import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import ImageSupport from "../../Icons/support.png"
import '../../CustomCss.css';
import moment from "moment";
import { Collapse, Tabs } from "antd";
import axios from "axios";

function GalileoFlights({GalileoFlightsData,updateStopsData,updateCarriersData,selectedStops, selectedCarriers }) {
  let flightsdata =GalileoFlightsData;
  const apiUrl = process.env.REACT_APP_API_URL;
  const isHitITData= flightsdata.isDisplayed;
  let FlightClass = flightsdata.FlightClass;
  let FlightType = flightsdata.FlightType;
  let departureCity = flightsdata.departureCity;
  let arrivalCity = flightsdata.arrivalCity;
  let StartDate = flightsdata.StartDate;
  let EndDate = flightsdata.EndDate;
  let Adults = flightsdata.Adults;
  let Children = flightsdata.Children;
  let Infants = flightsdata.Infants;
  const MultiFirstDepartureCity = flightsdata.MultiFirstDepartureCity;
  const MultiFirstArrivalCity = flightsdata.MultiFirstArrivalCity;
  const MultiSecondDepartureCity = flightsdata.MultiSecondDepartureCity;
  const MultiSecondArrivalCity = flightsdata.MultiSecondArrivalCity;
  const MultiThirdDepartureCity = flightsdata.MultiThirdDepartureCity;
  const MultiThirdArrivalCity = flightsdata.MultiThirdArrivalCity;
  const MultiFourthDepartureCity = flightsdata.MultiFourthDepartureCity;
  const MultiFourthArrivalCity = flightsdata.MultiFourthArrivalCity;
  const MultiFifthDepartureCity = flightsdata.MultiFifthDepartureCity;
  const MultiFifthArrivalCity = flightsdata.MultiFifthArrivalCity;
  const MultiSixthDepartureCity = flightsdata.MultiSixthDepartureCity;
  const MultiSixthArrivalCity = flightsdata.MultiSixthArrivalCity;
  const StartMultiFirstDate = flightsdata.StartMultiFirstDate;
  const StartMultiSecondDate = flightsdata.StartMultiSecondDate;
  const StartMultiThirdDate = flightsdata.StartMultiThirdDate;
  const StartMultiFourthDate = flightsdata.StartMultiFourthDate;
  const StartMultiFifthDate = flightsdata.StartMultiFifthDate;
  const StartMultiSixthDate = flightsdata.StartMultiSixthDate;

  let flightType = "OneWay";

  if (FlightType === 1) {
    flightType = "OneWay";
  }
  else if (FlightType === 2) {
    flightType = "Return";
  }
  else {
    flightType = "Multi";
  }

  const CardHeader = {
    backgroundColor: 'rgba(0,0,0,.03)'
  };

  const SelectedCardHeader = {
    backgroundColor: 'skyblue'
  };

  let depCity = departureCity.slice(-4);
  depCity = depCity.replace(')', '');
  const [origin, setOrigin] = useState("");

  let arrCity = arrivalCity.slice(-4);
  arrCity = arrCity.replace(')', '');

  let url = apiUrl + "/api/FlightSearch/Results?type=OneWay&fromM_1=%20null&fromM_2=%20null&fromM_3=%20null&fromM_4=%20null&fromM_5=%20null&fromM_6=%20null&toM_1=%20null&toM_2=%20null&toM_3=%20null&toM_4=%20null&toM_5=%20null&toM_6=null&from=" + depCity + "&to=" + arrCity + "&start=" + StartDate + "&end=" + EndDate + "&airline=%20null&service=%20null&adults=" + Adults + "&children=" + Children + "&infants=" + Infants + "&flexdate=0&directconecting=0&multipleshow=0&stops=-1&userid=-1";
console.log('gall url',url)
  const [isLoading, setIsLoading] = useState(false);
  const [deptOptionId, setDeptOptionId] = useState(-1);
  const [CardBg, setCardBg] = useState(CardHeader);
  const [data, setData] = useState([]); //original Departure data
  const [originalData, setOriginalData] = useState([]); //Departure
  const [returnData, setreturnData] = useState([]); //Return
  const [originalReturnData, setoriginalReturnData] = useState([]); //Return
  const [groupeddata, setGroupedData] = useState([]);
  const [DepButtonText, setDepButtonText] = useState("Book This");
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [HideReturnFlights, setHideReturnFlights] = useState(true);
  const [HideDepartureFlights, setHideDepartureFlights] = useState(false);

  let duration = 0;
  let hours = 0;
  let minutes = 0;
  let divId = 1;
  let img = "";
  let DepDirectFlights;
  let DepConnectingMainFLights;
  let DepConnectingFLights;

  let RetDirectFlights;
  let RetConnectingMainFLights;
  let RetConnectingFLights;

  let FilterDepartureFlights;
  let FilterReturnFlights;

  const [Stops, setStops] = useState([]);
  const [Airlines, setAirlines] = useState(['PK']);
  const navigate = useNavigate();
  // const [stopCheckboxes, setStopCheckboxes] = useState([ { id: '0', name: '0', label: '0', checked: false },]);
  const [stopCheckboxes, setStopCheckboxes] = useState([]);
  const [airlineCheckboxes, setairlineCheckboxes] = useState([]);
  const previousDataRef = useRef(null);
  const [formData, updateFormData] = React.useState([{ adults: "", childred: "", infants: "", BookingID: "" }]);

  async function fetchData() {
    setIsLoading(true);
    //debugger;
    await axios
      .get(url)

      .then((response) => {
        const data = response.data;
      //  flightsdata.onDataAvailable();
        setData(data);
        setOriginalData(data);
       
        setHideDepartureFlights(true);
        setHideReturnFlights(false);
        //Filters for Search Bar
        if (!data.includes("something went wrong")) {

        if(response.data!== '')
        {
        const stops = data.map(row => row.Stops);
        const uniqueStops = [...new Set(stops)].sort();

        const stopswithFlights = uniqueStops.filter(stop => {
          return data.some(flight => flight.Stops === stop);
        });

        setStops(stopswithFlights);
        updateStopsData(stopswithFlights);


          const airlines = data.map(row => row.Carrier);
          const uniqueAirlines = [...new Set(airlines)].sort();

          // Assuming `flightsData` is the array containing flight information
          const airlinesWithFlights = uniqueAirlines.filter(airline => {
            return data.some(flight => flight.Carrier === airline && flight.Flight=="DM");
          });

          setAirlines(airlinesWithFlights);
          updateCarriersData(airlinesWithFlights);

        setIsLoading(false);
        }
        }
      })
      .catch((error) => console.error("Error: ${error}"));
  }

  function handleCheckboxChange(e) {
    if (e.target.checked) {
      stopCheckboxes.push(e.target.value);
    }
    else {
      const index = stopCheckboxes.indexOf(e.target.value);
      stopCheckboxes.splice(index, 1);
    }
    if (stopCheckboxes.length > 0) {
      if (data !== undefined) {
        const filteredArray = data.filter(element => stopCheckboxes.includes(element.Stops));
        setData(filteredArray);
      }
    }
    else {
      setData(originalData);
    }
  }

  const applyFiltering = (originalData) => {
    if(selectedCarriers !==undefined && selectedStops !== undefined){
      const selectedStopsArray = Object.keys(selectedStops).filter(stop => selectedStops[stop]);
      const selectedCarrierArray = Object.keys(selectedCarriers).filter(carrier => selectedCarriers[carrier]);
  
      if (selectedStopsArray.length > 0 && selectedCarrierArray.length > 0) {
        const selectedStopsArray = Object.keys(selectedStops).filter(stop => selectedStops[stop]);
        let filteredFlightsData = originalData.filter(flight => selectedStopsArray.includes(flight.Stops));
        filteredFlightsData = filteredFlightsData.filter(flight => selectedCarrierArray.includes(flight.Carrier));
        // const filteredArray = data.filter(element => stopCheckboxes.includes(selectedStops));
        setData(filteredFlightsData);
      }
      else if(selectedStopsArray.length > 0){
        let filteredFlightsData = originalData.filter(flight => selectedStopsArray.includes(flight.Stops));
        setData(filteredFlightsData);
      }
      else if(selectedCarrierArray.length > 0){
       let filteredFlightsData = originalData.filter(flight => selectedCarrierArray.includes(flight.Carrier));
       setData(filteredFlightsData);
      }
      else {
        setData(originalData);
      }
    }
  };

  // useEffect to apply filtering whenever carriersData or selectedCheckboxes changes
  useEffect(() => {
    applyFiltering(originalData);
  }, [selectedStops, selectedCarriers]);



  function handleAirlinesCheckboxChange(e) {
    if (e.target.checked) {
      //const arr = [];
      airlineCheckboxes.push(e.target.value);
      //setStopCheckboxes(arr);
    }
    else {
      const index = airlineCheckboxes.indexOf(e.target.value);
      airlineCheckboxes.splice(index, 1);

      //setStopCheckboxes(stopCheckboxes(item => item !== e.target.value));
    }
    if (airlineCheckboxes.length > 0) {
      if (data !== undefined) {
        const filteredArray = data.filter(element => airlineCheckboxes.includes(selectedCarriers));
        setData(filteredArray);
      }
    }
    else {
      setData(originalData);
    }
  }

  const retUrl = apiUrl + "/api/FlightSearch/Results?type=OneWay&fromM_1=%20null&fromM_2=%20null&fromM_3=%20null&fromM_4=%20null&fromM_5=%20null&fromM_6=%20null&toM_1=%20null&toM_2=%20null&toM_3=%20null&toM_4=%20null&toM_5=%20null&toM_6=null&from=" + arrivalCity + "&to=" + departureCity + "&start=" + EndDate + "&end=" + EndDate + "&airline=%20null&service=%20null&adults=" + Adults + "&children=" + Children + "&infants=" + Infants + "&flexdate=0&directconecting=0&multipleshow=0&stops=-1";

  const combUrl= apiUrl + "/api/FlightSearch/Results?type=Return&fromM_1=null&fromM_2=null&fromM_3=null&fromM_4=null&fromM_5=null&fromM_6=null&toM_1=null&toM_2=null&toM_3=null&toM_4=null&toM_5=null&toM_6=null&from="+ departureCity +"&to="+ arrivalCity +"&start="+ StartDate +"&end="+ EndDate +"&airline=null&service=null&adults="+ Adults +"&children="+ Children +"&infants="+ Infants +"&flexdate=0&directconecting=0&multipleshow=0&stops=-1&userid=-1"
  async function fetchReturnData() {
    setIsLoading(true);
    setData([]);
    setOriginalData([]);
    await axios
      .get(combUrl)
      .then((response) => {
        const returnData = response.data;
       // debugger
        if(returnData !==""){
          const departureFlights = returnData.filter(item => item.Flight === 'DM' || item.Flight === 'DC');
          const returnFlights = returnData.filter(item => item.Flight === 'RM' || item.Flight === 'RC');

          setDepButtonText("Select This");
          setData(departureFlights);
          setOriginalData(departureFlights);
          setreturnData(returnFlights);
          setoriginalReturnData(returnFlights);

          setHideDepartureFlights(true);
          setHideReturnFlights(false);

          const stops = departureFlights.map(row => row.Stops);
          const uniqueStops = [...new Set(stops)].sort();
          const stopswithFlights = uniqueStops.filter(stop => {
            return departureFlights.some(flight => flight.Stops === stop);
          });
          setStops(stopswithFlights);
          updateStopsData(stopswithFlights);

          const airlines = departureFlights.map(row => row.Carrier);
          const uniqueAirlines = [...new Set(airlines)].sort();

          // Assuming `flightsData` is the array containing flight information
          const airlinesWithFlights = uniqueAirlines.filter(airline => {
            return departureFlights.some(flight => flight.Carrier === airline && flight.Flight=="DM");
          });

          setAirlines(airlinesWithFlights);
          updateCarriersData(airlinesWithFlights);
        }
      
        setIsLoading(false);
      });
  }
  
  useEffect(() => {
    if (previousDataRef.current !== GalileoFlightsData|| previousDataRef.current ==null) {

      if (flightType === 'Return') {
        fetchReturnData();
      }
      else{
        fetchData();
      }
    previousDataRef.current = GalileoFlightsData;
    }
    else{
   
  }
  }, [GalileoFlightsData.departureCity,GalileoFlightsData.arrivalCity,GalileoFlightsData.StartDate,GalileoFlightsData.EndDate]);

  const divRef = useRef(0);

  if (!data.includes("something went wrong")) {
    if (data.length > 0) {
      DepDirectFlights = data.filter((flights) => flights.Direct === "1" );

      DepConnectingMainFLights = data.filter(
        (flights) => flights.Direct === "0" && flights.Flight === "DM"
      );

      DepConnectingFLights = data.filter(
        (flights) => flights.Direct === "0" && flights.Flight === "DC"
      );

    }
  }

    if (!returnData.includes("something went wrong")) {
     
      RetDirectFlights = returnData.filter((flights) => flights.Direct === "1");

      RetConnectingMainFLights = returnData.filter(
        (flights) => flights.Direct === "0" && flights.Flight === "RM"
      );

      RetConnectingFLights = returnData.filter(
        (flights) => flights.Direct === "0" && flights.Flight === "RC"
      );
    }

  const handleAirlineChange = e => {
    if (e.target.checked) {
      if (data !== undefined) {
        setData(data.filter(flights => flights.Carrier !== e.target.value));
      }
    } else {
      setData([...originalData, e.target.value]);
    }
  };

  const handleClick = (event,totalamount) => {
    if (HideReturnFlights === false) {
      if (data !== undefined) {
        FilterDepartureFlights = data.filter(
          (flights) => flights.Option_Id === event && flights.GrandTotal === totalamount
        );
      }
      setData(FilterDepartureFlights); //filter data
      setDeptOptionId(event);

      if (returnData !== undefined) {
        FilterReturnFlights = returnData.filter(
          (flights) => flights.Carrier === FilterDepartureFlights[0].Carrier && flights.GrandTotal ===totalamount
        );
        // onSelectFlight("B");
        setreturnData(FilterReturnFlights); // filter data
      }

      if (FilterReturnFlights !== undefined) {
        RetDirectFlights = FilterReturnFlights.filter((flights) => flights.Direct === "1");
        RetConnectingMainFLights = FilterReturnFlights.filter(
          (flights) => flights.Direct === "0" && flights.Flight === "RM"
        );

        RetConnectingFLights = FilterReturnFlights.filter(
          (flights) => flights.Direct === "0" && flights.Flight === "RC"
        );

        setCardBg(SelectedCardHeader);
        setDepButtonText("Change This");
        setHideReturnFlights(true);
      }
    } else {
      setCardBg(CardHeader);
      setData(originalData);
      setreturnData(originalReturnData);
      setDepButtonText("Select This");
      setHideReturnFlights(false);
      //setHideDepartureFlights(true);
    }
  };

  //setDeptOptionId


  //sending request to booking page 
  const HandleOneWayClick = (e) => {
    let optionID = e;

    const filteredArray = data.filter(element => element.Option_Id === optionID);

    //navigate('/FlightBooking',{state:{ prop1: filteredArray}});     

    navigate('/FlightBooking', { state: { props: filteredArray, adults: Adults, children: Children, infants: Infants } });

  };

  const HandleReturnClick = (e,totalamount) => {
    let optionID = e;

    const array1 = originalData.filter(element => element.Option_Id === deptOptionId && element.GrandTotal ===totalamount);
    const array2 = originalReturnData.filter(element => element.Option_Id === optionID && element.GrandTotal ===totalamount);

    const filteredArray = [...array1, ...array2];
    navigate('/FlightBooking', { state: { props: filteredArray, adults: Adults, children: Children, infants: Infants } });

    
  };

  //sending request to booking page end

  function removeLetters(str) {
    return str.replace(/[a-z]/gi, "");
  }

  function renderStops(e) {
    if (e === '2') {
      return <span>2 Stops</span>;
    }
    else if (e === '1') {
      return <span>1 Stop</span>;
    }
    else if (e === '0') {
      return <span>Direct</span>;
    }
    else {
      return null;
    }
  }

  // const GalileoFlightsData = {
  //   data, returnData, originalData, originalReturnData, isLoading, deptOptionId, DepButtonText, Panel, loading, HideDepartureFlights, HideReturnFlights,
  //   FiltersToggle,
  //   // Add your additional data properties here
  // };
 console.log('gall data is',data)
 
  return (
    <>
      <div>
        {isLoading && isHitITData === false && <div className="loader"></div>}

        {/* Render the content when isLoading is false */}
        {!isLoading && (
          <div>
            <section className="innerpage-wrapper">

                  <div className="row w-100"style={{marginLeft:"0px"}}>
                    <div className="col-12 col-md-9 col-lg-9 pl-0">
                      {(() => {
                        if (flightType == 'OneWay') {
                          return (
                            <div className="content-side">
                              {loading ? <>Loading..</> : <></>}
                              {/* Departure Flights */}
                              {HideDepartureFlights ? (
                                <div id="DepartureFlights">
                                  <div>
                                    {!DepDirectFlights ? <></> : DepDirectFlights.map((flights) => (
                                      <form>

                                        <div
                                          id={divId}
                                          ref={divRef}
                                          style={{ display: "block" }}
                                          className="list-block main-block f-list-block"
                                        >


                                          <div style={{ display: "none" }}>
                                            {" "}
                                            {(divId = divId + 1)}
                                            {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                            {(img = flights.Carrier + ".png")}
                                          </div>
                                          <div className="card mt-0" style={{ borderRadius: "15px" }}>
                                            <div className="card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span><b>{flights.CarrierName}</b></span>
                                              <span>Departure</span>
                                              <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(removeLetters(flights.GrandTotal)))}</b></p></div>
                                            <div className="card-body">
                                              <div className="row">
                                                <div className="col-sm-3">
                                                  <img
                                                    src={"images/airlines/" + img}
                                                    style={{ width: "80px", height: "80px" }}
                                                    alt="flight-img"
                                                  />
                                                </div>
                                                <div className="col-sm-6 mt-3">
                                                  <div className="time">
                                                    <div className="line line--tip">
                                                      <strong> {moment(flights.DepartureTime).format(
                                                        "HH:mm"
                                                      )}</strong>
                                                      <span
                                                        data-airport-role="departure"
                                                        className="code"
                                                        data-leg-index="0"
                                                      >
                                                        {" "}
                                                        {flights.Origin}
                                                        <span className="tooltip">
                                                          Click to show only flights
                                                          having this
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div className="line line--middle">
                                                      <span className="icon-text">
                                                        <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                                      </span>
                                                      <dom-if style={{ display: 'none' }}>
                                                        <template is="dom-if"></template>
                                                      </dom-if>
                                                      <div className="direct-label">
                                                        {renderStops(flights.Stops)}
                                                      </div>
                                                    </div>
                                                    <div className="line line--tip">
                                                      <strong>  {moment(flights.ArrivalTime).format(
                                                        "HH:mm"
                                                      )} </strong>
                                                      <span
                                                        data-airport-role="arrival"
                                                        className="code"
                                                        data-leg-index="0"
                                                      >
                                                        {" "}
                                                        {flights.Destination}
                                                        <span className="tooltip">
                                                          Click to show only flights
                                                          having this
                                                        </span>
                                                      </span>
                                                      <div className="labels">
                                                        <div
                                                          style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                          <b></b>
                                                          <span className="tooltip"></span>
                                                        </div>
                                                        <div
                                                          style={{ cursor: 'auto', hidden: '' }}>
                                                          <wego-icon
                                                            className="small moon"
                                                            icon="moon"
                                                          ></wego-icon>
                                                          <span className="tooltip">
                                                            Overnight Flight
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-3">

                                                  <a
                                                    className="btn btn-orange"
                                                    key={flights.Option_Id}
                                                    id={flights.Option_Id}
                                                    onClick={(e) => HandleOneWayClick(e.target.id)}
                                                  >
                                                    Book This
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-footer text-left">
                                              <Collapse defaultActiveKey={["1"]} ghost>
                                                <Panel header="Flight Details" key="0">
                                                  <Tabs
                                                    defaultActiveKey="1"
                                                    items={[
                                                      {
                                                        label: "Itinerary",
                                                        key: "1",
                                                        children: (
                                                          <>
                                                            <div className="row">
                                                              <ul style={{ listStyle: "none" }}>
                                                                <li>
                                                                  <img
                                                                    src={
                                                                      "images/airlines/" + img
                                                                    }
                                                                    style={{
                                                                      width: "80px",
                                                                      height: "80px",
                                                                    }}
                                                                    alt="flight-img"
                                                                  />
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p className="block-title">
                                                                    {flights.Origin} to{" "}
                                                                    {flights.Destination}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  <p>
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{ color: "black",paddingLeft:"5px" }}
                                                                    >
                                                                      {moment(
                                                                        flights.DepartureTime
                                                                      ).format("HH:mm")}{" "}
                                                                    </span>
                                                                    -{" "}
                                                                    <span>
                                                                      {hours} hours - {minutes}{" "}
                                                                      minutes
                                                                    </span>{" "}
                                                                    -
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane fa-flip-vertical"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{ color: "black",paddingLeft:"5px" }}
                                                                    >
                                                                      {moment(
                                                                        flights.ArrivalTime
                                                                      ).format("HH:mm")}
                                                                    </span>
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {flights.Carrier} -{" "}
                                                                  {flights.FlightNumber}{" "}
                                                                </li>
                                                                <li>
                                                                  {flights.CabinClass} (
                                                                  {flights.BookingCode})
                                                                </li>
                                                                <li>{flights.Stops}</li>
                                                              </ul>
                                                            </div>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Baggage",
                                                        key: "2",
                                                        children: (
                                                          <>
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                Allowed Weight:{" "}
                                                                {flights.MaxWeightValue}{" "}
                                                                {flights.MaxWeightUnit}
                                                              </li>
                                                            </ul>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Cancellation",
                                                        key: "3",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>{flights.CancelPenalty}</p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                      ,
                                                      {
                                                        label: "Date Change",
                                                        key: "4",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>{flights.ChangePenalty}</p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                    ]}
                                                  />
                                                </Panel>
                                              </Collapse>
                                            </div>
                                          </div>
                                        </div>

                                      </form>
                                    ))}
                                  </div>

                                  <div>
                                    {!DepConnectingMainFLights ? <></> : DepConnectingMainFLights.map((flights) => (
                                      <form>

                                        <div
                                          id={divId}
                                          style={{ display: "block" }}
                                          className="list-block main-block f-list-block"
                                        >
                                          <div style={{ display: "none" }}>
                                            {" "}
                                            {(divId = divId + 1)}
                                            {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                            {(img = flights.Carrier + ".png")}
                                          </div>
                                          <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                            <div className="card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span><b>{flights.CarrierName}</b></span>
                                              <span>Departure</span>
                                              <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(removeLetters(flights.AdultTotalPrice)) + 1000)}</b></p></div>
                                            <div className="card-body">
                                              <div className="row">
                                                <div className="col-sm-3">
                                                  <img
                                                    src={"images/airlines/" + img}
                                                    style={{ width: "80px", height: "80px" }}
                                                    alt="flight-img"
                                                  />
                                                </div>
                                                <div className="col-sm-6">
                                                  <div className="time">
                                                    <div className="line line--tip">
                                                      <strong> {moment(flights.DepartureTime).format(
                                                        "HH:mm"
                                                      )}</strong>
                                                      <span
                                                        data-airport-role="departure"
                                                        className="code"
                                                        data-leg-index="0"
                                                      >
                                                        {" "}
                                                        {flights.Origin}
                                                        <span className="tooltip">
                                                          Click to show only flights
                                                          having this
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div className="line line--middle">
                                                      <span>
                                                        <span className="icon-text">
                                                          <b>Total</b>
                                                          <br />
                                                          {hours}h {minutes}m
                                                        </span>
                                                      </span>
                                                      <dom-if style={{ display: 'none' }}>
                                                        <template is="dom-if"></template>
                                                      </dom-if>
                                                      <div className="direct-label">
                                                        {renderStops(flights.Stops)}
                                                      </div>
                                                    </div>
                                                    <div className="line line--tip">
                                                      <strong>  {moment(flights.ArrivalTime).format(
                                                        "HH:mm"
                                                      )} </strong>
                                                      <span
                                                        data-airport-role="arrival"
                                                        className="code"
                                                        data-leg-index="0"
                                                      >
                                                        {" "}
                                                        {flights.Destination}
                                                        <span className="tooltip">
                                                          Click to show only flights
                                                          having this
                                                        </span>
                                                      </span>
                                                      <div className="labels">
                                                        <div
                                                          style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                          <b></b>
                                                          <span className="tooltip"></span>
                                                        </div>
                                                        <div
                                                          style={{ cursor: 'auto', hidden: '' }}>
                                                          <wego-icon
                                                            className="small moon"
                                                            icon="moon"
                                                          ></wego-icon>
                                                          <span className="tooltip">
                                                            Overnight Flight
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-3">

                                                  <a
                                                    onClick={(e) => HandleOneWayClick(e.target.id)}
                                                    key={flights.Option_Id}
                                                    id={flights.Option_Id}
                                                    className="btn btn-orange"
                                                  >
                                                    {DepButtonText}
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-footer text-left">
                                              <Collapse defaultActiveKey={["1"]} ghost>
                                                <Panel header="Flight Details" key="0">
                                                  <Tabs
                                                    defaultActiveKey="1"
                                                    items={[
                                                      {
                                                        label: "Itinerary",
                                                        key: "1",
                                                        children: (
                                                          <>
                                                            <div className="row">
                                                              <ul style={{ listStyle: "none" }}>
                                                                <li>
                                                                  <img
                                                                    src={
                                                                      "images/airlines/" + img
                                                                    }
                                                                    style={{
                                                                      width: "80px",
                                                                      height: "80px",
                                                                    }}
                                                                    alt="flight-img"
                                                                  />
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p className="block-title">
                                                                    {flights.Origin} to{" "}
                                                                    {flights.Destination}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  <p>
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{ color: "black",paddingLeft:"5px" }}
                                                                    >
                                                                      {moment(
                                                                        flights.DepartureTime
                                                                      ).format("HH:mm")}{" "}
                                                                    </span>
                                                                    -{" "}
                                                                    <span>
                                                                      {hours} hours - {minutes}{" "}
                                                                      minutes
                                                                    </span>{" "}
                                                                    -
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane fa-flip-vertical"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{ color: "black",paddingLeft:"5px" }}
                                                                    >
                                                                      {moment(
                                                                        flights.ArrivalTime
                                                                      ).format("HH:mm")}
                                                                    </span>
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {flights.Carrier} -{" "}
                                                                  {flights.FlightNumber}{" "}
                                                                </li>
                                                                <li>
                                                                  {flights.CabinClass} (
                                                                  {flights.BookingCode})
                                                                </li>
                                                                {/* <li>{flights.Stops}</li> */}
                                                              </ul>
                                                            </div>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Baggage",
                                                        key: "2",
                                                        children: (
                                                          <>
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                Allowed Weight:{" "}
                                                                {flights.MaxWeightValue}{" "}
                                                                {flights.MaxWeightUnit}
                                                              </li>
                                                            </ul>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Cancellation",
                                                        key: "3",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>{flights.CancelPenalty}</p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                      ,
                                                      {
                                                        label: "Date Change",
                                                        key: "4",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>{flights.ChangePenalty}</p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                    ]}
                                                  />
                                                  {DepConnectingFLights.filter(
                                                    (cflights) =>
                                                      cflights.Option_Id ===
                                                      flights.Option_Id &&
                                                      cflights.Flight === "DC"
                                                  ).map((cflights) => (
                                                    <Tabs
                                                      defaultActiveKey="1"
                                                      items={[
                                                        {
                                                          label: "Itinerary",
                                                          key: "1",
                                                          children: (
                                                            <>
                                                              <div className="row">
                                                                <ul
                                                                  style={{ listStyle: "none" }}
                                                                >
                                                                  <li>
                                                                    <img
                                                                      src={
                                                                        "images/airlines/" + img
                                                                      }
                                                                      style={{
                                                                        width: "80px",
                                                                        height: "80px",
                                                                      }}
                                                                      alt="flight-img"
                                                                    />
                                                                  </li>
                                                                  <li>
                                                                    {" "}
                                                                    <p className="block-title">
                                                                      {cflights.Origin} to{" "}
                                                                      {cflights.Destination}
                                                                    </p>
                                                                  </li>
                                                                  <li>
                                                                    <p>
                                                                      <span style={{fontSize:"large"}}>
                                                                        <i className="fa fa-plane"></i>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color: "black",paddingLeft:"5px"
                                                                        }}
                                                                      >
                                                                        {moment(
                                                                          cflights.DepartureTime
                                                                        ).format("HH:mm")}{" "}
                                                                      </span>
                                                                      -{" "}
                                                                      <span>
                                                                        {hours} hours -{" "}
                                                                        {minutes} minutes
                                                                      </span>{" "}
                                                                      -
                                                                      <span style={{fontSize:"large"}}>
                                                                        <i className="fa fa-plane fa-flip-vertical"></i>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color: "black",paddingLeft:"5px"
                                                                        }}
                                                                      >
                                                                        {moment(
                                                                          cflights.ArrivalTime
                                                                        ).format("HH:mm")}
                                                                      </span>
                                                                    </p>
                                                                  </li>
                                                                  <li>
                                                                    {cflights.Carrier} -{" "}
                                                                    {cflights.FlightNumber}{" "}
                                                                  </li>
                                                                  <li>
                                                                    {cflights.CabinClass} (
                                                                    {cflights.BookingCode})
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </>
                                                          ),
                                                        },
                                                        {
                                                          label: "Baggage",
                                                          key: "2",
                                                          children: (
                                                            <>
                                                              <ul style={{ listStyle: "none" }}>
                                                                <li>
                                                                  Allowed Weight:{" "}
                                                                  {cflights.MaxWeightValue}{" "}
                                                                  {cflights.MaxWeightUnit}
                                                                </li>
                                                              </ul>
                                                            </>
                                                          ),
                                                        },
                                                        {
                                                          label: "Cancellation",
                                                          key: "3",
                                                          children: (
                                                            <>
                                                              <table>
                                                                <tr>
                                                                  <th style={{ width: "70%" }}>
                                                                    Time
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width: "30%",
                                                                      textAlign: "center",
                                                                    }}
                                                                  >
                                                                    Fee
                                                                  </th>
                                                                </tr>
                                                                <tr>
                                                                  <td style={{ width: "70%" }}>
                                                                    <p>
                                                                      Penalty for adults before
                                                                      departure (Not applicable
                                                                      within 24 hours of
                                                                      departure).{" "}
                                                                    </p>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      width: "30%",
                                                                      textAlign: "center",
                                                                    }}
                                                                  >
                                                                    <p>
                                                                      {cflights.CancelPenalty}
                                                                    </p>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </>
                                                          ),
                                                        },
                                                        ,
                                                        {
                                                          label: "Date Change",
                                                          key: "4",
                                                          children: (
                                                            <>
                                                              <table>
                                                                <tr>
                                                                  <th style={{ width: "70%" }}>
                                                                    Time
                                                                  </th>
                                                                  <th
                                                                    style={{
                                                                      width: "30%",
                                                                      textAlign: "center",
                                                                    }}
                                                                  >
                                                                    Fee
                                                                  </th>
                                                                </tr>
                                                                <tr>
                                                                  <td style={{ width: "70%" }}>
                                                                    <p>
                                                                      Penalty for adults before
                                                                      departure (Not applicable
                                                                      within 24 hours of
                                                                      departure).{" "}
                                                                    </p>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      width: "30%",
                                                                      textAlign: "center",
                                                                    }}
                                                                  >
                                                                    <p>
                                                                      {cflights.ChangePenalty}
                                                                    </p>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </>
                                                          ),
                                                        },
                                                      ]}
                                                    />
                                                  ))}
                                                </Panel>
                                              </Collapse>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          )
                        }
                        else if (flightType == 'Return') {
                          return (
                            <div className="content-side">
                              {loading ? <>Loading..</> : <></>}
                              {/* Departure Flights */}
                              {HideDepartureFlights ? (
                                <div id="DepartureFlights">
                                  <div>
                                    {!DepDirectFlights ? <><h2>No record found. Please try another dates.</h2></> : DepDirectFlights.map((flights) => (
                                      <div
                                        id={divId}
                                        ref={divRef}
                                        style={{ display: "block" }}
                                        className="list-block main-block f-list-block"
                                      >
                                        <div style={{ display: "none" }}>
                                          {" "}
                                          {(divId = divId + 1)}
                                          {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                          {(img = flights.Carrier + ".png")}
                                        </div>
                                        <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                          <div className="card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span><b>{flights.CarrierName}</b></span>
                                            <span>Departure</span>
                                            <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(removeLetters(flights.GrandTotal)) + 1000)}</b></p></div>
                                          <div className="card-body">
                                            <div className="row">
                                              <div className="col-sm-3">
                                                <img
                                                  src={"images/airlines/" + img}
                                                  style={{ width: "80px", height: "80px" }}
                                                  alt="flight-img"
                                                />
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="time">
                                                  <div className="line line--tip">
                                                    <strong> {moment(flights.DepartureTime).format(
                                                      "HH:mm"
                                                    )}</strong>
                                                    <span
                                                      data-airport-role="departure"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Origin}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="line line--middle">
                                                    <span>
                                                      <span className="icon-text">
                                                        <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                                      </span>
                                                    </span>
                                                    <dom-if style={{ display: 'none' }}>
                                                      <template is="dom-if"></template>
                                                    </dom-if>
                                                    <div className="direct-label">
                                                      {renderStops(flights.Stops)}
                                                    </div>
                                                  </div>
                                                  <div className="line line--tip">
                                                    <strong>  {moment(flights.ArrivalTime).format(
                                                      "HH:mm"
                                                    )} </strong>
                                                    <span
                                                      data-airport-role="arrival"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Destination}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                    <div className="labels">
                                                      <div
                                                        style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                        <b></b>
                                                        <span className="tooltip"></span>
                                                      </div>
                                                      <div
                                                        style={{ cursor: 'auto', hidden: '' }}>
                                                        <wego-icon
                                                          className="small moon"
                                                          icon="moon"
                                                        ></wego-icon>
                                                        <span className="tooltip">
                                                          Overnight Flight
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">

                                                <a
                                                  className="btn btn-orange"
                                                  key={flights.Option_Id}
                                                  id={flights.Option_Id}

                                                  onClick={(e) => handleClick(e.target.id,flights.GrandTotal)}
                                                >
                                                  {DepButtonText}
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer text-left">
                                            <Collapse defaultActiveKey={["1"]} ghost>
                                              <Panel header="Flight Details" key="0">
                                                <Tabs
                                                  defaultActiveKey="1"
                                                  items={[
                                                    {
                                                      label: "Itinerary",
                                                      key: "1",
                                                      children: (
                                                        <>
                                                          <div className="row">
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                <img
                                                                  src={
                                                                    "images/airlines/" + img
                                                                  }
                                                                  style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                  }}
                                                                  alt="flight-img"
                                                                />
                                                              </li>
                                                              <li>
                                                                {" "}
                                                                <p className="block-title">
                                                                  {flights.Origin} to{" "}
                                                                  {flights.Destination}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.DepartureTime
                                                                    ).format("HH:mm")}{" "}
                                                                  </span>
                                                                  -{" "}
                                                                  <span>
                                                                    {hours} hours - {minutes}{" "}
                                                                    minutes
                                                                  </span>{" "}
                                                                  -
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane fa-flip-vertical"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.ArrivalTime
                                                                    ).format("HH:mm")}
                                                                  </span>
                                                                </p>
                                                              </li>
                                                              <li>
                                                                {flights.Carrier} -{" "}
                                                                {flights.FlightNumber}{" "}
                                                              </li>
                                                              <li>
                                                                {flights.CabinClass} (
                                                                {flights.BookingCode})
                                                              </li>
                                                              <li>{flights.Stops}</li>
                                                            </ul>
                                                          </div>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Baggage",
                                                      key: "2",
                                                      children: (
                                                        <>
                                                          <ul style={{ listStyle: "none" }}>
                                                            <li>
                                                              Allowed Weight:{" "}
                                                              {flights.MaxWeightValue}{" "}
                                                              {flights.MaxWeightUnit}
                                                            </li>
                                                          </ul>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Cancellation",
                                                      key: "3",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.CancelPenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                    ,
                                                    {
                                                      label: "Date Change",
                                                      key: "4",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.ChangePenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                  ]}
                                                />
                                              </Panel>
                                            </Collapse>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <div>
                                    {!DepConnectingMainFLights ? <></> : DepConnectingMainFLights.map((flights) => (
                                      <div
                                        id={divId}
                                        style={{ display: "block" }}
                                        className="list-block main-block f-list-block"
                                      >
                                        <div style={{ display: "none" }}>
                                          {" "}
                                          {(divId = divId + 1)}
                                          {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                          {(img = flights.Carrier + ".png")}
                                        </div>
                                        <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                          <div className="card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span><b>{flights.CarrierName}</b></span>
                                            <span>Departure</span>
                                            <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(removeLetters(flights.AdultTotalPrice)) + 1000)}</b></p></div>
                                          <div className="card-body">
                                            <div className="row">
                                              <div className="col-sm-3">
                                                <img
                                                  src={"images/airlines/" + img}
                                                  style={{ width: "80px", height: "80px" }}
                                                  alt="flight-img"
                                                />
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="time">
                                                  <div className="line line--tip">
                                                    <strong> {moment(flights.DepartureTime).format(
                                                      "HH:mm"
                                                    )}</strong>
                                                    <span
                                                      data-airport-role="departure"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Origin}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="line line--middle">
                                                    <span>
                                                      <span className="icon-text">
                                                        <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                                      </span>
                                                    </span>
                                                    <dom-if style={{ display: 'none' }}>
                                                      <template is="dom-if"></template>
                                                    </dom-if>
                                                    <div className="direct-label">
                                                      {renderStops(flights.Stops)}
                                                    </div>
                                                  </div>
                                                  <div className="line line--tip">
                                                    <strong>  {moment(flights.ArrivalTime).format(
                                                      "HH:mm"
                                                    )} </strong>
                                                    <span
                                                      data-airport-role="arrival"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Destination}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                    <div className="labels">
                                                      <div
                                                        style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                        <b></b>
                                                        <span className="tooltip"></span>
                                                      </div>
                                                      <div
                                                        style={{ cursor: 'auto', hidden: '' }}>
                                                        <wego-icon
                                                          className="small moon"
                                                          icon="moon"
                                                        ></wego-icon>
                                                        <span className="tooltip">
                                                          Overnight Flight
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">

                                                <a
                                                  onClick={(e) => handleClick(e.target.id,flights.GrandTotal)}
                                                  key={flights.Option_Id}
                                                  id={flights.Option_Id}
                                                  className="btn btn-orange"
                                                >
                                                  {DepButtonText}
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer text-left">
                                            <Collapse defaultActiveKey={["1"]} ghost>
                                              <Panel header="Flight Details" key="0">
                                                <Tabs
                                                  defaultActiveKey="1"
                                                  items={[
                                                    {
                                                      label: "Itinerary",
                                                      key: "1",
                                                      children: (
                                                        <>
                                                          <div className="row">
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                <img
                                                                  src={
                                                                    "images/airlines/" + img
                                                                  }
                                                                  style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                  }}
                                                                  alt="flight-img"
                                                                />
                                                              </li>
                                                              <li>
                                                                {" "}
                                                                <p className="block-title">
                                                                  {flights.Origin} to{" "}
                                                                  {flights.Destination}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.DepartureTime
                                                                    ).format("HH:mm")}{" "}
                                                                  </span>
                                                                  -{" "}
                                                                  <span>
                                                                    {hours} hours - {minutes}{" "}
                                                                    minutes
                                                                  </span>{" "}
                                                                  -
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane fa-flip-vertical"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.ArrivalTime
                                                                    ).format("HH:mm")}
                                                                  </span>
                                                                </p>
                                                              </li>
                                                              <li>
                                                                {flights.Carrier} -{" "}
                                                                {flights.FlightNumber}{" "}
                                                              </li>
                                                              <li>
                                                                {flights.CabinClass} (
                                                                {flights.BookingCode})
                                                              </li>
                                                              <li>{flights.Stops}</li>
                                                            </ul>
                                                          </div>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Baggage",
                                                      key: "2",
                                                      children: (
                                                        <>
                                                          <ul style={{ listStyle: "none" }}>
                                                            <li>
                                                              Allowed Weight:{" "}
                                                              {flights.MaxWeightValue}{" "}
                                                              {flights.MaxWeightUnit}
                                                            </li>
                                                          </ul>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Cancellation",
                                                      key: "3",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.CancelPenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                    ,
                                                    {
                                                      label: "Date Change",
                                                      key: "4",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.ChangePenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                  ]}
                                                />
                                                {!DepConnectingFLights ? <></> : DepConnectingFLights.filter(
                                                  (cflights) =>
                                                    cflights.Option_Id ===
                                                    flights.Option_Id &&
                                                    cflights.Flight === "DC"
                                                ).map((cflights) => (
                                                  <Tabs
                                                    defaultActiveKey="1"
                                                    items={[
                                                      {
                                                        label: "Itinerary",
                                                        key: "1",
                                                        children: (
                                                          <>
                                                            <div className="row">
                                                              <ul
                                                                style={{ listStyle: "none" }}
                                                              >
                                                                <li>
                                                                  <img
                                                                    src={
                                                                      "images/airlines/" + img
                                                                    }
                                                                    style={{
                                                                      width: "80px",
                                                                      height: "80px",
                                                                    }}
                                                                    alt="flight-img"
                                                                  />
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p className="block-title">
                                                                    {cflights.Origin} to{" "}
                                                                    {cflights.Destination}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  <p>
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color: "black",paddingLeft:"5px"
                                                                      }}
                                                                    >
                                                                      {moment(
                                                                        cflights.DepartureTime
                                                                      ).format("HH:mm")}{" "}
                                                                    </span>
                                                                    -{" "}
                                                                    <span>
                                                                      {hours} hours -{" "}
                                                                      {minutes} minutes
                                                                    </span>{" "}
                                                                    -
                                                                    <span>
                                                                      <i className="fa fa-plane fa-flip-vertical"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color: "black",
                                                                      }}
                                                                    >
                                                                      {moment(
                                                                        cflights.ArrivalTime
                                                                      ).format("HH:mm")}
                                                                    </span>
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {cflights.Carrier} -{" "}
                                                                  {cflights.FlightNumber}{" "}
                                                                </li>
                                                                <li>
                                                                  {cflights.CabinClass} (
                                                                  {cflights.BookingCode})
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Baggage",
                                                        key: "2",
                                                        children: (
                                                          <>
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                Allowed Weight:{" "}
                                                                {cflights.MaxWeightValue}{" "}
                                                                {cflights.MaxWeightUnit}
                                                              </li>
                                                            </ul>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Cancellation",
                                                        key: "3",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {cflights.CancelPenalty}
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                      ,
                                                      {
                                                        label: "Date Change",
                                                        key: "4",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {cflights.ChangePenalty}
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                    ]}
                                                  />
                                                ))}
                                              </Panel>
                                            </Collapse>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}



                              {HideReturnFlights ? (
                                <div id="ReturnFlights">
                                  <div>
                                    {!RetDirectFlights ? <></> : RetDirectFlights.map((flights) => (
                                      <div
                                        id={flights.Option_Id}
                                        className="list-block main-block f-list-block"
                                      >
                                        <div style={{ display: "none" }}>
                                          {" "}
                                          {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                          {(img = flights.Carrier + ".png")}
                                        </div>
                                        <div className="card">
                                        <div className="card-header p-1 d-flex justify-content-between" style={{ backgroundColor: 'white' }} > 
                                        <span>Return</span>
                                        <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(removeLetters(flights.GrandTotal)))}</b></p></div>


                                          <div className="card-header">Return</div>
                                          <div className="card-body">
                                            <div className="row">
                                              <div className="col-sm-3">
                                                <img
                                                  src={"images/airlines/" + img}
                                                  style={{ width: "80px", height: "80px" }}
                                                  alt="flight-img"
                                                />
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="time">
                                                  <div className="line line--tip">
                                                    <strong> {moment(flights.DepartureTime).format(
                                                      "HH:mm"
                                                    )}</strong>
                                                    <span
                                                      data-airport-role="departure"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Origin}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="line line--middle">
                                                    <span>
                                                      <span className="icon-text">
                                                        <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                                      </span>
                                                    </span>
                                                    <dom-if style={{ display: 'none' }}>
                                                      <template is="dom-if"></template>
                                                    </dom-if>
                                                    <div className="direct-label">
                                                      {renderStops(flights.Stops)}
                                                    </div>
                                                  </div>
                                                  <div className="line line--tip">
                                                    <strong>  {moment(flights.ArrivalTime).format(
                                                      "HH:mm"
                                                    )} </strong>
                                                    <span
                                                      data-airport-role="arrival"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Destination}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                    <div className="labels">
                                                      <div
                                                        style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                        <b></b>
                                                        <span className="tooltip"></span>
                                                      </div>
                                                      <div
                                                        style={{ cursor: 'auto', hidden: '' }}>
                                                        <wego-icon
                                                          className="small moon"
                                                          icon="moon"
                                                        ></wego-icon>
                                                        <span className="tooltip">
                                                          Overnight Flight
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">

                                                <a
                                                  key={flights.Option_Id}
                                                  id={flights.Option_Id}
                                                  onClick={(e) => HandleReturnClick(e.target.id,flights.GrandTotal)}
                                                  className="btn btn-orange"
                                                >
                                                  Book Now
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer text-left">
                                            <Collapse defaultActiveKey={["1"]} ghost>
                                              <Panel header="Flight Details" key="0">
                                                <Tabs
                                                  defaultActiveKey="1"
                                                  items={[
                                                    {
                                                      label: "Itinerary",
                                                      key: "1",
                                                      children: (
                                                        <>
                                                          <div className="row">
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                <img
                                                                  src={
                                                                    "images/airlines/" + img
                                                                  }
                                                                  style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                  }}
                                                                  alt="flight-img"
                                                                />
                                                              </li>
                                                              <li>
                                                                {" "}
                                                                <p className="block-title">
                                                                  {flights.Origin} to{" "}
                                                                  {flights.Destination}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.DepartureTime
                                                                    ).format("HH:mm")}{" "}
                                                                  </span>
                                                                  -{" "}
                                                                  <span>
                                                                    {hours} hours - {minutes}{" "}
                                                                    minutes
                                                                  </span>{" "}
                                                                  -
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane fa-flip-vertical"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black" ,paddingLeft:"5px"}}
                                                                  >
                                                                    {moment(
                                                                      flights.ArrivalTime
                                                                    ).format("HH:mm")}
                                                                  </span>
                                                                </p>
                                                              </li>
                                                              <li>
                                                                {flights.Carrier} -{" "}
                                                                {flights.FlightNumber}{" "}
                                                              </li>
                                                              <li>
                                                                {flights.CabinClass} (
                                                                {flights.BookingCode})
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Baggage",
                                                      key: "2",
                                                      children: (
                                                        <>
                                                          <ul style={{ listStyle: "none" }}>
                                                            <li>
                                                              Allowed Weight:{" "}
                                                              {flights.MaxWeightValue}{" "}
                                                              {flights.MaxWeightUnit}
                                                            </li>
                                                          </ul>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Cancellation",
                                                      key: "3",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.CancelPenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                    ,
                                                    {
                                                      label: "Date Change",
                                                      key: "4",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.ChangePenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                  ]}
                                                />
                                              </Panel>
                                            </Collapse>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div>
                                    {!RetConnectingMainFLights ? <></> : RetConnectingMainFLights.map((flights) => (
                                      <div
                                        id={flights.Option_Id}
                                        className="list-block main-block f-list-block"
                                      >
                                        <div style={{ display: "none" }}>
                                          {" "}
                                          {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                          {(img = flights.Carrier + ".png")}
                                        </div>
                                        <div className="card">
                                          <div className="card-header">Return</div>
                                          <div className="card-body">
                                            <div className="row">
                                              <div className="col-sm-3">
                                                <img
                                                  src={"images/airlines/" + img}
                                                  style={{ width: "80px", height: "80px" }}
                                                  alt="flight-img"
                                                />
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="time">
                                                  <div className="line line--tip">
                                                    <strong> {moment(flights.DepartureTime).format(
                                                      "HH:mm"
                                                    )}</strong>
                                                    <span
                                                      data-airport-role="departure"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Origin}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div className="line line--middle">
                                                    <span>
                                                      <span className="icon-text">
                                                        <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                                      </span>
                                                    </span>
                                                    <dom-if style={{ display: 'none' }}>
                                                      <template is="dom-if"></template>
                                                    </dom-if>
                                                    <div className="direct-label">
                                                      {renderStops(flights.Stops)}
                                                    </div>
                                                  </div>
                                                  <div className="line line--tip">
                                                    <strong>  {moment(flights.ArrivalTime).format(
                                                      "HH:mm"
                                                    )} </strong>
                                                    <span
                                                      data-airport-role="arrival"
                                                      className="code"
                                                      data-leg-index="0"
                                                    >
                                                      {" "}
                                                      {flights.Destination}
                                                      <span className="tooltip">
                                                        Click to show only flights
                                                        having this
                                                      </span>
                                                    </span>
                                                    <div className="labels">
                                                      <div
                                                        style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                                        <b></b>
                                                        <span className="tooltip"></span>
                                                      </div>
                                                      <div
                                                        style={{ cursor: 'auto', hidden: '' }}>
                                                        <wego-icon
                                                          className="small moon"
                                                          icon="moon"
                                                        ></wego-icon>
                                                        <span className="tooltip">
                                                          Overnight Flight
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-3">

                                                <a
                                                  key={flights.Option_Id}
                                                  id={flights.Option_Id}
                                                  onClick={(e) => HandleReturnClick(e.target.id,flights.GrandTotal)}
                                                  className="btn btn-orange"
                                                >
                                                  Book Now
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer text-left">
                                            <Collapse defaultActiveKey={["1"]} ghost>
                                              <Panel header="Flight Details" key="0">
                                                <Tabs
                                                  defaultActiveKey="1"
                                                  items={[
                                                    {
                                                      label: "Itinerary",
                                                      key: "1",
                                                      children: (
                                                        <>
                                                          <div className="row">
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                <img
                                                                  src={
                                                                    "images/airlines/" + img
                                                                  }
                                                                  style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                  }}
                                                                  alt="flight-img"
                                                                />
                                                              </li>
                                                              <li>
                                                                {" "}
                                                                <p className="block-title">
                                                                  {flights.Origin} to{" "}
                                                                  {flights.Destination}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <p>
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.DepartureTime
                                                                    ).format("HH:mm")}{" "}
                                                                  </span>
                                                                  -{" "}
                                                                  <span>
                                                                    {hours} hours - {minutes}{" "}
                                                                    minutes
                                                                  </span>{" "}
                                                                  -
                                                                  <span style={{fontSize:"large"}}>
                                                                    <i className="fa fa-plane fa-flip-vertical"></i>
                                                                  </span>
                                                                  <span
                                                                    style={{ color: "black",paddingLeft:"5px" }}
                                                                  >
                                                                    {moment(
                                                                      flights.ArrivalTime
                                                                    ).format("HH:mm")}
                                                                  </span>
                                                                </p>
                                                              </li>
                                                              <li>
                                                                {flights.Carrier} -{" "}
                                                                {flights.FlightNumber}{" "}
                                                              </li>
                                                              <li>
                                                                {flights.CabinClass} (
                                                                {flights.BookingCode})
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Baggage",
                                                      key: "2",
                                                      children: (
                                                        <>
                                                          <ul style={{ listStyle: "none" }}>
                                                            <li>
                                                              Allowed Weight:{" "}
                                                              {flights.MaxWeightValue}{" "}
                                                              {flights.MaxWeightUnit}
                                                            </li>
                                                          </ul>
                                                        </>
                                                      ),
                                                    },
                                                    {
                                                      label: "Cancellation",
                                                      key: "3",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.CancelPenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                    ,
                                                    {
                                                      label: "Date Change",
                                                      key: "4",
                                                      children: (
                                                        <>
                                                          <table>
                                                            <tr>
                                                              <th style={{ width: "70%" }}>
                                                                Time
                                                              </th>
                                                              <th
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                Fee
                                                              </th>
                                                            </tr>
                                                            <tr>
                                                              <td style={{ width: "70%" }}>
                                                                <p>
                                                                  Penalty for adults before
                                                                  departure (Not applicable
                                                                  within 24 hours of
                                                                  departure).{" "}
                                                                </p>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "30%",
                                                                  textAlign: "center",
                                                                }}
                                                              >
                                                                <p>{flights.ChangePenalty}</p>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </>
                                                      ),
                                                    },
                                                  ]}
                                                />
                                                {!RetConnectingFLights ? <></> : RetConnectingFLights.filter(
                                                  (cflights) =>
                                                    cflights.Option_Id ===
                                                    flights.Option_Id &&
                                                    flights.Flight === "RM"
                                                ).map((cflights) => (
                                                  <Tabs
                                                    defaultActiveKey="1"
                                                    items={[
                                                      {
                                                        label: "Itinerary",
                                                        key: "1",
                                                        children: (
                                                          <>
                                                            <div className="row">
                                                              <ul
                                                                style={{ listStyle: "none" }}
                                                              >
                                                                <li>
                                                                  <img
                                                                    src={
                                                                      "images/airlines/" + img
                                                                    }
                                                                    style={{
                                                                      width: "80px",
                                                                      height: "80px",
                                                                    }}
                                                                    alt="flight-img"
                                                                  />
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p className="block-title">
                                                                    {cflights.Origin} to{" "}
                                                                    {cflights.Destination}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  <p>
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color: "black",paddingLeft:"5px"
                                                                      }}
                                                                    >
                                                                      {moment(
                                                                        cflights.DepartureTime
                                                                      ).format("HH:mm")}{" "}
                                                                    </span>
                                                                    -{" "}
                                                                    <span>
                                                                      {hours} hours -{" "}
                                                                      {minutes} minutes
                                                                    </span>{" "}
                                                                    -
                                                                    <span style={{fontSize:"large"}}>
                                                                      <i className="fa fa-plane fa-flip-vertical"></i>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color: "black",paddingLeft:"5px"
                                                                      }}
                                                                    >
                                                                      {moment(
                                                                        cflights.ArrivalTime
                                                                      ).format("HH:mm")}
                                                                    </span>
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {cflights.Carrier} -{" "}
                                                                  {cflights.FlightNumber}{" "}
                                                                </li>
                                                                <li>
                                                                  {cflights.CabinClass} (
                                                                  {cflights.BookingCode})
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Baggage",
                                                        key: "2",
                                                        children: (
                                                          <>
                                                            <ul style={{ listStyle: "none" }}>
                                                              <li>
                                                                Allowed Weight:{" "}
                                                                {cflights.MaxWeightValue}{" "}
                                                                {cflights.MaxWeightUnit}
                                                              </li>
                                                            </ul>
                                                          </>
                                                        ),
                                                      },
                                                      {
                                                        label: "Cancellation",
                                                        key: "3",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {cflights.CancelPenalty}
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                      ,
                                                      {
                                                        label: "Date Change",
                                                        key: "4",
                                                        children: (
                                                          <>
                                                            <table>
                                                              <tr>
                                                                <th style={{ width: "70%" }}>
                                                                  Time
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  Fee
                                                                </th>
                                                              </tr>
                                                              <tr>
                                                                <td style={{ width: "70%" }}>
                                                                  <p>
                                                                    Penalty for adults before
                                                                    departure (Not applicable
                                                                    within 24 hours of
                                                                    departure).{" "}
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width: "30%",
                                                                    textAlign: "center",
                                                                  }}
                                                                >
                                                                  <p>
                                                                    {cflights.ChangePenalty}
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </>
                                                        ),
                                                      },
                                                    ]}
                                                  />
                                                ))}
                                              </Panel>
                                            </Collapse>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          )
                        }
                        else {
                          return (<></>)
                        }
                      })()}
                    </div>
                    {isHitITData.childAData === null && 
                        (
                          <div class="needHelp  inlineDiv col-12 col-md-3 col-lg-3" style={{ maxHeight: "150px !important" }} >
                          <h2 class="rotate45">Need Help?</h2>
                          <div class="need_help_extend">
                            <div class="d-block row">
                              <div class="inlineDiv"><img src={ImageSupport} width="53" height="53"></img></div>
                              <div class="inlineDiv ml-3">
                                <span class="d-block colorGrey">For For booking help</span>
                                <span class="d-block colorBlack">(051) 111 77 88 00</span>
                              </div>
                            </div>
                  
                          </div>
                        </div>
                        )
                        }
                  </div>
                

             
            </section>
          </div>
        )}


      </div>
    </>
  );

}
export default GalileoFlights;