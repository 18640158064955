import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useHistory
import moment from "moment";
import { Collapse, Tabs, Spin } from "antd";
import axios from "axios";
import qs from 'qs';
import '../../CustomCss.css';
import ImageSupport from "../../Icons/support.png";
import MainMenu from "../Layout/MainMenu";

function MultiCity() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useHistory hook
  const { props: inputValues } = location.state || {};
  
  const { Panel } = Collapse;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [allflight, setAllFlight] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const replaceNullWithZero = (obj) => {
    const newObj = { ...obj };
    for (let key in newObj) {
      if (newObj[key] === null) {
        newObj[key] = 0;
      }
    }
    return newObj;
  };

  const handleTrip = async () => {
    setLoading(true);
    setError(null);
    try {
      const updatedFormValues = replaceNullWithZero(inputValues);
      const queryString = qs.stringify(updatedFormValues, { arrayFormat: 'comma' });
      const url = `${apiUrl}/api/FlightSearch/results?${queryString}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        const data = response.data;
        setAllFlight(data);
      } else {
        setError('Something went wrong while fetching flight data.');
      }
    } catch (error) {
      setError('Something went wrong while fetching flight data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTrip();
  }, [inputValues]);

  const renderConnectingFlights = (connectingSegments) => {
    const convertMinutesToHoursAndMinutes = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };
   
    return connectingSegments.map((segment, index) => (
      <div key={index}>
        <ul style={{ listStyle: "none" }}>
          <li>
            <img
              src={`images/airlines/${segment.Carrier + ".png"}`}
              style={{ width: "80px", height: "80px" }}
              alt="flight-img"
            />
          </li>
          <li>
            <p className="block-title">
              {segment.Origin} to {segment.Destination}
            </p>
          </li>
          <li>
            <p>
              <span style={{ fontSize: "large" }}>
                <i className="fa fa-plane"></i>
              </span>
              <span style={{ color: "black", paddingLeft: "5px" }}>
                {moment(segment.DepartureTime).format("HH:mm")}
              </span>
              - {convertMinutesToHoursAndMinutes(segment.FlightTime)}
              <span style={{ fontSize: "large" }}>
                <i className="fa fa-plane fa-flip-vertical"></i>
              </span>
              <span style={{ color: "black", paddingLeft: "5px" }}>
                {moment(segment.ArrivalTime).format("HH:mm")}
              </span>
            </p>
          </li>
          <li>{segment.Carrier} - {segment.FlightNumber}</li>
          <li>{segment.CabinClass} ({segment.BookingCode})</li>
        </ul>
      </div>
    ));
  };
  const handleBookClick = (optionIds) => {
    // Ensure optionIds is always an array and convert to numbers
    if (!Array.isArray(optionIds)) {
        optionIds = [optionIds];
    }
    
    optionIds = optionIds.map(id => Number(id)); // Convert to numbers

    console.log("Option IDs being passed to the next screen:", optionIds);
  
    // Get all the segments that match any of the optionIds
    const flightData = allflight.FlightSegments.filter(segment => {
        console.log("Checking Segment Option_Id:", segment.Option_Id);
        return optionIds.includes(segment.Option_Id);
    });

    if (flightData.length === 0) {
        console.error("No flight data matches the provided Option IDs.");
        return;
    }
//adults: Adults, children: Children, infants: Infants 
    // Navigate to the next screen and pass the grouped segments data
    navigate("/Multi_Booking", { state: { props: flightData , adults:inputValues.adults,children:inputValues.children, infants:inputValues.infants} });
};

const renderFlightDetails = (segmentRefs, optionIds) => {
    return segmentRefs.map((segmentRef, index) => {
        const flightSegment = allflight.FlightSegments.find(flight => flight.SegmentRef === segmentRef);
        if (flightSegment) {
            const img = flightSegment.Carrier + ".png";
            const convertMinutesToHoursAndMinutes = (totalMinutes) => {
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
            };

            const connectingSegments = allflight.FlightSegments.filter(segment => 
                segment.Option_Id === flightSegment.Option_Id && segment.SegmentRef !== segmentRef
            );

            return (
                <div key={flightSegment.Option_Id} className="card mt-0">
                    <div className="card-header d-flex justify-content-between" style={{ backgroundColor: 'white' }}>
                        <span><b>{flightSegment.Carrier}</b></span>
                        <span>Departure</span>
                        {index === 0 && (
                            <div style={{ textAlign: 'right' }}>
                                <p style={{ color: 'black', fontSize: "20px" }}>
                                    <b>{flightSegment.AdultTotalPrice}</b>
                                </p>
                                <button className="btn btn-orange" onClick={() => handleBookClick(optionIds)}>
                                    Book This
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-3">
                                <img
                                    src={`images/airlines/${img}`}
                                    style={{ width: "80px", height: "80px" }}
                                    alt="flight-img"
                                />
                            </div>
                            <div className="col-sm-6 mt-3">
                                <div className="time">
                                    <div className="line line--tip">
                                        <strong>{moment(flightSegment.DepartureTime).format("HH:mm")}</strong>
                                        <span className="code">
                                            {flightSegment.Origin}
                                        </span>
                                    </div>
                                    <div className="line line--middle">
                                        <span className="icon-text">
                                            <b>Total</b><br />
                                            {convertMinutesToHoursAndMinutes(flightSegment.FlightTime)}
                                        </span>
                                        <div className="direct-label">{flightSegment.Stops > 0 ? `${flightSegment.Stops} Stop` : "Direct"}</div>
                                    </div>
                                    <div className="line line--tip">
                                        <strong>{moment(flightSegment.ArrivalTime).format("HH:mm")}</strong>
                                        <span className="code">
                                            {flightSegment.Destination}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-left">
                    <Collapse defaultActiveKey={["1"]} ghost>
              <Panel header="Flight Details" key="0">
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Itinerary" key="1">
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <img
                          src={`images/airlines/${img}`}
                          style={{ width: "80px", height: "80px" }}
                          alt="flight-img"
                        />
                      </li>
                      <li>
                        <p className="block-title">
                          {flightSegment.Origin} to {flightSegment.Destination}
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontSize: "large" }}>
                            <i className="fa fa-plane"></i>
                          </span>
                          <span style={{ color: "black", paddingLeft: "5px" }}>
                            {moment(flightSegment.DepartureTime).format("HH:mm")}
                          </span>
                          - {convertMinutesToHoursAndMinutes(flightSegment.FlightTime)}
                          <span style={{ fontSize: "large" }}>
                            <i className="fa fa-plane fa-flip-vertical"></i>
                          </span>
                          <span style={{ color: "black", paddingLeft: "5px" }}>
                            {moment(flightSegment.ArrivalTime).format("HH:mm")}
                          </span>
                        </p>
                      </li>
                      <li>{flightSegment.Carrier} - {flightSegment.FlightNumber}</li>
                      <li>{flightSegment.CabinClass} ({flightSegment.BookingCode})</li>
                    </ul>
                    {connectingSegments.length > 0 && (
                      <div style={{ marginTop: "15px" }}>
                        <h5>Connecting Flights</h5>
                        {renderConnectingFlights(connectingSegments)}
                      </div>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Baggage" key="2">
                    <ul style={{ listStyle: "none" }}>
                      <li>Allowed Weight: {flightSegment.MaxWeightValue} {flightSegment.MaxWeightUnit}</li>
                    </ul>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Cancellation" key="3">
                    <table>
                      <tr>
                        <th style={{ width: "70%" }}>Time</th>
                        <th style={{ width: "30%", textAlign: "center" }}>Fee</th>
                      </tr>
                      <tr>
                        <td style={{ width: "70%" }}>
                          <p>Penalty for adults before departure (Not applicable within 24 hours of departure).</p>
                        </td>
                        <td style={{ width: "30%", textAlign: "center" }}>
                          <p>{flightSegment.CancelPenalty}</p>
                        </td>
                      </tr>
                    </table>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Date Change" key="4">
                    <table>
                      <tr>
                        <th style={{ width: "70%" }}>Time</th>
                        <th style={{ width: "30%", textAlign: "center" }}>Fee</th>
                      </tr>
                      <tr>
                        <td style={{ width: "70%" }}>
                          <p>Penalty for adults before departure (Not applicable within 24 hours of departure).</p>
                        </td>
                        <td style={{ width: "30%", textAlign: "center" }}>
                          <p>{flightSegment.ChangePenalty}</p>
                        </td>
                      </tr>
                    </table>
                  </Tabs.TabPane>
                </Tabs>
              </Panel>
            </Collapse>
                    </div>
                </div>
            );
        }
        return null;
    });
};


  const renderSegmentCards = (item, index) => {
    const segmentRefs = [
        item.SegmentRef1, item.SegmentRef2, item.SegmentRef3, item.SegmentRef4, item.SegmentRef5, item.SegmentRef6
    ];
    const optionIds = [
        item.Option_id1, item.Option_id2, item.Option_id3, item.Option_id4, item.Option_id5, item.Option_id6
    ].filter(id => id); // Filter out any undefined or null values

    return (
        <div style={{ marginTop: 10 }} key={index}>
            {renderFlightDetails(segmentRefs, optionIds)}
        </div>
    );
};

  return (
    <>
      <div>
        {loading && <div className="loader"></div>}
        <MainMenu />
        {!loading && (

          <div>
         
            <section className="innerpage-wrapper">
              <div id="flight-listings" className="innerpage-section-padding" >
                <div className="NoMargin" style={{margin: "10px 100px 0px"}}>
                  <div className="row w-100" style={{ marginLeft: "0px" }}>
                    <div className="col-12 col-md-9 col-lg-9 pl-0">
                      <div className="content-side">
                        <div id="DepartureFlights">
                          <div>
                           
                            {Array.isArray(allflight.Segments) && allflight.Segments.length > 0 ? (
                              allflight.Segments.map(renderSegmentCards)
                            ) : (
                              <p>No direct flights available.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="needHelp inlineDiv col-12 col-md-3 col-lg-3" style={{ maxHeight: "150px !important", marginTop:'10px' }}>
                      <h2 className="rotate45">Need Help?</h2>
                      <div className="need_help_extend">
                        <div className="d-block row">
                          <div className="inlineDiv">
                            <img src={ImageSupport} width="53" height="53" alt="Support" />
                          </div>
                          <div className="inlineDiv ml-3">
                            <span className="d-block colorGrey">For For booking help</span>
                            <span className="d-block colorBlack">(051) 111 77 88 00)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default MultiCity;
