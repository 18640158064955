
import React from 'react';
import './UmrahPackages.css';
import UmrahEnquiryForm from './UmrahEnquiryForm';


const UmrahPackagesDays= ({ 
    SevenPackageRef,
    TenDaysPackageRef,
    FifteenDaysPackageRef,
    TwentyOnePackageRef,
    TwentyEightPackageRef,
    CustomPackageCRef

}) => {

    const scrollToSevenPackage = () => {
        if (SevenPackageRef && SevenPackageRef.current) {
            SevenPackageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTenPackage = () => {
        if (TenDaysPackageRef && TenDaysPackageRef.current) {
            TenDaysPackageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToFifteenPackage = () => {
        if (FifteenDaysPackageRef && FifteenDaysPackageRef.current) {
            FifteenDaysPackageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTwentyPackage = () => {
        if (TwentyOnePackageRef && TwentyOnePackageRef.current) {
            TwentyOnePackageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTwentyEightPackage = () => {
        if (TwentyEightPackageRef && TwentyEightPackageRef.current) {
            TwentyEightPackageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToCustomPackage = () => {
        if (CustomPackageCRef && CustomPackageCRef.current) {
            CustomPackageCRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    


    return (
        <div className="umh-duration-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="umh-heading">
                            <h2> Explore Our Flexible Umrah Packages for Various Durations</h2>
                            <p>At Malik Express Travel & Tours, we understand that every pilgrim's journey is unique. That's why we offer a range of Umrah packages tailored to fit different schedules and needs. Whether you're looking for a quick spiritual getaway or a more extended stay to immerse yourself in holy cities, our packages provide the perfect balance of comfort, convenience, and affordability. Choose the duration that suits you best and embark on a memorable pilgrimage with us.</p>
                        </div>
                       
                        <UmrahEnquiryForm/>
                        
                        
                        <ul className="umh-duration-list">
                            <li onClick={scrollToSevenPackage}><a>7<em>Days</em></a></li>
                            <li onClick={scrollToTenPackage}><a >10<em>Days</em></a></li>
                            <li onClick={scrollToFifteenPackage}><a >15<em>Days</em></a></li>
                            <li onClick={scrollToTwentyPackage}><a>21<em>Days</em></a></li>
                            <li onClick={scrollToTwentyEightPackage}><a>28<em>Days</em></a></li>
                            <li>
                                <a href='#'>
                                    <span>
                                        Make My
                                        Custom
                                        Package
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
             
            </div>
          
        </div>
    );
}

export default UmrahPackagesDays;
