import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import FrontPage from './Components/FrontPage';
import SearchResult from './Components/SearchResult/SearchResult';
import FlightBooking from './Components/SearchResult/FlightBooking';
import ThankYou from './Components/SearchResult/ThankYou';
import ThankYouHitIt from './Components/SearchResult/ThankyouHitIt';
import Payment from './Components/SearchResult/Payment';
import Visas from './Components/Visa/VisaSearchBar';
import VisaSearchResult from './Components/Visa/VisaSearchResult';
import VisaPersonDetails from './Components/Visa/VisaPersonDetails';
import VisaThankYou from './Components/Visa/VisaThankYou';
import DubaiPackages from './Components/Tours/DubaiPackages';
import ThailandPackages from './Components/Tours/ThailandPackages';
import MalaysiaPackages from './Components/Tours/MalaysiaPackages';
import TurkeyPackages from './Components/Tours/TurkeyPackages';
import HititBooking from './Components/SearchResult/HititBooking';
import UmrahPackages from './Components/UmrahPackages/UmrahPackages';
import UmrahHotels from './Components/UmrahPackages/UmrahHotels';
import Flights from './Components/Flight/flights';
import Hotels from './Components/Hotel/hotels';
import TourPackages from './Components/Tours/TourPackages';
import About from './Components/About/About';
import Contact from './Components/About/Contact';
import PrivacyPolicy from './Components/About/PrivacyPolicy';
import TermsConditions from './Components/About/TermsConditions';
import FlightBookingCard from './Components/SearchResult/FlightBookingCard';
import Blog from './Components/Blog/blog';
import Post from './Components/Blog/Post';
import TravelInsurance from './Components/Insurance/TravelInsurance';
import TravelSearch from './Components/Insurance/Travel_search';
import TravelInsurance_Booking from './Components/Insurance/TravelInsurance_Booking';
import Beneficiary_Details from './Components/Insurance/Beneficiary Details';
import Review_Information from './Components/Insurance/Review Information';
import Servicelink from './Components/Service&Links/Service&link';
import ConfirmedPayment from './Components/Insurance/ConfirmedPayment';
import SelectPaymnt from './Components/Insurance/Select Payment Option';
import Unisersal_booking from './Components/Insurance/Unisersal_booking';
import Beneficiary_Universal from './Components/Insurance/Beneficiary_Universal';
import PreviewPayment from './Components/Insurance/PreviewPayment';
import Review_Universal from './Components/Insurance/Review_Universal';
import ThankYouGall from './Components/SearchResult/ThankyouGall';
import Testing from './Components/Insurance/Testing';
import SelectPaymntUniversal from './Components/Insurance/Select Payment Universal';
import TravelPlan from './Components/Insurance/TravelPlan';
import International from './Components/Insurance/International';
import Visitor_Travel from './Components/Insurance/Visitor_Travel';
import Covid_Travel from './Components/Insurance/Covid_Travel';
import Schengen_Trave from './Components/Insurance/Schengen_Travel';
import Student_Travel from './Components/Insurance/Student_Travel';
import { Business } from '@mui/icons-material';
import Business_Travel from './Components/Insurance/Business_Travel';
import UmrahHome from './Components/UmrahPackages/UmrahHome';
import RouteForm from './Components/UmrahPackages/RouteForm';
import Lahore from './Components/UmrahPackages/Lahore';
import Karachi from './Components/UmrahPackages/Karachi';
import Islamabad from './Components/UmrahPackages/Islamabad';
import Quetta from './Components/UmrahPackages/Quetta';
import Peshawar from './Components/UmrahPackages/Peshawar';
import Multan from './Components/UmrahPackages/Multan';
import Sialkot from './Components/UmrahPackages/Sialkot';
import Faisalbad from './Components/UmrahPackages/Faisalbad';
import MultiCity from './Components/SearchResult/MultiCity';
import Multi_Booking from './Components/SearchResult/Multi_Booking';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/SearchResult" element={<SearchResult />} />
          <Route path="/FlightBooking" element={<FlightBooking />} />
          <Route path="/umrahform" element={<RouteForm />} />

          <Route path="/Lahore" element={<Lahore />} />
          <Route path="/Karachi" element={<Karachi />} />
          <Route path="/Islamabad" element={<Islamabad />} />
          <Route path="/Quetta" element={<Quetta />} />
          <Route path="/Peshawar" element={<Peshawar />} />
          <Route path="/Multan" element={<Multan />} />
          <Route path="/Faisalbad" element={<Faisalbad />} />
          <Route path="/Sialkot" element={<Sialkot />} />
          <Route path="/MultiCity" element={<MultiCity />} />
          <Route path="/Multi_Booking" element={<Multi_Booking />} />

          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/thankyouhitit" element={<ThankYouHitIt />} />
          <Route path="/flightbookingcard" element={<FlightBookingCard />} />
          <Route path="/travel-agency-in-islamabad" element={<About />} />
          <Route path="/travel-agency-contact-number" element={<Contact />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/cheap-flights-booking" element={<Flights />} />
          <Route path="/hotel-booking" element={<Hotels />} />
          <Route path="/visa-consultancy" element={<Visas />} />
          <Route path="/Visas/:country" element={<VisaSearchResult />} />
          <Route path="/VisaPersonDetails" element={<VisaPersonDetails />} />
          <Route path="/VisaThankYou" element={<VisaThankYou />} />
          <Route path="/DubaiPackages" element={<DubaiPackages />} />
          <Route path="/ThailandPackages" element={<ThailandPackages />} />
          <Route path="/MalaysiaPackages" element={<MalaysiaPackages />} />
          <Route path="/TurkeyPackages" element={<TurkeyPackages />} />
          <Route path="/tour-packages-from-pakistan" element={<TourPackages />} />
          <Route path="/umrah-packages-from-pakistan" element={<UmrahHome />} />
          <Route path="/UmrahHotels" element={<UmrahHotels />} />
          <Route path="/TravelSearch" element={<TravelSearch />} />
          <Route path="/travel-agency-in-islamabad" element={<About />} />
          <Route path="/travel-agency-contact-number" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsConditions />} />
          <Route path="/hititbooking" element={<HititBooking />} />
          <Route path="/travel-blog" element={<Blog />} />
          <Route path="/Blog/Post/:postname" element={<Post />} />
          <Route path="/online-travel-insurance-pakistan" element={<TravelInsurance />} />
          <Route path="/TravelInsurance_Booking" element={<TravelInsurance_Booking />} />
          <Route path="/Beneficiary_Details" element={<Beneficiary_Details />} />
          <Route path="/Review_Information" element={<Review_Information />} />
          <Route path="/ConfirmedPayment" element={<ConfirmedPayment />} />
          <Route path="/SelectPaymnt" element={<SelectPaymnt />} />
          <Route path="/Servicelink" element={<Servicelink />} />
          <Route path="/Unisersal_booking" element={<Unisersal_booking />} />
          <Route path="/Beneficiary_Universal" element={<Beneficiary_Universal />} />
          <Route path="/PreviewPayment" element={<PreviewPayment />} />
          <Route path="/Review_Universal" element={<Review_Universal />} />
          <Route path="/ThankYouGall" element={<ThankYouGall />} />
          <Route path="/Testing" element={<Testing />} />
          <Route path="/SelectPaymntUniversal" element={<SelectPaymntUniversal />} />
          <Route path="/TravelPlan" element={<TravelPlan />} />
          <Route path="/international-travel-insurance-online" element={<International />} />
          <Route path="/visitor-travel-insurance-online" element={<Visitor_Travel />} />
          <Route path="/covid-covered-travel-insurance-online" element={<Covid_Travel />} />
          <Route path="/travel-insurance-for-schengen-visa-online" element={<Schengen_Trave />} />
          <Route path="/student-travel-insurance-online" element={<Student_Travel/>} />
          <Route path="/business-travel-insurance-online" element={<Business_Travel />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
