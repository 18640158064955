import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import {Link, useLocation , useNavigate} from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet";


function ThankYouGall(){

   const location = useLocation();
   const propsdata = location.state.props;

  //console.log('props data is',propsdata);

  useEffect(() => {
    if (propsdata) {
        fetchData1();
    }
}, [propsdata]);
  const queryParameters = new URLSearchParams(window.location.search)
  const pp_ResponseCode = queryParameters.get("pp_ResponseCode")
  const pp_ResponseMessage = queryParameters.get("pp_ResponseMessage")
  let bookingId = queryParameters.get("pp_BillReference")

  const [data, setData] = useState(null);
  const [departurecity, setDepartureCity] = useState("");
  const [arrivalcity, setArrivalCity] = useState("");
  const [departurecitycode, setDepartureCityCode] = useState("");
  const [arrivalcitycode, setArrivalCityCode] = useState("");

  const [departurecitycon, setDepartureCityCon] = useState("");
  const [arrivalcitycon, setArrivalCityCon] = useState("");
  const [departurecitycodecon, setDepartureCityCodeCon] = useState("");
  const [arrivalcitycodecon, setArrivalCityCodeCon] = useState("");
  const [paymentDetail, setPaymentDetail] = useState({});
 // const [grandTotal, setGrandTotal] = useState(0);
const [origin_price, setOrigin_price] = useState(null)
const [mobile, setmobile] = useState('')
const [email, setEmail] = useState('')
const [png, setpng] = useState('')
const [vendor, setvendor] = useState('')
  let OriginID=0;
  let DestinationId=0;
  let OriginIDConnecting=0;
  let DestinationIDConnecting=0;

  const [grandTotal, setGrandTotal] = useState(0);

  const searchParams = new URLSearchParams(window.location.search);

  const apiUrl = process.env.REACT_APP_API_URL;

  const url = apiUrl + "/api/HitItFlightSearch";

   async function fetchData1() {
    await axios
    .get(url + "/flightbookingdetails?BookingID=" + propsdata)
    .then((response) => {
    debugger
   const returnData = response.data;
    setData(response.data);
      setEmail(returnData.Reservation[0].Email)
      setmobile(returnData.Reservation[0].PrimaryPhone)
      setOrigin_price(returnData.Reservation[0].OriginalCost)
      setpng(returnData.Reservation[0].PNR)
      setGrandTotal(returnData.Reservation[0].GrandTotal)
      setvendor('GALL')
    if(response.data!==null)
    {
         OriginID= response.data.flightSegments[0].OriginID;
         DestinationId = response.data.flightSegments[0].DestinationID;
         fetchOrigin(OriginID);
         fetchDestination(DestinationId);
        if(response.data.flightSegments[1]!==null)
        {
            OriginIDConnecting = response.data.flightSegments[1].OriginID;
            DestinationIDConnecting = response.data.flightSegments[1].DestinationID;
           // fetchOrigin(OriginIDConnecting);
           fetchOriginConnecting(OriginIDConnecting)
           fetchDestinationConnecting(DestinationIDConnecting);
        }
        console.log('responce data is ',response.data);

    }
    console.log('responce data is ',response.data);
    setTimeout(() => {
        setData(response.data);

      }, 2000);
    console.log('responce data is ',response.data);    
    }) 
      .catch((error) => {
        console.log(error);
      });
  } 

  async function fetchOrigin(id) {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/citynamebyid?term=" + id
      )
      .then((response) => {
        const returnData = response.data;
        setDepartureCity(response.data.Name);
        setDepartureCityCode(response.data.Code);
        setTimeout(() => {
           

        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchDestination(id) {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/citynamebyid?term=" + id
      )
      .then((response) => {
        const returnData = response.data;
        debugger
        setArrivalCity(response.data.Name);
        setArrivalCityCode(response.data.Code);
        setTimeout(() => {
         // setOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchOriginConnecting(id) {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/citynamebyid?term=" + id
      )
      .then((response) => {
        const returnData = response.data;
        setDepartureCityCon(response.data.Name);
        setDepartureCityCodeCon(response.data.Code);
        setTimeout(() => {
           

        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchDestinationConnecting(id) {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/citynamebyid?term=" + id
      )
      .then((response) => {
        const returnData = response.data;
        debugger
        setArrivalCityCon(response.data.Name);
        setArrivalCityCodeCon(response.data.Code);
        setTimeout(() => {
         // setOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function calculateTimeDifference(time1, time2) {
    const momentTime1 = moment(time1, 'HH:mm:ss');
    const momentTime2 = moment(time2, 'HH:mm:ss');
  
    const duration = moment.duration(momentTime2.diff(momentTime1));
  
    const hours = duration.hours();
    const minutes = duration.minutes();
  
    return { hours, minutes };
  }
 

  function formatTimeToAMPM(inputTime) {
    if(inputTime!==""){
        const parts = inputTime.split(":"); // Split the input time into hours, minutes, and seconds
  
        // Create a new Date object with the time set to the input time
        const date = new Date();
        date.setHours(parseInt(parts[0], 10)); // Set hours
        date.setMinutes(parseInt(parts[1], 10)); // Set minutes
        date.setSeconds(parseInt(parts[2], 10)); // Set seconds
      
        // Format the date in 12-hour format with AM/PM
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      
        return formattedTime; // Return the formatted time
    }
  }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  const Fastpay = async (setPaymentDetail) => {
    const currentDates = formatDate(new Date());

    try {
        if (png && grandTotal && mobile && email && vendor ) {
            const response = await fetch('https://api.malikexpress.com/api/payfast/GetAccessToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    merchant_id: '21277',
                    secured_key: '-VFew2B7oKZBitKsXjXnAJ5-FG'
                })
            });

            const data = await response.json();

            const authToken = data.accesS_TOKEN;
            console.log('fast token', authToken);

            const payload = {
                BASKET_ID: `${png}-${vendor}-${origin_price}`,
                ORDER_DATE: currentDates,
                TOKEN: authToken,
                CURRENCY_CODE: 'PKR',
                MERCHANT_ID: '21277',
                SUCCESS_URL: 'https://malikexpress.com/previewpayment/',
                FAILURE_URL: 'https://malikexpress.com/previewpayment/',
                CHECKOUT_URL: 'https://typedwebhook.tools/webhook/cfe4e40e-8c5c-4d5b-867a-017bce41070c',
                CUSTOMER_EMAIL_ADDRESS: email,
                CUSTOMER_MOBILE_NO: mobile,
                TXNAMT: grandTotal,
                SIGNATURE: '',
                VERSION: 'MERCHANT-CART-0.1',
                TXNDESC: 'Item Purchased from Cart',
                PROCCODE: '00',
                TRAN_TYPE: 'ECOMM_PURCHASE',
                STORE_ID: '',
                RECURRING_TXN: 'false'
            };

            setPaymentDetail(payload);
        } else {
            console.error('Required fields are missing:', { png, grandTotal, mobile, email, vendor });
        }
    } catch (error) {
        console.error('Error:', error.message);
    }
};
useEffect(() => {
  console.log('payfast is', grandTotal, origin_price, png, email, mobile, vendor);
  Fastpay(setPaymentDetail);
}, [grandTotal, png, email, mobile, vendor]);

const handlepayfast = async () => {
  console.log('Payment detail before submission:', paymentDetail);
  document.getElementById("FormPG").submit();
};


    return(
        <>
               <Helmet>
        <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>

        <meta
          name="description"
          content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://malikexpress.com/thankyou" />
      </Helmet>
        <MainMenu></MainMenu>
      
        <section className="innerpage-wrapper">
        <div className="innerpage-section-padding">
       
        {propsdata === 'something went wrong' ? (
        
        <h3 style={{display:'flex',alignItems:'center',justifyContent:'center'}}>{propsdata} Please call at this number +92 311 1778811</h3>
   
          
        ) : (
          <div className="container SlipBackground p-0"style={{maxWidth:"960px"}}>
          <div className="w-100 m-0 pt-2 pl-2 MainSlip"style={{fontWeight:"600",color:"white"}}>  <h3>BOOKING DETAILS</h3></div>
          <div className="w-100 m-0 pt-2 pl-2"style={{color:"#0867d2",backgroundColor:"#d0dadf",fontWeight:"600" }}><span>{departurecity} - {arrivalcity}</span></div>
        <div className="">
                          <div className="d-flex justify-content-between col m-5 w-100">
                              <div className="">
                                  <label>PNR ID:</label>
                                  <h2>
                                      {data ? data.Reservation[0].PNR : ""}
                                  </h2>
                              </div>
                             <div className="">

                             </div>
                             
                              <span className="float-right mr-5">
                                  <label className="">Total Amount:</label>
                                  <h3>
                                      {data ? data.Reservation[0].GrandTotal : ""}
                                  </h3>
                              </span>
                          </div>
                        
        
                          <div className="row m-5">
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>Passenger Name:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? data.PaxList[0].FirstName : ""} {data ? data.PaxList[0].LastName:""}</span></span>
                              </div>
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>Gender:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? (data.PaxList[0].Gender ? "Male" : "Female") : ""} </span></span>
                              </div>
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>DOB:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? moment(data.PaxList[0].DOB).format("DD MMMM YYYY") : ""}</span></span>
                              </div>
                          </div>

                          <div className="row m-5">
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>Document #:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? data.PaxList[0].Document : ""} </span></span>
                              </div>
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>DOE:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? moment(data.PaxList[0].DOE).format("DD MMMM YYYY") : ""}</span></span>
                              </div>
                              <div className="col-lg-4 col-sm-12">
                                  <span><span>Booking Date:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>{data ? moment(data.Reservation[0].BookingDate).format("DD MMMM YYYY") : ""}</span></span>
                              </div>
                          </div>
                          <div className="m-5 row" style={{ float: 'right' }}>
            <PaymentForm paymentDetail={paymentDetail} />
            <button className="btn-lg fn semibold" onClick={handlepayfast} style={{ background: '#0867d2', color: '#fff', padding: '10px', borderRadius: '10px' }}>
                PayNow
            </button>
        </div>
        </div>
          <div className="row col-12 p-0 m-0"style={{backgroundColor:"#eeeeee"}}>
                          <div className="col-lg-1 col-sm-12 p-2 ml-5">
                              <div className="">
                            
                                  { data ? data.flightSegments[0].OperatingAirlineName:""} - { data ? data.flightSegments[0].FlightNumber:""}
                              </div>
                              <div className="">
                                  <img
                                      src={"images/airlines/PK.png"}
                                      style={{ width: "47px", height: "30px" }}
                                      alt="flight-img"
                                  />

                              </div>
                          </div>
          <div className="col-5 p-2">
              <div className=""style={{fontWeight:"bolder"}}>DEPART:</div>
              <div className="" style={{lineHeight:"initial"}}>{departurecity ? departurecity:""} ({departurecitycode ? departurecitycode:""})</div>
              { data ? moment(data.flightSegments[0].DepartureDate).format("dddd DD MMMM YYYY") :""}  
              {" "}
              { data ? ( formatTimeToAMPM(data.flightSegments[0].DepartureTime)):""}
          </div>
          <div className="col-5 p-2">
          <div className=""style={{fontWeight:"bolder"}}>ARRIV:</div>
              <div className="" style={{lineHeight:"initial"}}>{arrivalcity ? arrivalcity:""} ({arrivalcitycode ? arrivalcitycode:""})</div>
              { data ? moment(data.flightSegments[0].ArrivalDate).format("dddd DD MMMM YYYY") :""}  
              {" "}
              { data ? ( formatTimeToAMPM(data.flightSegments[0].ArrivalTime)  ):""}

          </div>
          </div>
          

          <div className="row">
              <div className="ml-5">
              {/* <span> <span className="font-weight-bold"style={{color:"#6e6464"}}>Duration:</span> <span style={{color:"#6e6464"}}>{calculateTimeDifference(data ? data.flightSegments[0].DepartureTime:"", data ? data.flightSegments[0].ArrivalTime:"")}</span></span> */}

              <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Cabin Class:</span> <span style={{color:"#6e6464"}}>Economy (Y)</span></span>
              
              <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Airline:</span> <span style={{color:"#6e6464"}}>Pakistan Airlines</span></span>

              <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Stop:</span> <span style={{color:"#6e6464"}}>0</span></span>

              <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Baggage Allowance:</span> <span style={{color:"#6e6464"}}>{data ? data.flightSegments[0].Beggage:""}</span></span>

              </div>
          </div>

          { data && data.flightSegments[1] ? <> <div className="row col-12 p-0 m-0"style={{backgroundColor:"#eeeeee"}}>
                          <div className="col-lg-1 col-sm-12 p-2 ml-5">
                              <div className="">
                                  PK-2312
                              </div>
                              <div className="">
                                  <img
                                      src={"images/airlines/PK.png"}
                                      style={{ width: "47px", height: "30px" }}
                                      alt="flight-img"
                                  />

                              </div>
                          </div>
          <div className="col-5 p-2">
              <div className=""style={{fontWeight:"bolder"}}>DEPART:</div>
              <div className="" style={{lineHeight:"initial"}}>{departurecitycon ? departurecitycon:""} ({departurecitycodecon ? departurecitycodecon:""})</div>
              <div className=""style={{lineHeight:"initial"}}>Thursday 29 August 2023 05:05AM</div>
          </div>
          <div className="col-5 p-2">
          <div className=""style={{fontWeight:"bolder"}}>ARRIV:</div>
              <div className="" style={{lineHeight:"initial"}}>{arrivalcitycon ? arrivalcitycon:""} ({arrivalcitycodecon ? arrivalcitycodecon:""})</div>
              <div className=""style={{lineHeight:"initial"}}>Thursday 30 August 2023 05:05AM</div>
          </div>
          </div>
          

          <div className="row">
              <div className="ml-5">
              {/* <span> <span className="font-weight-bold"style={{color:"#6e6464"}}>Duration:</span> <span style={{color:"#6e6464"}}>{calculateTimeDifference(data ? data.flightSegments[1].DepartureTime:"", data ? data.flightSegments[1].ArrivalTime:"")}</span></span> */}

              <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Cabin Class:</span> <span style={{color:"#6e6464"}}>Economy (Y)</span></span>
              
              <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Airline:</span> <span style={{color:"#6e6464"}}>Pakistan Airlines</span></span>

              <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Stop:</span> <span style={{color:"#6e6464"}}>0</span></span>

              <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Baggage Allowance:</span> <span style={{color:"#6e6464"}}>{data ? data.flightSegments[1].Beggage:""}</span></span>

              </div>
          </div> </>:"" }
          
           
      </div>
          )}
        </div>
        </section>
        <Footer></Footer>
        </>
    )
}

const PaymentForm = ({ paymentDetail }) => {
  return (
      <form id="FormPG" action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction" method="POST">
          {Object.keys(paymentDetail).map((key) => (
              <input
                  key={key}
                  type="hidden"
                  id={key}
                  name={key}
                  value={paymentDetail[key]}
              />
          ))}
      </form>
  );
};
export default ThankYouGall;