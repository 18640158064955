import React from "react";
import { TextField } from "@mui/material";
import $ from "jquery";
import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { DatePicker } from "react-rainbow-components";
import { Radio, Space, Select } from "antd";
import axios from "axios";
import { format, addDays } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Autosuggest from 'react-autosuggest';
import AutocompleteNew from "../SearchResult/AutocompleteNew";
import AutocompleteNew2 from "../SearchResult/AutocompleteNew2";

import qs from 'qs';
var SearchType = 1;
function SearchBar({ SearchBarValues }) {
  let depureCity = "";
  let arrCity = "";
  let start = new Date();
  let end = new Date();
  let adul = 1;
  let chil = 0;
  let infan = 0;


  if (SearchBarValues !== undefined) {
    const SDate = new Date(SearchBarValues.StartDate);
    const EDate = new Date(SearchBarValues.EndDate);
    SearchType = SearchBarValues.FlightType;
    depureCity = SearchBarValues.departureCity;
    arrCity = SearchBarValues.arrivalCity;
    start = SearchBarValues.StartDate;
    end = SearchBarValues.EndDate;
    SDate.setDate(SDate.getDate() - 1);
    EDate.setDate(EDate.getDate() - 1);
    start = SDate;
    end = EDate;
    adul = SearchBarValues.Adults;
    chil = SearchBarValues.Children;
    infan = SearchBarValues.Infants;


  }

  useEffect(() => {
    // Call the function automatically whenever flightType changes to 2
    if (SearchType === 2) {
      ShowEndDateDiv(2);
    }
  }, [SearchType]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [States, setStates] = useState([]);
  
  const [departurecityempty, setDepartureCityEmpty] = useState(false);
  const [arrivalcityempty, setArrivalCityEmpty] = useState(false);
  const [Classvalue, setRadioValue] = useState("Economy");
  const [DepartureCity, setDepartureCity] = useState(depureCity);
  const [ArrivalCity, setArrivalCity] = useState(arrCity);
  const [SearchTypeValue, setSearchTypeValue] = useState(1);


  console.log('search type',SearchTypeValue)
  const [allflight, setAllFlight] = useState([]);

  const [Startdate, setStartDate] = useState(
    format(addDays(new Date(start), 1), "yyyy/MM/dd")
  );
  const [EndDate, setEndDate] = useState(
    format(addDays(new Date(end), 1), "yyyy/MM/dd")
  );

  const CheckOpen = (e) => {
    setOpen(true);

    const url = apiUrl + "/api/FlightSearch/CountryAll";
    // console.log(url);
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        setLoading(false);
      })
      .catch((error) => console.error("Error: ${error}"));
  };
  //Setting value of Home Tabs
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  function ClosePopUp() {
    const el = document.getElementById("MobileDatePickerOverlay");
    el.classList.remove("mlk-drawer-open");
  }


  const [economytoggle, seteconomyToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 700);
  const [adultscount, setadultsCount] = useState(adul);
  const [childrencount, setchildrenCount] = useState(chil);
  const [infantscount, setinfantsCount] = useState(infan);


console.log('adult',adultscount,'chil',childrencount,'infant',infantscount)






  const [inputValues, setInputValues] = useState({
    fromM_1: null,
    fromM_2: null,
    fromM_3: null,
    fromM_4: null,
    fromM_5: null,
    fromM_6: null,
    toM_1: null,
    toM_2: null,
    toM_3: null,
    toM_4: null,
    toM_5: null,
    toM_6: null,
    start: [],
    adults: '',
    children: '',
    infants: '',
    service: 'Y',
    type: 'Other',
    airline: null,
    flexdate: 0,
    directconecting: 2,
    multipleshow: 0,
    to: 0,
    end: 0,
    from: 0,
  });
  console.log('input value',inputValues)

// Update inputValues when adultscount changes
useEffect(() => {
  setInputValues((prevValues) => ({
    ...prevValues,
    adults: adultscount,
  }));
}, [adultscount]);

// Update inputValues when childrencount changes
useEffect(() => {
  setInputValues((prevValues) => ({
    ...prevValues,
    children: childrencount,
  }));
}, [childrencount]);

// Update inputValues when infantscount changes
useEffect(() => {
  setInputValues((prevValues) => ({
    ...prevValues,
    infants: infantscount,
  }));
}, [infantscount]);

  const [suggestions, setSuggestions] = useState({});
  const SetStarttDate = (date) => {
    setStartDate(format(date, "yyyy/MM/dd"));
  };
  const SetEnddDate = (date) => {
    setEndDate(format(date, "yyyy/MM/dd"));
  };





  const ShowMultiWays = (type) => {
    setSearchTypeValue(type)
    SearchType = type;
    const el = document.getElementById("SinlgeTwoWayDiv");
    el.style.display = "none";

    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "block";

    const double = document.getElementById("TwoWaySelectionTop");
    const single = document.getElementById("OneWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    single.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    double.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
  };
  const ShowStartDate = (type) => {
    setSearchTypeValue(type)

    SearchType = type;
    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "none";

    const elb = document.getElementById("SinlgeTwoWayDiv");
    elb.style.display = "block";

    const el = document.getElementById("StartDateFirstDIV");
    el.style.display = "none";
    const double = document.getElementById("TwoWaySelectionTop");
    const single = document.getElementById("OneWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    double.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    single.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
  };
  const ShowEndDateDiv = (type) => {
    setSearchTypeValue(type)

    SearchType = type;
    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "none";

    const elb = document.getElementById("SinlgeTwoWayDiv");
    elb.style.display = "block";

    const el = document.getElementById("StartDateFirstDIV");
    const single = document.getElementById("OneWaySelectionTop");
    const double = document.getElementById("TwoWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    single.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    double.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
    el.style.display = "block";
  };



  const onClassChange = (e) => {
    setRadioValue(e.target.value);
  };
  // Function to increment count by 1
  const DecrementAdultsCount = () => {
    // Update state with incremented value
    if (adultscount < 2) {
      setadultsCount(1);
    } else {
      setadultsCount(adultscount - 1);
    }
  };
  const DecrementChildrenCount = () => {
    // Update state with incremented value
    if (childrencount < 1) {
      setchildrenCount(0);
    } else {
      setchildrenCount(childrencount - 1);
    }
  };
  const DecrementInfantsCount = () => {
    // Update state with incremented value
    if (infantscount < 1) {
      setinfantsCount(0);
    } else {
      setinfantsCount(infantscount - 1);
    }
  };
  const IncrementAdultsCount = () => {
    // Update state with incremented value
    setadultsCount(adultscount + 1);
  };
  const IncrementChildrenCount = () => {
    // Update state with incremented value
    setchildrenCount(childrencount + 1);
  };
  const IncrementInfantsCount = () => {
    // Update state with incremented value
    setinfantsCount(infantscount + 1);
  };



  const handleAutocompleteChange1 = (value) => {
    setDepartureCityEmpty(false);
    setDepartureCity(value);
  };

  const handleAutocompleteChange2 = (value) => {
    setArrivalCityEmpty(false);
    setArrivalCity(value);
  };


  const fetchSuggestions = async ({ value }, section, setInputValues, setSuggestions, setLoading, apiUrl) => {
    setInputValues(prev => ({ ...prev, [section]: value }));
    if (value.length < 3) {
      setSuggestions(prev => ({
        ...prev,
        [section]: []
      }));
      return;
    }

    setLoading(true);
    const url = `${apiUrl}/api/FlightSearch/AirportName?term=${encodeURIComponent(value)}`;
    console.log('Fetching data from URL:', url);

    try {
      const response = await axios.get(url);
      const data = response.data;
      console.log('Response data:', data);
      setSuggestions(prev => ({
        ...prev,
        [section]: data
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestionsFetchRequested = ({ value }, { section }, setInputValues, setSuggestions, setLoading, apiUrl) => {
    fetchSuggestions({ value }, section, setInputValues, setSuggestions, setLoading, apiUrl);
  };

  const handleSuggestionsClearRequested = (section, setSuggestions) => {
    setSuggestions(prev => ({ ...prev, [section]: [] }));
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion}
    </div>
  );

  const renderInputComponent = (inputProps) => (
    <div className="inputContainer">
      <input {...inputProps} />
    </div>
  );




  const [sections, setSections] = useState([1, 2]);

  const handleAddDestination = () => {
    if (sections.length < 6) {
      setSections([...sections, sections.length + 1]);
    }
  };

  const handleRemoveDestination = (sectionToRemove) => {
    setSections(sections.filter(section => section !== sectionToRemove));
  };

  const handleInputChange = (section, field, value) => {
    setInputValues(prev => {
        const newInputValues = { ...prev };

        // Only update the state for fields that are part of fromM_ or toM_
        if (field === 'fromM_' || field === 'toM_') {
            newInputValues[`${field}${section}`] = value;

            // If updating toM_, propagate the value to the next fromM_
            if (field === 'toM_' && section < 6) {
                newInputValues[`fromM_${section + 1}`] = value;
            }
        }

        // Remove the unnecessary fields like "1": "dxb" if they exist
        delete newInputValues[section];

        return newInputValues;
    });
};
const handleDateChange = (id, selectedDate) => {
  let year = selectedDate.getFullYear();
  let day = selectedDate.getDate();
  let month = selectedDate.getMonth() + 1; // Adding 1 to adjust month to 1-based index
  let formattedDay = day < 10 ? `0${day}` : day;
  let formattedMonth = month < 10 ? `0${month}` : month;
  let formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  setInputValues(prevFormValues => ({
      ...prevFormValues,
      start: [...prevFormValues.start.slice(0, id - 1), formattedDate, ...prevFormValues.start.slice(id)],
  }));
};

const replaceNullWithZero = (obj) => {
  const newObj = { ...obj };
  for (let key in newObj) {
    if (newObj[key] === null) {
      newObj[key] = 0;
    }
  }
  console.log('Processed Form Values after replaceNullWithZero:', newObj);
  return newObj;
};

function MultiCityRequest() {
    navigate("/MultiCity", { state: { props: inputValues } });
  }





  function SendRequest() {

    if (DepartureCity == "" && ArrivalCity == "") {
      setDepartureCityEmpty(true);
      setArrivalCityEmpty(true);
    }
    else if (DepartureCity == "") {
      setDepartureCityEmpty(true);
    }
    else if (ArrivalCity == "") {
      setArrivalCityEmpty(true);
    }
    else {
      var data = {
        FlightClass: Classvalue,
        FlightType: SearchTypeValue,
        departureCity: DepartureCity,
        arrivalCity: ArrivalCity,
        StartDate: Startdate,
        EndDate: EndDate,
        Adults: adultscount,
        Children: childrencount,
        Infants: infantscount,
      };

      navigate("/SearchResult", { state: { props: data } });

    }
  }

const dateFormat = (date) => {
  if (!date) return '';
  const formattedDate = new Date(date.split('-').reverse().join('-'));
  return `${('0' + (formattedDate.getMonth() + 1)).slice(-2)}/${('0' + formattedDate.getDate()).slice(-2)}/${formattedDate.getFullYear()}`;
};

  return (
    <>
      <div className="" style={{ position: "relative" }}>
        <div className="">
          <div
            role="tabpanel"
            tabIndex="0"
            aria-hidden="false"
            className="CutomTab tab-pane active mlk-tabs-tabpane mlk-tabs-tabpane-active"
            id="FlightsTab"
            aria-labelledby="rc-tabs-0-tab-Flights"
          >
            <input
              id="hfWay"
              name="hfWay"
              type="hidden"
              defaultValue="1"
            />
            <div
              className="ml-2 mr-2 mlk-row _3gePwZ9SAAGVfaKFiLvuNP"
              style={{
                marginLeft: "-14px",
                marginRight: "-14px",
                rowGap: "22px",
              }}
            >
              <div className="mlk-col mlk-col-24 pl-xs-0 pr-xs-0 pr-md-4 pl-md-4">
                <div
                  className="mlk-row mlk-row-space-between mlk-row-middle"
                  style={{ rowGap: "0px" }}
                >
                  <div
                    className="mlk-row"
                    style={{ rowGap: "0px" }}
                  >
                    <ul
                      id="LeftTopButtons"
                      className="mlk-col mlk-col-24"
                    >
                      <li
                        id="OneWaySelectionTop"
                        onClick={() => ShowStartDate(1)}
                        className="mlk-tag shape-rounded _3Y80XhXSGl4xBZsWokJgo _3Flxco_ApdE_UsZi6Cts3l"
                      >
                        <a
                          id="OneWaySelection"
                          className="mlk-typography font-secondary"
                          defaultValue="1"
                        >
                          One Way
                        </a>
                      </li>
                      <li
                        id="TwoWaySelectionTop"
                        onClick={() => ShowEndDateDiv(2)}
                        className="mlk-tag shape-rounded _3Y80XhXSGl4xBZsWokJgo false"
                      >
                        <a
                          id="TwoWaySelection"
                          className="mlk-typography font-secondary"
                          defaultValue="2"
                        >
                          Return
                        </a>
                      </li>
                      <li
                        id="MultiWaySelectionTop"
                        onClick={() => ShowMultiWays(3)}
                        className="mlk-tag shape-rounded _3Y80XhXSGl4xBZsWokJgo false"
                      >
                        <a
                          id="MultiWaySelection"
                          className="mlk-typography font-secondary"
                          defaultValue="2"
                        >
                          Multi City
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div
                      className="mlk-row"
                      style={{ rowGap: "0px" }}
                    >
                      <span>
                        <button
                          type="submit"
                          onClick={() =>
                            setToggle(!toggle)
                          }
                          className="mlk-btn mlk-btn-text -t3ubWYd7XM_bHfcLUu3Z pr-1 d-none d-lg-block"
                        >
                          <div
                            className="mlk-row mlk-row-center mlk-row-middle"
                            style={{ rowGap: "0px" }}
                          >
                            <span
                              className="mlk-typography mr-1"
                              name="AdultdefaultValueUpper"
                              id="AdultdefaultValueUpper"
                              defaultValue="1"
                            >
                              Travelers
                            </span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                            >
                              <path
                                d="M3.48042 18.1237C2.81329 17.4643 2.89511 16.8589 3.39441 16.235C4.04896 15.4092 4.69302 14.5685 5.35386 13.7446C6.07974 12.8367 6.56226 12.7619 7.58814 13.371C8.66227 14.0062 9.16367 13.9483 9.51402 12.7825C9.87906 11.5719 10.7728 10.7069 11.4441 9.68871C11.8406 9.0778 12.2833 8.49678 12.7364 7.91016C13.3973 7.03583 14.3329 6.80043 15.4322 7.21705C16.37 7.57388 16.8127 8.35293 16.6197 9.33561C16.5427 9.91451 16.4157 10.4873 16.2399 11.0488C15.7029 12.4499 16.0071 13.7147 16.7413 15.0019C17.4336 16.1976 17.9518 17.4717 18.5707 18.7029C18.8099 19.1812 18.8728 19.6202 18.2854 19.8911C17.698 20.162 17.3371 19.8163 17.056 19.3773C15.8476 17.4829 14.6266 15.5979 13.4224 13.7017C13.2014 13.371 13.0565 13.0045 12.996 12.6234C12.9355 12.2424 12.9606 11.8545 13.07 11.4822C13.2168 10.9666 13.2798 10.4323 13.3994 9.77651C13.2723 9.85572 13.1542 9.94582 13.0469 10.0455C11.2972 12.4275 9.02731 14.4695 7.38254 16.9225C7.07834 17.3764 6.8245 17.453 6.25806 17.212C5.24477 16.7824 4.53567 17.0551 3.48042 18.1237Z"
                                fill="#060606"
                              ></path>
                              <path
                                d="M18.3315 4.87055C18.3274 5.11245 18.2698 5.35126 18.162 5.57334C18.0543 5.79543 17.8984 5.99643 17.7034 6.16488C17.5084 6.33333 17.2781 6.46593 17.0255 6.5551C16.773 6.64427 16.5032 6.68827 16.2315 6.68459C15.9599 6.68091 15.6917 6.62962 15.4423 6.53365C15.1929 6.43768 14.9672 6.2989 14.7781 6.12525C14.5889 5.95159 14.44 5.74646 14.3399 5.52156C14.2397 5.29667 14.1903 5.05641 14.1945 4.8145C14.1986 4.5726 14.2562 4.33379 14.364 4.11171C14.4717 3.88962 14.6276 3.68862 14.8226 3.52017C15.0176 3.35172 15.2479 3.21912 15.5005 3.12995C15.753 3.04078 16.0228 2.99678 16.2945 3.00046C16.5661 3.00414 16.8343 3.05543 17.0837 3.1514C17.3331 3.24737 17.5588 3.38615 17.7479 3.5598C17.9371 3.73346 18.086 3.93859 18.1861 4.16349C18.2863 4.38838 18.3357 4.62864 18.3315 4.87055Z"
                                fill="#060606"
                              ></path>
                              <path
                                d="M12.7761 13.7316C14.2656 15.8128 14.2656 15.8595 12.6985 17.709C12.1614 18.3442 11.6265 18.9831 11.081 19.6146C10.8062 19.9359 10.4516 20.1284 10.0153 19.8762C9.57891 19.6239 9.52646 19.2428 9.82227 18.843C9.95234 18.6562 10.0803 18.4825 10.2125 18.3031C10.737 17.5857 11.2614 16.8646 11.7922 16.1509C12.323 15.4373 12.323 14.5853 12.7761 13.7316Z"
                                fill="#0A0A0A"
                              ></path>
                              <path
                                d="M17.0351 9.8064C17.4546 10.8339 18.577 11.301 19.4036 12.0016C19.7707 12.3154 20.3078 12.6031 19.7854 13.1225C19.3218 13.5858 18.9232 13.2196 18.5435 12.9581C18.3901 12.8352 18.2283 12.721 18.0588 12.6162C16.8001 11.9698 16.1917 11.116 17.0351 9.8064Z"
                                fill="#0A0A0A"
                              ></path>
                              <path
                                d="M5.51741 20.0032C4.73489 19.9079 4.24607 19.566 4.25866 18.8822C4.27125 18.1985 4.72859 17.7931 5.49013 17.7949C6.25168 17.7968 6.71112 18.2115 6.71322 18.8897C6.71532 19.5679 6.20552 19.9004 5.51741 20.0032Z"
                                fill="#0A0A0A"
                              ></path>
                            </svg>
                            <span
                              role="img"
                              aria-label="down"
                              type="primary"
                              className="anticon anticon-down IRZgLzifnXqccNR-nMyWB"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="down"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                              </svg>
                            </span>
                          </div>
                        </button>
                      </span>
                      <span>
                        <button
                          type="submit"
                          onClick={() =>
                            seteconomyToggle(
                              !economytoggle
                            )
                          }
                          className="mlk-btn mlk-btn-text _3NtRGWvN2_wNBJ9MnHqtZB pr-0"
                        >
                          <div
                            className="mlk-row mlk-row-center mlk-row-middle"
                            style={{ rowGap: "0px" }}
                          >
                            {isDesktop ? (
                              <span
                                className="mlk-typography"
                                id="FlightTypeDiv"
                                defaultValue=""
                              >
                                Flight Class
                              </span>
                            ) : (
                              <span
                                className="mlk-typography"
                                id="FlightTypeDiv"
                                defaultValue=""
                              >
                                Travelers
                              </span>
                            )}
                            <span
                              role="img"
                              aria-label="down"
                              type="primary"
                              className="anticon anticon-down _1fwy19bCu_Y1nOBROCXaW2"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="down"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                              </svg>
                            </span>
                          </div>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mlk-col mlk-col-24"
                id="SinlgeTwoWayDiv"
              >
                <div
                  className="mlk-col mlk-col-24"
                  style={{
                    paddingLeft: "14px",
                    paddingRight: "14px",
                  }}
                >
                  <div
                    className="mlk-row"
                    style={{
                      marginLeft: "-17.5px",
                      marginRight: "-17.5px",
                      rowGap: "0px",
                    }}
                  >
                    <div
                      className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mb-3"
                      style={{
                        paddingLeft: "17.5px",
                        paddingRight: "17.5px",
                      }}
                    >
                      <div>
                        <div className="st-airport-selector">
                          <svg
                            width="15"
                            height="21"
                            viewBox="0 0 15 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                          >
                            <path
                              d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                              fill="black"
                            ></path>
                          </svg>
                          <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                            <div className="mlk-select-selector">
                              <span className="mlk-select-selection-search">
                                {/*  <Autocomplete
                                                                                                                id="FirstCity"
                                                                                                                loading={loading}
                                                                                                                onKeyUp={onKeyUp}
                                                                                                                options={States.map((option) => option)}
                                                                                                                value={DepartureCity}
                                                                                                                onChange={(event, newValue) => {
                                                                                                                    setDepartureCity(newValue);
                                                                                                                }}
                                                                                                                renderInput={(params) => <TextField
                                                                                                                    {...params}
                                                                                                                    variant='standard'
                                                                                                                    placeholder='Flying From(City or Airport)'
                                                                                                                    InputProps={{
                                                                                                                        ...params.InputProps,
                                                                                                                        endAdornment: (
                                                                                                                            <>
                                                                                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                                                                {params.InputProps.endAdornment}
                                                                                                                            </>
                                                                                                                        ),
                                                                                                                    }}
                                                                                                                />}
                                                                                                            /> */}
                                <AutocompleteNew
                                  placeholder='Flying From(City or Airport)'
                                  onChange={handleAutocompleteChange1}
                                  departureCity={DepartureCity}
                                />
                                <div>
                                  <span style={{ color: "red", fontSize: "14px" }}>
                                    {
                                      departurecityempty ? "Please select your origin" : <></>
                                    }
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mb-3"
                      style={{
                        paddingLeft: "17.5px",
                        paddingRight: "17.5px",
                      }}
                    >
                      <button
                        type="submit"
                        className="mlk-btn mlk-btn-circle mlk-btn-lg FRNR-7sLQZrTZz49Vnm2h iYqbmkyJz0qYR6CPTqY7p"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M3.72927 8.63954C3.96312 8.87377 4.34449 8.87377 4.57835 8.63954L5.35498 7.8629C5.58921 7.62905 5.58921 7.24768 5.35498 7.01383L4.57835 6.23719H15.2646C16.2582 6.23719 17.0664 7.04536 17.0664 8.03895C17.0664 8.53556 17.4707 8.93983 17.9673 8.93983H18.5679C19.0645 8.93983 19.4688 8.53556 19.4688 8.03895C19.4688 5.72069 17.5829 3.83485 15.2646 3.83485H4.57835L5.35498 3.05822C5.58921 2.82436 5.58921 2.44299 5.35498 2.20914L4.57835 1.43251C4.34449 1.19828 3.96312 1.19828 3.72927 1.43251L0.337836 4.82356C0.220721 4.94105 0.220721 5.13099 0.337836 5.24848L3.72927 8.63954Z"
                              fill="#001D8D"
                            ></path>
                            <path
                              d="M15.9895 11.6421C15.7556 11.4079 15.3746 11.4079 15.1404 11.6421L14.3638 12.4187C14.1291 12.6534 14.1287 13.0328 14.3638 13.2678L15.1404 14.0444H4.4541C3.46051 14.0444 2.65234 13.2363 2.65234 12.2427C2.65234 11.7461 2.24807 11.3418 1.75146 11.3418H1.15088C0.654269 11.3418 0.25 11.7461 0.25 12.2427C0.25 14.5609 2.13584 16.4468 4.4541 16.4468H15.1404L14.3638 17.2234C14.1291 17.4581 14.1287 17.8374 14.3638 18.0725L15.1404 18.8491C15.3747 19.0834 15.7553 19.0834 15.9895 18.8491L19.3809 15.4581C19.498 15.3406 19.498 15.1506 19.3809 15.0332L15.9895 11.6421Z"
                              fill="#e5b815"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect
                                width="19.2188"
                                height="19.2188"
                                fill="white"
                                transform="translate(0.25 0.53125)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <div id="FlyingTo">
                        <div className="st-airport-selector">
                          <svg
                            width="15"
                            height="21"
                            viewBox="0 0 15 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                          >
                            <path
                              d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                              fill="black"
                            ></path>
                          </svg>
                          <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                            <div className="mlk-select-selector">
                              <span className="mlk-select-selection-search">
                                {/*         <Autocomplete
                                                                                                                id="SecondCity"
                                                                                                                onKeyUp={onKeyUp}
                                                                                                                options={States.map((option) => option)}
                                                                                                                value={ArrivalCity}
                                                                                                                placeholder="Flying To..."
                                                                                                                onChange={(event, newValue) => {
                                                                                                                    setArrivalCity(newValue);
                                                                                                                }}
                                                                                                                renderInput={(params) => <TextField
                                                                                                                    {...params}
                                                                                                                    variant='standard'
                                                                                                                    placeholder='Flying To(City or Airport)'
                                                                                                                    InputProps={{
                                                                                                                        ...params.InputProps,
                                                                                                                        endAdornment: (
                                                                                                                            <>
                                                                                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                                                                {params.InputProps.endAdornment}
                                                                                                                            </>
                                                                                                                        ),
                                                                                                                    }}
                                                                                                                />}
                                                                                                            /> */}

                                <AutocompleteNew2
                                  onChange={handleAutocompleteChange2}
                                  arrivalCity={ArrivalCity}
                                />
                                <span style={{ color: "red", fontSize: "14px" }}>
                                  {
                                    arrivalcityempty ? "Please select your destination" : <></>
                                  }
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-12"
                      style={{ paddingLeft: "17.5px" }}
                    >
                      <div
                        className="mlk-rowes"
                        style={{
                          marginLeft: "-17.5px",
                          marginRight: "-17.5px",
                          rowGap: "0px",
                        }}
                      >
                        <div
                          className="mlk-col mlk-col-12"
                          style={{
                            paddingLeft: "17.5px",
                          }}
                        >
                          <div
                            className="mlk-row"
                            style={{ rowGap: "0px" }}
                          >
                            <div className="mlk-picker mlk-picker-large _1epwiHF8YSic2nULq7H-mU">
                              <div className="mlk-picker-input">
                                <DatePicker
                                  className="Datepickerwidth"
                                  placeholder="Start Date"
                                  minDate={new Date()}
                                  name="StartDatePicker"
                                  value={Startdate}
                                  onChange={SetStarttDate}
                                />
                              </div>
                            </div>
                            <div className="_6TtnsbW1P0yhuNCg-SDby">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                              >
                                <g>
                                  <path
                                    d="M22.9605 7.71829C20.9272 6.96759 18.7425 6.85387 16.6423 7.38928L13.9132 8.08504L6.1809 3.65203C6.022 3.56095 5.83398 3.53531 5.65637 3.58055L2.40855 4.40855C2.14614 4.47548 1.94533 4.6868 1.89185 4.9523C1.83841 5.21775 1.94182 5.49033 2.15796 5.65354L7.5317 9.71202L5.31892 10.2762L2.27041 8.4688C1.94852 8.27798 1.53349 8.3721 1.32541 8.68326L0.118991 10.4876C-0.0639617 10.7612 -0.0330711 11.125 0.193335 11.3639L3.84086 15.2123C3.97562 15.3545 4.16134 15.4323 4.35226 15.4323C4.41025 15.4323 4.46865 15.4251 4.52631 15.4104L22.8026 10.7511C23.4917 10.5754 23.9608 10.003 23.9978 9.29282C24.0348 8.58262 23.6276 7.96462 22.9605 7.71829Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M12.1368 19.0327H0.704577C0.315468 19.0327 0 19.3482 0 19.7373C0 20.1264 0.315468 20.4419 0.704577 20.4419H12.1367C12.5258 20.4419 12.8413 20.1264 12.8413 19.7373C12.8413 19.3482 12.5259 19.0327 12.1368 19.0327Z"
                                    fill="black"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath>
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="white"
                                      transform="translate(0 0.000244141)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div
                          className="mlk-col mlk-col-12"
                          id="StartDateFirstDIV"
                          style={{
                            display: "none",
                            paddingLeft: "17.5px",
                          }}
                        >
                          <div
                            id="ReturningDateDiv"
                            className="mlk-row _27S74ezjL-fTtOR75RS_5E disabled"
                            style={{ rowGap: "0px" }}
                          >
                            <div className="mlk-picker mlk-picker-large _1epwiHF8YSic2nULq7H-mU ">
                              <div className="mlk-picker-input">
                                <DatePicker
                                  className="Datepickerwidth"
                                  placeholder="End Date"
                                  minDate={
                                    new Date(Startdate)
                                  }
                                  name="StartDatePicker"
                                  value={EndDate}
                                  onChange={SetEnddDate}
                                />
                              </div>
                            </div>
                            <div className="_6TtnsbW1P0yhuNCg-SDby">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                              >
                                <g>
                                  <path
                                    d="M21.7286 14.4266C20.283 12.8357 18.4126 11.7359 16.3197 11.246L13.5999 10.6093L8.82466 3.17035C8.72655 3.0175 8.5727 2.90884 8.39585 2.86745L5.15909 2.10981C4.89762 2.04868 4.62415 2.14272 4.45564 2.35182C4.28712 2.56093 4.25342 2.84814 4.36887 3.09062L7.24019 9.12075L5.03501 8.60456L3.17956 5.61929C2.98358 5.30396 2.57525 5.19704 2.24989 5.37554L0.362706 6.41124C0.0765342 6.56827 -0.062778 6.90249 0.0270812 7.21632L1.47561 12.2718C1.54541 12.5153 1.74148 12.7019 1.98809 12.7596L20.2018 17.023C20.3279 17.0525 20.4536 17.0668 20.5769 17.0668C21.1256 17.0668 21.629 16.7831 21.9208 16.2867C22.2784 15.6787 22.2029 14.9486 21.7286 14.4266Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M23.3012 20.5115H11.9629C11.577 20.5115 11.2642 20.8244 11.2642 21.2102C11.2642 21.5961 11.577 21.909 11.9629 21.909H23.3012C23.6871 21.909 24 21.5962 24 21.2102C24 20.8243 23.6871 20.5115 23.3012 20.5115Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M8.80641 15.0566C8.44088 15.0566 8.09072 15.378 8.10765 15.7554C8.12461 16.134 8.41468 16.4542 8.80641 16.4542C9.17194 16.4542 9.5221 16.1328 9.50518 15.7554C9.48826 15.3768 9.19819 15.0566 8.80641 15.0566Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M11.2289 15.5757C10.8634 15.5757 10.5132 15.8971 10.5301 16.2744C10.5471 16.6531 10.8372 16.9732 11.2289 16.9732C11.5945 16.9732 11.9446 16.6518 11.9277 16.2744C11.9107 15.8959 11.6206 15.5757 11.2289 15.5757Z"
                                    fill="black"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath>
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="white"
                                      transform="translate(0 0.000244141)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mlk-col mlk-col-24 text-center md:pt-2 lg:pt-2 xl:pt-2 xxl:pt-2"
                  style={{
                    paddingLeft: "14px",
                    paddingRight: "14px",
                  }}
                >
                  <div
                    className="mlk-row mlk-row-center mlk-row-middle"
                    style={{
                      rowGap: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    <button
                      onClick={SendRequest}
                      type="submit"
                      className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base"
                    >
                      <span
                        role="img"
                        aria-label="search"
                        className="anticon anticon-search"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="search"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                        </svg>
                      </span>
                      <span>Search</span>
                    </button>
                    <button
                      type="submit"
                      className="mlk-btn mlk-btn-link _2lOe1Wj4kELEfYdDMvvSNJ"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                      >
                        <path
                          d="M20.5355 12.8121H18.6794C18.8686 13.3301 18.972 13.8891 18.972 14.4717V21.4866C18.972 21.7295 18.9297 21.9626 18.8528 22.1795H21.9213C23.0675 22.1795 24 21.2469 24 20.1007V16.2766C24 14.3663 22.4459 12.8121 20.5355 12.8121Z"
                          fill="black"
                        ></path>
                        <path
                          d="M5.02806 14.4717C5.02806 13.889 5.13142 13.3301 5.3206 12.8121H3.46454C1.55419 12.8121 0 14.3663 0 16.2767V20.1008C0 21.247 0.932486 22.1795 2.07872 22.1795H5.14726C5.07034 21.9626 5.02806 21.7295 5.02806 21.4866V14.4717Z"
                          fill="black"
                        ></path>
                        <path
                          d="M14.1216 11.0073H9.87841C7.96806 11.0073 6.41387 12.5615 6.41387 14.4719V21.4867C6.41387 21.8694 6.72409 22.1796 7.10678 22.1796H16.8933C17.276 22.1796 17.5862 21.8694 17.5862 21.4867V14.4719C17.5862 12.5615 16.032 11.0073 14.1216 11.0073Z"
                          fill="black"
                        ></path>
                        <path
                          d="M12 1.82092C9.70258 1.82092 7.83348 3.69002 7.83348 5.98751C7.83348 7.54587 8.69359 8.90689 9.96381 9.62145C10.5663 9.96036 11.2609 10.154 12 10.154C12.7391 10.154 13.4337 9.96036 14.0362 9.62145C15.3065 8.90689 16.1666 7.54582 16.1666 5.98751C16.1666 3.69007 14.2975 1.82092 12 1.82092Z"
                          fill="black"
                        ></path>
                        <path
                          d="M4.68366 5.70447C2.96546 5.70447 1.56764 7.10228 1.56764 8.82049C1.56764 10.5387 2.96546 11.9365 4.68366 11.9365C5.1195 11.9365 5.53454 11.8462 5.91155 11.6839C6.5634 11.4033 7.10087 10.9065 7.43344 10.2843C7.66688 9.84752 7.79968 9.34929 7.79968 8.82049C7.79968 7.10233 6.40186 5.70447 4.68366 5.70447Z"
                          fill="black"
                        ></path>
                        <path
                          d="M19.3164 5.70447C17.5982 5.70447 16.2004 7.10228 16.2004 8.82049C16.2004 9.34933 16.3332 9.84757 16.5666 10.2843C16.8992 10.9066 17.4366 11.4034 18.0885 11.6839C18.4655 11.8462 18.8805 11.9365 19.3164 11.9365C21.0346 11.9365 22.4324 10.5387 22.4324 8.82049C22.4324 7.10228 21.0346 5.70447 19.3164 5.70447Z"
                          fill="black"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>


              <div
                className="MultiWays mlk-col mlk-col-24"
                id="MultiWaySelectionDiv"
                style={{ display: "none" }}
              >


                <div className="">
                  {sections.map((section, index) => (
                    <div key={section} className="mlk-col mlk-col-24" style={{ paddingLeft: "14px", paddingRight: "14px" }}>
                      <div className="mlk-row" style={{ marginLeft: "-17px", marginRight: "-17.5", rowGap: "10px" }}>
                        <div className="col-sm-12 col-md-12 col-lg-4" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>
                          <div className="st-airport-selector">
                            <svg
                              width="15"
                              height="21"
                              viewBox="0 0 15 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                            >
                              <path
                                d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                fill="black"
                              ></path>
                            </svg>
                            <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                              <div className="mlk-select-selector">
                                <span className="mlk-select-selection-search">
                                  <Autosuggest
                                    suggestions={suggestions[section] || []}
                                    onSuggestionsFetchRequested={({ value }) => handleSuggestionsFetchRequested({ value }, { section }, setInputValues, setSuggestions, setLoading, apiUrl)}
                                    onSuggestionsClearRequested={() => handleSuggestionsClearRequested(section, setSuggestions)}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                      placeholder: "Flying From (City or Airport)",
                                      value: inputValues[`fromM_${section}`] || '',
                                      onChange: (_, { newValue }) => handleInputChange(section, 'fromM_', newValue)
                                    }}
                                    renderInputComponent={renderInputComponent}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>
                          <button
                            type="submit"
                            className="mlk-btn mlk-btn-circle mlk-btn-lg FRNR-7sLQZrTZz49Vnm2h iYqbmkyJz0qYR6CPTqY7p"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0)">
                                <path
                                  d="M3.72927 8.63954C3.96312 8.87377 4.34449 8.87377 4.57835 8.63954L5.35498 7.8629C5.58921 7.62905 5.58921 7.24768 5.35498 7.01383L4.57835 6.23719H15.2646C16.2582 6.23719 17.0664 7.04536 17.0664 8.03895C17.0664 8.53556 17.4707 8.93983 17.9673 8.93983H18.5679C19.0645 8.93983 19.4688 8.53556 19.4688 8.03895C19.4688 5.72069 17.5829 3.83485 15.2646 3.83485H4.57835L5.35498 3.05822C5.58921 2.82436 5.58921 2.44299 5.35498 2.20914L4.57835 1.43251C4.34449 1.19828 3.96312 1.19828 3.72927 1.43251L0.337836 4.82356C0.220721 4.94105 0.220721 5.13099 0.337836 5.24848L3.72927 8.63954Z"
                                  fill="#001D8D"
                                ></path>
                                <path
                                  d="M15.9895 11.6421C15.7556 11.4079 15.3746 11.4079 15.1404 11.6421L14.3638 12.4187C14.1291 12.6534 14.1287 13.0328 14.3638 13.2678L15.1404 14.0444H4.4541C3.46051 14.0444 2.65234 13.2363 2.65234 12.2427C2.65234 11.7461 2.24807 11.3418 1.75146 11.3418H1.15088C0.654269 11.3418 0.25 11.7461 0.25 12.2427C0.25 14.5609 2.13584 16.4468 4.4541 16.4468H15.1404L14.3638 17.2234C14.1291 17.4581 14.1287 17.8374 14.3638 18.0725L15.1404 18.8491C15.3747 19.0834 15.7553 19.0834 15.9895 18.8491L19.3809 15.4581C19.498 15.3406 19.498 15.1506 19.3809 15.0332L15.9895 11.6421Z"
                                  fill="#e5b815"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width="19.2188"
                                    height="19.2188"
                                    fill="white"
                                    transform="translate(0.25 0.53125)"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </button>

                          <div className="st-airport-selector">
                            <svg
                              width="15"
                              height="21"
                              viewBox="0 0 15 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                            >
                              <path
                                d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                fill="black"
                              ></path>
                            </svg>
                            <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                              <div className="mlk-select-selector">
                                <span className="mlk-select-selection-search">
                                  <Autosuggest
                                    suggestions={suggestions[section] || []}
                                    onSuggestionsFetchRequested={({ value }) => handleSuggestionsFetchRequested({ value }, { section }, setInputValues, setSuggestions, setLoading, apiUrl)}
                                    onSuggestionsClearRequested={() => handleSuggestionsClearRequested(section, setSuggestions)}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                      placeholder: "Flying To (City or Airport)",
                                      value: inputValues[`toM_${section}`] || '',
                                      onChange: (_, { newValue }) => handleInputChange(section, 'toM_', newValue)
                                    }}
                                    renderInputComponent={renderInputComponent}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>
                          <div className="mlk-row" style={{ marginLeft: "-17.5px", marginRight: "-17.5px", rowGap: "10px" }}>
                            <div className="" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>
                              <div className="mlk-row" style={{ rowGap: "0px" }}>
                                <div className="mlk-picker mlk-picker-large _1epwiHF8YSic2nULq7H-mU">
                                  <div className="mlk-picker-input">
                                  <DatePicker
    className="Datepickerwidth"
    placeholder="End Date"
    minDate={new Date()}
    name={`StartDatePicker_${section}`}
    selected={
        inputValues['start'][section - 1]
            ? new Date(inputValues['start'][section - 1].split('-').reverse().join('-'))
            : null
    }
    dateFormat="MM/dd/yyyy"
    value={dateFormat(inputValues['start'][section - 1])}
    onChange={(date) => handleDateChange(section, date)}
/>



                                  </div>
                                </div>
                                {index >= 2 && (
                                    <span
                                style={{ position: 'absolute', top: '5px', right: '10px', cursor: 'pointer', color: '#2a3b8f', fontSize: '20px', fontWeight: 'bold' }}
                                onClick={() => handleRemoveDestination(section)}
                            >
                                &#x2715; {/* Close icon (×) */}
                            </span>
                                 
                                 
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="">
                    <button  className="mlk-btn mlk-btn-link pl-3" id="AddDestinationButton" onClick={handleAddDestination}>
                      <span role="img" aria-label="plus" className="anticon anticon-plus">
                        <svg viewBox="64 64 896 896" style={{ verticalAlign: "baseline !important" }} focusable="false" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <defs></defs>
                          <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                          <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                        </svg>
                      </span>
                      <span>Add Destination</span>
                    </button>
                  </div>
                  <div className="mlk-col mlk-col-24 text-center md:pt-2 lg:pt-2 xl:pt-2 xxl:pt-2" style={{ marginLeft: "14px", marginRight: "14px" }}>
                    <div className="mlk-row mlk-row-center mlk-row-middle" style={{ rowGap: "10px", paddingTop: "10px" }}>
                      <button onClick={() => {MultiCityRequest()}} type="submit" className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base">
                        <span role="img" aria-label="search" className="anticon anticon-search">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true" />
                          <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                        </span>
                        <span>Search</span>
                      </button>
                      <button type="submit" className="mlk-btn mlk-btn-link _2lOe1Wj4kELEfYdDMvvSNJ">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="_1MZrIK28AE8HVj289UBgZw st-svg-icon">
                          <path
                            d="M20.5355 12.8121H18.6794C18.8686 13.3301 18.972 13.8891 18.972 14.4717V21.4866C18.972 21.7295 18.9297 21.9626 18.8528 22.1795H21.9213C23.0675 22.1795 24 21.2469 24 20.1007V16.2766C24 14.3663 22.4459 12.8121 20.5355 12.8121Z"
                            fill="black"
                          ></path>
                          <path
                            d="M5.02806 14.4717C5.02806 13.889 5.13142 13.3301 5.3206 12.8121H3.46454C1.55419 12.8121 0 14.3663 0 16.2767V20.1008C0 21.247 0.932486 22.1795 2.07872 22.1795H5.14726C5.07034 21.9626 5.02806 21.7295 5.02806 21.4866V14.4717Z"
                            fill="black"
                          ></path>
                          <path
                            d="M14.1216 11.0073H9.87841C7.96806 11.0073 6.41387 12.5615 6.41387 14.4719V21.4867C6.41387 21.8694 6.72409 22.1796 7.10678 22.1796H16.8933C17.276 22.1796 17.5862 21.8694 17.5862 21.4867V14.4719C17.5862 12.5615 16.032 11.0073 14.1216 11.0073Z"
                            fill="black"
                          ></path>
                          <path
                            d="M12 1.82092C9.70258 1.82092 7.83348 3.69002 7.83348 5.98751C7.83348 7.54587 8.69359 8.90689 9.96381 9.62145C10.5663 9.96036 11.2609 10.154 12 10.154C12.7391 10.154 13.4337 9.96036 14.0362 9.62145C15.3065 8.90689 16.1666 7.54582 16.1666 5.98751C16.1666 3.69007 14.2975 1.82092 12 1.82092Z"
                            fill="black"
                          ></path>
                          <path
                            d="M4.68366 5.70447C2.96546 5.70447 1.56764 7.10228 1.56764 8.82049C1.56764 10.5387 2.96546 11.9365 4.68366 11.9365C5.1195 11.9365 5.53454 11.8462 5.91155 11.6839C6.5634 11.4033 7.10087 10.9065 7.43344 10.2843C7.66688 9.84752 7.79968 9.34929 7.79968 8.82049C7.79968 7.10233 6.40186 5.70447 4.68366 5.70447Z"
                            fill="black"
                          ></path>
                          <path
                            d="M19.3164 5.70447C17.5982 5.70447 16.2004 7.10228 16.2004 8.82049C16.2004 9.34933 16.3332 9.84757 16.5666 10.2843C16.8992 10.9066 17.4366 11.4034 18.0885 11.6839C18.4655 11.8462 18.8805 11.9365 19.3164 11.9365C21.0346 11.9365 22.4324 10.5387 22.4324 8.82049C22.4324 7.10228 21.0346 5.70447 19.3164 5.70447Z"
                            fill="black"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>





              </div>
            </div>
          </div>
        </div>





        <div
          id="PassengersHotelPopUp"
          className="mlk-popover _2p3fHACPm_2k5ROOcRLV_s _3lm1kPEmbQZy6J1BHR0nJM mlk-popover-placement-bottomRight"
          style={{
            left: "786px",
            top: "155px",
            TransformOrigin: "354px -4px",
            display: "none",
          }}
        >
          <div className="mlk-popover-content">
            <div className="mlk-popover-arrow">
              <span className="mlk-popover-arrow-content"></span>
            </div>
            <div className="mlk-popover-inner" role="tooltip">
              <div className="mlk-popover-title">
                <div
                  className="mlk-row mlk-row-space-between _2cBW-pTJvDCb_SWQ6S6nqh"
                  style={{ rowGap: "0px" }}
                >
                  <div
                    className="mlk-col"
                    style={{ flex: "1 1 auto" }}
                  >
                    <span className="mlk-typography text-tiny font-normal">
                      Travelers
                    </span>
                  </div>
                  <div className="mlk-col">
                    <span
                      role="img"
                      aria-label="close"
                      tabIndex="-1"
                      className="anticon anticon-close"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mlk-popover-inner-content">
                <div
                  className="mlk-row mlk-row-space-between _17YY9XFm_-VCvme-Su4Nyo"
                  style={{ rowGap: "0px" }}
                >
                  <div className="mlk-col mlk-col-24 content">
                    <div className="_13osEtXv_ZRA69EM9rnOj4">
                      <div
                        className="mlk-row"
                        style={{
                          marginLeft: "-8px",
                          marginRight: "-8px",
                          rowGap: "16px",
                        }}
                      >
                        <div
                          className="mlk-col mlk-col-24"
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          }}
                        >
                          <div
                            className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                            style={{
                              marginleft: "-5px",
                              marginRight: "-5px",
                              rowGap: "0px",
                            }}
                          >
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                              >
                                <g clipPath="url(#clip0)">
                                  <path
                                    d="M11.9999 11.7378C14.6682 11.7378 16.8316 9.1103 16.8316 5.86907C16.8316 2.62776 16.1213 0.000244141 11.9999 0.000244141C7.87837 0.000244141 7.16797 2.62776 7.16797 5.86907C7.16797 9.1103 9.33133 11.7378 11.9999 11.7378Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M2.87375 20.6987C2.87293 20.5011 2.87211 20.6431 2.87375 20.6987V20.6987Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M21.1257 20.8531C21.1283 20.799 21.1266 20.4777 21.1257 20.8531V20.8531Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M21.1154 20.4617C21.0259 14.8154 20.2885 13.2065 14.6455 12.1881C14.6455 12.1881 13.8512 13.2003 11.9998 13.2003C10.1483 13.2003 9.35388 12.1881 9.35388 12.1881C3.77251 13.1954 2.99024 14.7805 2.88739 20.2783C2.87895 20.7273 2.87504 20.7508 2.87354 20.6987C2.87388 20.7964 2.87429 20.977 2.87429 21.2919C2.87429 21.2919 4.21774 24.0002 11.9998 24.0002C19.7817 24.0002 21.1253 21.2919 21.1253 21.2919C21.1253 21.0896 21.1254 20.9489 21.1256 20.8531C21.1241 20.8854 21.1211 20.8229 21.1154 20.4617Z"
                                    fill="black"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="white"
                                      transform="translate(0 0.000244141)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                flex: "1 1 auto",
                              }}
                            >
                              <div
                                className="mlk-row"
                                style={{ rowGap: "0px" }}
                              >
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography text-gray-9">
                                    Adults
                                  </span>
                                </div>
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                    12 years and above
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <div
                                className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                style={{ rowGap: "0px" }}
                              >
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="minus-circle"
                                    className="anticon anticon-minus-circle"
                                    style={{
                                      color: "rgb(191,191,191)",
                                    }}
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="minus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                    </svg>
                                  </span>
                                </button>
                                <span
                                  className="mlk-typography quantity text-base font-normal"
                                  id="AdultdefaultValue"
                                  defaultValue="1"
                                >
                                  1
                                </span>
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="plus-circle"
                                    className="anticon anticon-plus-circle"
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="plus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                        fill="#0a549c"
                                      ></path>
                                      <path
                                        d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                        fill="#c5d4db"
                                      ></path>
                                      <path
                                        d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                        fill="#0a549c"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="mlk-col mlk-col-24"
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          }}
                        >
                          <div
                            className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                            style={{
                              marginLeft: "-5px",
                              marginRight: "-5px",
                              rowGap: "0px",
                            }}
                          >
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                              >
                                <g clipPath="url(#clip0)">
                                  <path
                                    d="M10.2823 13.3081C10.2823 12.0306 9.24298 10.9912 7.96541 10.9912C6.68785 10.9912 5.64844 12.0306 5.64844 13.3081H7.05468C7.05468 12.806 7.46326 12.3975 7.96541 12.3975C8.46757 12.3975 8.8761 12.806 8.8761 13.3081H10.2823Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M16.0346 10.9912C14.757 10.9912 13.7177 12.0306 13.7177 13.3081H15.1239C15.1239 12.806 15.5324 12.3975 16.0346 12.3975C16.5367 12.3975 16.9453 12.806 16.9453 13.3081H18.3516C18.3516 12.0306 17.3122 10.9912 16.0346 10.9912Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M11.9999 17.4228C11.1109 17.4228 10.2772 16.946 9.82428 16.1785L8.61316 16.8931C9.3178 18.0872 10.6155 18.829 11.9999 18.829C13.3842 18.829 14.6819 18.0872 15.3866 16.8931L14.1755 16.1785C13.7226 16.946 12.8889 17.4228 11.9999 17.4228Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M23.085 10.8175C22.6372 10.3702 22.0748 10.0744 21.4644 9.95861C20.1461 5.88483 16.3219 3.07997 12 3.07997H11.5286C10.3965 3.07997 9.47546 2.1589 9.47546 1.02686H8.06922C8.06922 1.79505 8.32122 2.50529 8.74644 3.07997H8.30092C7.16883 3.07997 6.24781 2.1589 6.24781 1.02686H4.84156C4.84156 2.55476 5.83757 3.85358 7.21408 4.31034C5.03702 5.50723 3.33063 7.502 2.53568 9.95861C1.92524 10.0744 1.3627 10.3703 0.914477 10.8181C0.324763 11.4084 0 12.1928 0 13.0269C0 13.8506 0.31779 14.6279 0.894888 15.2154C1.34591 15.6746 1.91552 15.9776 2.53568 16.0951C3.8539 20.1689 7.6781 22.9737 12 22.9737C16.3219 22.9737 20.1461 20.1689 21.4643 16.0951C22.0845 15.9776 22.6541 15.6746 23.1051 15.2154C23.6822 14.6279 24 13.8506 24 13.0269C24 12.1928 23.6752 11.4084 23.085 10.8175ZM20.9103 14.7435L20.3826 14.7535L20.2449 15.263C19.241 18.9749 15.8506 21.5674 12 21.5674C8.14928 21.5674 4.75893 18.975 3.75514 15.263L3.61735 14.7535L3.08968 14.7435C2.1614 14.7259 1.4062 13.9559 1.4062 13.0269C1.4062 12.5681 1.58487 12.1367 1.90873 11.8124C2.22458 11.4969 2.64397 11.3185 3.08954 11.3102L3.61731 11.3003L3.7551 10.7907C4.75893 7.07877 8.14933 4.48626 12 4.48626C13.2709 4.48626 14.3049 5.52022 14.3049 6.79114C14.3049 8.06207 13.2709 9.09607 12 9.09607C10.7291 9.09607 9.69512 8.06212 9.69512 6.79119H8.28887C8.28887 8.83753 9.95367 10.5023 12 10.5023C14.0463 10.5023 15.7111 8.83753 15.7111 6.79119C15.7111 6.20883 15.5759 5.65763 15.3358 5.16662C17.6944 6.16984 19.5468 8.20944 20.2449 10.7907L20.3826 11.3003L20.9104 11.3102C21.356 11.3186 21.7754 11.497 22.0907 11.8119C22.4151 12.1367 22.5938 12.5682 22.5938 13.0269C22.5938 13.9559 21.8386 14.726 20.9103 14.7435Z"
                                    fill="black"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="white"
                                      transform="translate(0 0.000244141)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                flex: "1 1 auto",
                              }}
                            >
                              <div
                                className="mlk-row"
                                style={{ rowGap: "0px" }}
                              >
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography text-gray-9">
                                    Children
                                  </span>
                                </div>
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                    2 to 11 years
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <div
                                className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                style={{ rowGap: "0px" }}
                              >
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="minus-circle"
                                    className="anticon anticon-minus-circle"
                                    style={{
                                      color: "rgb(191,191,191)",
                                    }}
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="minus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                    </svg>
                                  </span>
                                </button>
                                <span
                                  className="mlk-typography quantity text-base font-normal"
                                  defaultValue="0"
                                  id="ChilddefaultValue"
                                >
                                  0
                                </span>
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="plus-circle"
                                    className="anticon anticon-plus-circle"
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="plus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                        fill="#0a549c"
                                      ></path>
                                      <path
                                        d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                        fill="#c5d4db"
                                      ></path>
                                      <path
                                        d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                        fill="#0a549c"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="mlk-col mlk-col-24"
                          style={{
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          }}
                        >
                          <div
                            className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                            style={{
                              marginleft: "-5px",
                              marginRight: "-5px",
                              rowGap: "0px",
                            }}
                          >
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                              >
                                <path
                                  d="M23.9438 8.84446C23.9438 4.51257 20.5206 1.00171 16.2947 1.00171C15.8312 1.00171 15.376 1.04309 14.9357 1.12586C14.6758 1.17386 14.4193 1.24007 14.1693 1.31456L16.2947 8.84611L23.9438 8.84446Z"
                                  fill="#030104"
                                ></path>
                                <path
                                  d="M7.01676 9.92696C6.982 9.3294 6.80157 8.36933 6.08483 7.47217C5.32671 6.52203 4.17132 5.89468 2.65508 5.58679C2.48293 5.38485 2.22802 5.25574 1.94165 5.25574H0.946824C0.425409 5.25574 0 5.67949 0 6.20422C0 6.72894 0.423753 7.15104 0.946824 7.15104H1.94165C2.17339 7.15104 2.38196 7.06497 2.54583 6.92758C3.65984 7.17587 4.49245 7.63107 5.02711 8.28822C5.51045 8.88412 5.64784 9.52307 5.6826 9.92861H5.64453C5.64453 14.0751 9.75295 17.4386 14.8231 17.4386C19.8916 17.4386 24 14.0768 24 9.92861C24 9.92861 7.01676 9.92861 7.01676 9.92696Z"
                                  fill="#030104"
                                ></path>
                                <path
                                  d="M11.2063 17.7698C9.76459 17.7698 8.59265 18.9417 8.59265 20.3835C8.59265 21.8252 9.76459 22.9972 11.2063 22.9972C12.6481 22.9972 13.82 21.8252 13.82 20.3835C13.82 18.9417 12.6481 17.7698 11.2063 17.7698ZM11.2063 21.663C10.5012 21.663 9.92681 21.0886 9.92681 20.3851C9.92681 19.6816 10.5012 19.1056 11.2063 19.1056C11.9115 19.1056 12.4859 19.6816 12.4859 20.3851C12.4859 21.0886 11.9115 21.663 11.2063 21.663Z"
                                  fill="#030104"
                                ></path>
                                <path
                                  d="M18.2561 17.7698C16.8143 17.7698 15.6407 18.9417 15.6407 20.3835C15.6407 21.8252 16.8143 22.9972 18.2561 22.9972C19.6962 22.9972 20.8698 21.8252 20.8698 20.3835C20.8698 18.9417 19.6962 17.7698 18.2561 17.7698ZM18.2561 21.663C17.5493 21.663 16.9766 21.0886 16.9766 20.3851C16.9766 19.6816 17.5509 19.1056 18.2561 19.1056C18.9613 19.1056 19.534 19.6816 19.534 20.3851C19.534 21.0886 18.9613 21.663 18.2561 21.663Z"
                                  fill="#030104"
                                ></path>
                              </svg>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                flex: "1 1 auto",
                              }}
                            >
                              <div
                                className="mlk-row"
                                style={{ rowGap: "0px" }}
                              >
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography text-gray-9">
                                    Infants
                                  </span>
                                </div>
                                <div className="mlk-col mlk-col-24">
                                  <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                    0 to 23 months
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="mlk-col"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              <div
                                className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                style={{ rowGap: "0px" }}
                              >
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="minus-circle"
                                    className="anticon anticon-minus-circle"
                                    style={{
                                      color: "rgb(191,191,191)",
                                    }}
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="minus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                    </svg>
                                  </span>
                                </button>
                                <span
                                  className="mlk-typography quantity text-base font-normal"
                                  id="Infants"
                                  defaultValue="0"
                                >
                                  0
                                </span>
                                <button
                                  type="submit"
                                  className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                >
                                  <span
                                    role="img"
                                    aria-label="plus-circle"
                                    className="anticon anticon-plus-circle"
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="plus-circle"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                        fill="#0a549c"
                                      ></path>
                                      <path
                                        d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                        fill="#c5d4db"
                                      ></path>
                                      <path
                                        d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                        fill="#0a549c"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mlk-col mlk-col-24 text-center">
                    <button
                      type="submit"
                      className="mlk-btn mlk-btn-primary done-btn"
                    >
                      <span>Done</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isDesktop ? (
          <div>
            {economytoggle && (
              <div
                id="BookingclassNamePopUp"
                className="mlk-popover _2p3fHACPm_2k5ROOcRLV_s _28c2esx9wEfzsTO_vM0pD1 mlk-popover-placement-bottomRight"
                style={{
                  TransformOrigin: "390px -4px",
                }}
              >
                <div className="mlk-popover-content">
                  <div className="mlk-popover-arrow">
                    <span className="mlk-popover-arrow-content"></span>
                  </div>
                  <div className="mlk-popover-inner" role="tooltip">
                    <div className="mlk-popover-title">
                      <div
                        className="mlk-row mlk-row-space-between _2cBW-pTJvDCb_SWQ6S6nqh"
                        style={{ rowGap: "0px" }}
                      >
                        <div
                          className="mlk-col"
                          style={{ flex: "1 1 auto" }}
                        >
                          <span className="mlk-typography text-tiny font-normal">
                            Flight Class
                          </span>
                        </div>
                        <div className="mlk-col">
                          <span
                            role="img"
                            aria-label="close"
                            onClick={() =>
                              seteconomyToggle(!economytoggle)
                            }
                            tabIndex="-1"
                            className="anticon anticon-close"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="close"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mlk-popover-inner-content">
                      <div
                        className="mlk-row mlk-row-space-between _17YY9XFm_-VCvme-Su4Nyo"
                        style={{ rowGap: "0px" }}
                      >
                        <div className="mlk-col mlk-col-24 content">
                          <div className="_32dewaVC9NM6XtJ9plMXbd">
                            <div
                              className="mlk-row"
                              style={{ rowGap: "0px" }}
                            >
                              <div className="mlk-col mlk-col-24">
                                <div
                                  className="mlk-radio-group mlk-radio-group-outline"
                                  id="classNameEconomy"
                                >
                                  <Radio.Group
                                    onChange={onClassChange}
                                    value={Classvalue}
                                  >
                                    <Space direction="vertical">
                                      <Radio value={"Economy"}>
                                        Economy
                                      </Radio>
                                      <Radio
                                        value={"Premium Economy"}
                                      >
                                        Premium Economy
                                      </Radio>
                                      <Radio value={"First Class"}>
                                        First Class
                                      </Radio>
                                      <Radio value={"Business"}>
                                        Business
                                      </Radio>
                                    </Space>
                                  </Radio.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mlk-col mlk-col-24 text-center">
                          <button
                            type="submit"
                            onClick={() =>
                              seteconomyToggle(!economytoggle)
                            }
                            className="mlk-btn mlk-btn-primary done-btn"
                          >
                            <span>Done</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {economytoggle && (


              <div
                tabIndex={"1"}
                className="mlk-drawer mlk-drawer-open mlk-drawer-bottom _212lkgahcq8aN2d79b6yAX"
                id="MobilePersonPicker"
              >
                <div className="mlk-drawer-mask"></div>
                <div
                  className="mlk-drawer-content-wrapper"
                  style={{ height: "90%" }}
                >
                  <div className="mlk-drawer-content">
                    <div className="mlk-drawer-wrapper-body">
                      <div
                        className="mlk-drawer-header"
                        style={{
                          padding: "16px",
                          borderBottom: "0px",
                        }}
                      >
                        <div className="mlk-drawer-title">
                          <span className="mlk-typography text-xl font-normal">
                            Travelers
                          </span>
                        </div>
                        <button
                          type="submit"
                          onClick={() =>
                            seteconomyToggle(!economytoggle)
                          }
                          aria-label="Close"
                          className="mlk-drawer-close"
                        >
                          <span
                            role="img"
                            aria-label="close"
                            className="anticon anticon-close"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="close"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        className="mlk-drawer-body"
                        style={{ padding: "16px" }}
                      >
                        <div
                          className="mlk-row"
                          style={{ rowGap: "0px" }}
                        >
                          <div className="mlk-col mlk-col-24">
                            <div
                              className="mlk-row"
                              style={{
                                marginleft: "-8px",
                                marginRight: "-8px",
                                rowGap: "16px",
                              }}
                            >
                              <div
                                className="mlk-col mlk-col-24"
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                                  style={{
                                    marginleft: "-5px",
                                    marginRight: "-5px",
                                    rowGap: "0px",
                                  }}
                                >
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M11.9999 11.7378C14.6682 11.7378 16.8316 9.1103 16.8316 5.86907C16.8316 2.62776 16.1213 0.000244141 11.9999 0.000244141C7.87837 0.000244141 7.16797 2.62776 7.16797 5.86907C7.16797 9.1103 9.33133 11.7378 11.9999 11.7378Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M2.87375 20.6987C2.87293 20.5011 2.87211 20.6431 2.87375 20.6987V20.6987Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M21.1257 20.8531C21.1283 20.799 21.1266 20.4777 21.1257 20.8531V20.8531Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M21.1154 20.4617C21.0259 14.8154 20.2885 13.2065 14.6455 12.1881C14.6455 12.1881 13.8512 13.2003 11.9998 13.2003C10.1483 13.2003 9.35388 12.1881 9.35388 12.1881C3.77251 13.1954 2.99024 14.7805 2.88739 20.2783C2.87895 20.7273 2.87504 20.7508 2.87354 20.6987C2.87388 20.7964 2.87429 20.977 2.87429 21.2919C2.87429 21.2919 4.21774 24.0002 11.9998 24.0002C19.7817 24.0002 21.1253 21.2919 21.1253 21.2919C21.1253 21.0896 21.1254 20.9489 21.1256 20.8531C21.1241 20.8854 21.1211 20.8229 21.1154 20.4617Z"
                                          fill="black"
                                        ></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                            transform="translate(0 0.000244141)"
                                          ></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      flex: "1 1 auto",
                                    }}
                                  >
                                    <div
                                      className="mlk-row"
                                      style={{
                                        rowGap: "0px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <div className="mlk-col mlk-col-24">
                                        <span className="mlk-typography text-gray-9">
                                          Adults
                                        </span>
                                      </div>
                                      <div className="mlk-col mlk-col-24">
                                        <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                          12 years and above
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <div
                                      className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                      style={{ rowGap: "0px" }}
                                    >
                                      <button
                                        type="submit"
                                        onClick={DecrementAdultsCount}
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="minus-circle"
                                          className="anticon anticon-minus-circle"
                                          style={{
                                            color: "rgb(191,191,191)",
                                          }}
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="minus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                          </svg>
                                        </span>
                                      </button>
                                      <span
                                        className="mlk-typography quantity text-base font-normal "
                                        id="AdultValueM"
                                        defaultValue={"1"}
                                      >
                                        {adultscount}
                                      </span>
                                      <button
                                        type="submit"
                                        onClick={IncrementAdultsCount}
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="plus-circle"
                                          className="anticon anticon-plus-circle"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="plus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                              fill="#0a549c"
                                            ></path>
                                            <path
                                              d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                              fill="#c5d4db"
                                            ></path>
                                            <path
                                              d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                              fill="#0a549c"
                                            ></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mlk-col mlk-col-24"
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                                  style={{
                                    paddingLeft: "-5px",
                                    paddingRight: "-5px",
                                  }}
                                >
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                    >
                                      <g clipPath="url(#clip0)">
                                        <path
                                          d="M10.2823 13.3081C10.2823 12.0306 9.24298 10.9912 7.96541 10.9912C6.68785 10.9912 5.64844 12.0306 5.64844 13.3081H7.05468C7.05468 12.806 7.46326 12.3975 7.96541 12.3975C8.46757 12.3975 8.8761 12.806 8.8761 13.3081H10.2823Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M16.0346 10.9912C14.757 10.9912 13.7177 12.0306 13.7177 13.3081H15.1239C15.1239 12.806 15.5324 12.3975 16.0346 12.3975C16.5367 12.3975 16.9453 12.806 16.9453 13.3081H18.3516C18.3516 12.0306 17.3122 10.9912 16.0346 10.9912Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M11.9999 17.4228C11.1109 17.4228 10.2772 16.946 9.82428 16.1785L8.61316 16.8931C9.3178 18.0872 10.6155 18.829 11.9999 18.829C13.3842 18.829 14.6819 18.0872 15.3866 16.8931L14.1755 16.1785C13.7226 16.946 12.8889 17.4228 11.9999 17.4228Z"
                                          fill="black"
                                        ></path>
                                        <path
                                          d="M23.085 10.8175C22.6372 10.3702 22.0748 10.0744 21.4644 9.95861C20.1461 5.88483 16.3219 3.07997 12 3.07997H11.5286C10.3965 3.07997 9.47546 2.1589 9.47546 1.02686H8.06922C8.06922 1.79505 8.32122 2.50529 8.74644 3.07997H8.30092C7.16883 3.07997 6.24781 2.1589 6.24781 1.02686H4.84156C4.84156 2.55476 5.83757 3.85358 7.21408 4.31034C5.03702 5.50723 3.33063 7.502 2.53568 9.95861C1.92524 10.0744 1.3627 10.3703 0.914477 10.8181C0.324763 11.4084 0 12.1928 0 13.0269C0 13.8506 0.31779 14.6279 0.894888 15.2154C1.34591 15.6746 1.91552 15.9776 2.53568 16.0951C3.8539 20.1689 7.6781 22.9737 12 22.9737C16.3219 22.9737 20.1461 20.1689 21.4643 16.0951C22.0845 15.9776 22.6541 15.6746 23.1051 15.2154C23.6822 14.6279 24 13.8506 24 13.0269C24 12.1928 23.6752 11.4084 23.085 10.8175ZM20.9103 14.7435L20.3826 14.7535L20.2449 15.263C19.241 18.9749 15.8506 21.5674 12 21.5674C8.14928 21.5674 4.75893 18.975 3.75514 15.263L3.61735 14.7535L3.08968 14.7435C2.1614 14.7259 1.4062 13.9559 1.4062 13.0269C1.4062 12.5681 1.58487 12.1367 1.90873 11.8124C2.22458 11.4969 2.64397 11.3185 3.08954 11.3102L3.61731 11.3003L3.7551 10.7907C4.75893 7.07877 8.14933 4.48626 12 4.48626C13.2709 4.48626 14.3049 5.52022 14.3049 6.79114C14.3049 8.06207 13.2709 9.09607 12 9.09607C10.7291 9.09607 9.69512 8.06212 9.69512 6.79119H8.28887C8.28887 8.83753 9.95367 10.5023 12 10.5023C14.0463 10.5023 15.7111 8.83753 15.7111 6.79119C15.7111 6.20883 15.5759 5.65763 15.3358 5.16662C17.6944 6.16984 19.5468 8.20944 20.2449 10.7907L20.3826 11.3003L20.9104 11.3102C21.356 11.3186 21.7754 11.497 22.0907 11.8119C22.4151 12.1367 22.5938 12.5682 22.5938 13.0269C22.5938 13.9559 21.8386 14.726 20.9103 14.7435Z"
                                          fill="black"
                                        ></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                            transform="translate(0 0.000244141)"
                                          ></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      flex: "1 1 auto",
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="mlk-row"
                                      style={{ rowGap: "0px" }}
                                    >
                                      <div className="mlk-col mlk-col-24">
                                        <span className="mlk-typography text-gray-9">
                                          Children
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <div
                                      className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                      style={{ rowGap: "0px" }}
                                    >
                                      <button
                                        type="submit"
                                        onClick={
                                          DecrementChildrenCount
                                        }
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="minus-circle"
                                          className="anticon anticon-minus-circle"
                                          style={{
                                            color: "rgb(191,191,191)",
                                          }}
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="minus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                          </svg>
                                        </span>
                                      </button>
                                      <span
                                        className="mlk-typography quantity text-base font-normal child children-select"
                                        id="ChildValueM"
                                        defaultValue={"0"}
                                      >
                                        {childrencount}
                                      </span>
                                      <button
                                        type="submit"
                                        onClick={
                                          IncrementChildrenCount
                                        }
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="plus-circle"
                                          className="anticon anticon-plus-circle"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="plus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                              fill="#0a549c"
                                            ></path>
                                            <path
                                              d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                              fill="#c5d4db"
                                            ></path>
                                            <path
                                              d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                              fill="#0a549c"
                                            ></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mlk-col mlk-col-24"
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                                  style={{
                                    paddingLeft: "-5px",
                                    paddingRight: "-5px",
                                    rowGap: "0px",
                                  }}
                                >
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                    >
                                      <path
                                        d="M23.9438 8.84446C23.9438 4.51257 20.5206 1.00171 16.2947 1.00171C15.8312 1.00171 15.376 1.04309 14.9357 1.12586C14.6758 1.17386 14.4193 1.24007 14.1693 1.31456L16.2947 8.84611L23.9438 8.84446Z"
                                        fill="#030104"
                                      ></path>
                                      <path
                                        d="M7.01676 9.92696C6.982 9.3294 6.80157 8.36933 6.08483 7.47217C5.32671 6.52203 4.17132 5.89468 2.65508 5.58679C2.48293 5.38485 2.22802 5.25574 1.94165 5.25574H0.946824C0.425409 5.25574 0 5.67949 0 6.20422C0 6.72894 0.423753 7.15104 0.946824 7.15104H1.94165C2.17339 7.15104 2.38196 7.06497 2.54583 6.92758C3.65984 7.17587 4.49245 7.63107 5.02711 8.28822C5.51045 8.88412 5.64784 9.52307 5.6826 9.92861H5.64453C5.64453 14.0751 9.75295 17.4386 14.8231 17.4386C19.8916 17.4386 24 14.0768 24 9.92861C24 9.92861 7.01676 9.92861 7.01676 9.92696Z"
                                        fill="#030104"
                                      ></path>
                                      <path
                                        d="M11.2063 17.7698C9.76459 17.7698 8.59265 18.9417 8.59265 20.3835C8.59265 21.8252 9.76459 22.9972 11.2063 22.9972C12.6481 22.9972 13.82 21.8252 13.82 20.3835C13.82 18.9417 12.6481 17.7698 11.2063 17.7698ZM11.2063 21.663C10.5012 21.663 9.92681 21.0886 9.92681 20.3851C9.92681 19.6816 10.5012 19.1056 11.2063 19.1056C11.9115 19.1056 12.4859 19.6816 12.4859 20.3851C12.4859 21.0886 11.9115 21.663 11.2063 21.663Z"
                                        fill="#030104"
                                      ></path>
                                      <path
                                        d="M18.2561 17.7698C16.8143 17.7698 15.6407 18.9417 15.6407 20.3835C15.6407 21.8252 16.8143 22.9972 18.2561 22.9972C19.6962 22.9972 20.8698 21.8252 20.8698 20.3835C20.8698 18.9417 19.6962 17.7698 18.2561 17.7698ZM18.2561 21.663C17.5493 21.663 16.9766 21.0886 16.9766 20.3851C16.9766 19.6816 17.5509 19.1056 18.2561 19.1056C18.9613 19.1056 19.534 19.6816 19.534 20.3851C19.534 21.0886 18.9613 21.663 18.2561 21.663Z"
                                        fill="#030104"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      flex: "1 1 auto",
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="mlk-row"
                                      style={{ rowGap: "0px" }}
                                    >
                                      <div className="mlk-col mlk-col-24">
                                        <span className="mlk-typography text-gray-9">
                                          Infants
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="mlk-col"
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <div
                                      className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                      style={{ rowGap: "0px" }}
                                    >
                                      <button
                                        type="submit"
                                        onClick={
                                          DecrementInfantsCount
                                        }
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="minus-circle"
                                          className="anticon anticon-minus-circle"
                                          style={{
                                            color: "rgb(191,191,191)",
                                          }}
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="minus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                          </svg>
                                        </span>
                                      </button>
                                      <span
                                        className="mlk-typography quantity text-base font-normal"
                                        id="InfantsM"
                                        defaultValue={"1"}
                                      >
                                        {infantscount}
                                      </span>
                                      <button
                                        type="submit"
                                        onClick={
                                          IncrementInfantsCount
                                        }
                                        className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                      >
                                        <span
                                          role="img"
                                          aria-label="plus-circle"
                                          className="anticon anticon-plus-circle"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="plus-circle"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                              fill="#0a549c"
                                            ></path>
                                            <path
                                              d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                              fill="#c5d4db"
                                            ></path>
                                            <path
                                              d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                              fill="#0a549c"
                                            ></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mlk-col mlk-col-24 mt-5 mb-2">
                            <span className="mlk-typography cabin-title text-tiny font-normal">
                              Flight Class
                            </span>
                          </div>
                          <div className="mlk-col mlk-col-24">
                            <div
                              className="mlk-row"
                              style={{
                                rowGap: "0px",
                                textAlign: "left",
                                marginLeft: "18px",
                              }}
                            >
                              <div className="mlk-col mlk-col-24">
                                <div className="mlk-radio-group mlk-radio-group-outline">
                                  <Radio.Group
                                    onChange={onClassChange}
                                    value={Classvalue}
                                  >
                                    <Space direction="vertical">
                                      <Radio value={"Economy"}>
                                        Economy
                                      </Radio>
                                      <Radio
                                        value={"Premium Economy"}
                                      >
                                        Premium Economy
                                      </Radio>
                                      <Radio value={"First Class"}>
                                        First Class
                                      </Radio>
                                      <Radio value={"Business"}>
                                        Business
                                      </Radio>
                                    </Space>
                                  </Radio.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mlk-drawer-footer"
                        style={{ padding: "16px", borderTop: "0px" }}
                      >
                        <div
                          className="mlk-row"
                          style={{ rowGap: "0px" }}
                        >
                          <div className="mlk-col mlk-col-24 text-center">
                            <button
                              type="submit"
                              onClick={() =>
                                seteconomyToggle(!economytoggle)
                              }
                              className="mlk-btn mlk-btn-primary mlk-btn-lg"
                            >
                              <span>Done</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {toggle && (
          <div
            id="PassengersPopUp"
            className="mlk-popover CustomclassNamePop _2p3fHACPm_2k5ROOcRLV_s _3lm1kPEmbQZy6J1BHR0nJM mlk-popover-placement-bottomRight"
            style={{ transformOrigin: "354px -4px" }}
          >
            <div className="mlk-popover-content">
              <div className="mlk-popover-arrow">
                <span className="mlk-popover-arrow-content"></span>
              </div>
              <div className="mlk-popover-inner" role="tooltip">
                <div className="mlk-popover-title">
                  <div
                    className="mlk-row mlk-row-space-between _2cBW-pTJvDCb_SWQ6S6nqh"
                    style={{ rowGap: "0px" }}
                  >
                    <div className="mlk-col" style={{ flex: "1 1 auto" }}>
                      <span className="mlk-typography text-tiny font-normal">
                        Travelers
                      </span>
                    </div>
                    <div className="mlk-col">
                      <span
                        role="img"
                        aria-label="close"
                        onClick={() => setToggle(!toggle)}
                        tabIndex="-1"
                        className="anticon anticon-close"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="close"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mlk-popover-inner-content">
                  <div
                    className="mlk-row mlk-row-space-between _17YY9XFm_-VCvme-Su4Nyo"
                    style={{ rowGap: "0px" }}
                  >
                    <div className="mlk-col mlk-col-24 content">
                      <div className="_13osEtXv_ZRA69EM9rnOj4">
                        <div
                          className="mlk-row"
                          style={{
                            marginleft: "-8px",
                            marginRight: "-8px",
                            rowGap: "16px",
                          }}
                        >
                          <div
                            className="mlk-col mlk-col-24"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <div
                              className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                              style={{
                                marginleft: "-5px",
                                marginRight: "-5px",
                                rowGap: "0px",
                              }}
                            >
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                >
                                  <g clipPath="url(#clip0)">
                                    <path
                                      d="M11.9999 11.7378C14.6682 11.7378 16.8316 9.1103 16.8316 5.86907C16.8316 2.62776 16.1213 0.000244141 11.9999 0.000244141C7.87837 0.000244141 7.16797 2.62776 7.16797 5.86907C7.16797 9.1103 9.33133 11.7378 11.9999 11.7378Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M2.87375 20.6987C2.87293 20.5011 2.87211 20.6431 2.87375 20.6987V20.6987Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M21.1257 20.8531C21.1283 20.799 21.1266 20.4777 21.1257 20.8531V20.8531Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M21.1154 20.4617C21.0259 14.8154 20.2885 13.2065 14.6455 12.1881C14.6455 12.1881 13.8512 13.2003 11.9998 13.2003C10.1483 13.2003 9.35388 12.1881 9.35388 12.1881C3.77251 13.1954 2.99024 14.7805 2.88739 20.2783C2.87895 20.7273 2.87504 20.7508 2.87354 20.6987C2.87388 20.7964 2.87429 20.977 2.87429 21.2919C2.87429 21.2919 4.21774 24.0002 11.9998 24.0002C19.7817 24.0002 21.1253 21.2919 21.1253 21.2919C21.1253 21.0896 21.1254 20.9489 21.1256 20.8531C21.1241 20.8854 21.1211 20.8229 21.1154 20.4617Z"
                                      fill="black"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.000244141)"
                                      ></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  flex: "1 1 auto",
                                }}
                              >
                                <div
                                  className="mlk-row"
                                  style={{ rowGap: "0px" }}
                                >
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography text-gray-9">
                                      Adults
                                    </span>
                                  </div>
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                      12 years and above
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                  style={{ rowGap: "0px" }}
                                >
                                  <button
                                    type="submit"
                                    onClick={DecrementAdultsCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="minus-circle"
                                      className="anticon anticon-minus-circle"
                                      style={{ color: "rgb(191,191,191)" }}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="minus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <span
                                    className="mlk-typography quantity text-base font-normal"
                                    id="AdultdefaultValue"
                                    value={adultscount}
                                  >
                                    {adultscount}
                                  </span>
                                  <button
                                    type="submit"
                                    onClick={IncrementAdultsCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="plus-circle"
                                      className="anticon anticon-plus-circle"
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="plus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                          fill="#0a549c"
                                        ></path>
                                        <path
                                          d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                          fill="#c5d4db"
                                        ></path>
                                        <path
                                          d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                          fill="#0a549c"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="mlk-col mlk-col-24"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <div
                              className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                              style={{
                                marginLeft: "-5px",
                                marginRight: "-5px",
                                rowGap: "0px",
                              }}
                            >
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                >
                                  <g clipPath="url(#clip0)">
                                    <path
                                      d="M10.2823 13.3081C10.2823 12.0306 9.24298 10.9912 7.96541 10.9912C6.68785 10.9912 5.64844 12.0306 5.64844 13.3081H7.05468C7.05468 12.806 7.46326 12.3975 7.96541 12.3975C8.46757 12.3975 8.8761 12.806 8.8761 13.3081H10.2823Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M16.0346 10.9912C14.757 10.9912 13.7177 12.0306 13.7177 13.3081H15.1239C15.1239 12.806 15.5324 12.3975 16.0346 12.3975C16.5367 12.3975 16.9453 12.806 16.9453 13.3081H18.3516C18.3516 12.0306 17.3122 10.9912 16.0346 10.9912Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M11.9999 17.4228C11.1109 17.4228 10.2772 16.946 9.82428 16.1785L8.61316 16.8931C9.3178 18.0872 10.6155 18.829 11.9999 18.829C13.3842 18.829 14.6819 18.0872 15.3866 16.8931L14.1755 16.1785C13.7226 16.946 12.8889 17.4228 11.9999 17.4228Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M23.085 10.8175C22.6372 10.3702 22.0748 10.0744 21.4644 9.95861C20.1461 5.88483 16.3219 3.07997 12 3.07997H11.5286C10.3965 3.07997 9.47546 2.1589 9.47546 1.02686H8.06922C8.06922 1.79505 8.32122 2.50529 8.74644 3.07997H8.30092C7.16883 3.07997 6.24781 2.1589 6.24781 1.02686H4.84156C4.84156 2.55476 5.83757 3.85358 7.21408 4.31034C5.03702 5.50723 3.33063 7.502 2.53568 9.95861C1.92524 10.0744 1.3627 10.3703 0.914477 10.8181C0.324763 11.4084 0 12.1928 0 13.0269C0 13.8506 0.31779 14.6279 0.894888 15.2154C1.34591 15.6746 1.91552 15.9776 2.53568 16.0951C3.8539 20.1689 7.6781 22.9737 12 22.9737C16.3219 22.9737 20.1461 20.1689 21.4643 16.0951C22.0845 15.9776 22.6541 15.6746 23.1051 15.2154C23.6822 14.6279 24 13.8506 24 13.0269C24 12.1928 23.6752 11.4084 23.085 10.8175ZM20.9103 14.7435L20.3826 14.7535L20.2449 15.263C19.241 18.9749 15.8506 21.5674 12 21.5674C8.14928 21.5674 4.75893 18.975 3.75514 15.263L3.61735 14.7535L3.08968 14.7435C2.1614 14.7259 1.4062 13.9559 1.4062 13.0269C1.4062 12.5681 1.58487 12.1367 1.90873 11.8124C2.22458 11.4969 2.64397 11.3185 3.08954 11.3102L3.61731 11.3003L3.7551 10.7907C4.75893 7.07877 8.14933 4.48626 12 4.48626C13.2709 4.48626 14.3049 5.52022 14.3049 6.79114C14.3049 8.06207 13.2709 9.09607 12 9.09607C10.7291 9.09607 9.69512 8.06212 9.69512 6.79119H8.28887C8.28887 8.83753 9.95367 10.5023 12 10.5023C14.0463 10.5023 15.7111 8.83753 15.7111 6.79119C15.7111 6.20883 15.5759 5.65763 15.3358 5.16662C17.6944 6.16984 19.5468 8.20944 20.2449 10.7907L20.3826 11.3003L20.9104 11.3102C21.356 11.3186 21.7754 11.497 22.0907 11.8119C22.4151 12.1367 22.5938 12.5682 22.5938 13.0269C22.5938 13.9559 21.8386 14.726 20.9103 14.7435Z"
                                      fill="black"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.000244141)"
                                      ></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  flex: "1 1 auto",
                                }}
                              >
                                <div
                                  className="mlk-row"
                                  style={{ rowGap: "0px" }}
                                >
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography text-gray-9">
                                      Children
                                    </span>
                                  </div>
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                      2 to 11 years
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                  style={{ rowGap: "0px" }}
                                >
                                  <button
                                    type="submit"
                                    onClick={DecrementChildrenCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="minus-circle"
                                      className="anticon anticon-minus-circle"
                                      style={{ color: "rgb(191,191,191)" }}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="minus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <span
                                    className="mlk-typography quantity text-base font-normal"
                                    value={childrencount}
                                    id="ChilddefaultValue"
                                  >
                                    {childrencount}
                                  </span>
                                  <button
                                    type="submit"
                                    onClick={IncrementChildrenCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="plus-circle"
                                      className="anticon anticon-plus-circle"
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="plus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                          fill="#0a549c"
                                        ></path>
                                        <path
                                          d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                          fill="#c5d4db"
                                        ></path>
                                        <path
                                          d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                          fill="#0a549c"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="mlk-col mlk-col-24"
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                          >
                            <div
                              className="mlk-row mlk-row-middle _3SDCK3iZzYhJGMx9MhJOoB"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                rowGap: "0px",
                              }}
                            >
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                >
                                  <path
                                    d="M23.9438 8.84446C23.9438 4.51257 20.5206 1.00171 16.2947 1.00171C15.8312 1.00171 15.376 1.04309 14.9357 1.12586C14.6758 1.17386 14.4193 1.24007 14.1693 1.31456L16.2947 8.84611L23.9438 8.84446Z"
                                    fill="#030104"
                                  ></path>
                                  <path
                                    d="M7.01676 9.92696C6.982 9.3294 6.80157 8.36933 6.08483 7.47217C5.32671 6.52203 4.17132 5.89468 2.65508 5.58679C2.48293 5.38485 2.22802 5.25574 1.94165 5.25574H0.946824C0.425409 5.25574 0 5.67949 0 6.20422C0 6.72894 0.423753 7.15104 0.946824 7.15104H1.94165C2.17339 7.15104 2.38196 7.06497 2.54583 6.92758C3.65984 7.17587 4.49245 7.63107 5.02711 8.28822C5.51045 8.88412 5.64784 9.52307 5.6826 9.92861H5.64453C5.64453 14.0751 9.75295 17.4386 14.8231 17.4386C19.8916 17.4386 24 14.0768 24 9.92861C24 9.92861 7.01676 9.92861 7.01676 9.92696Z"
                                    fill="#030104"
                                  ></path>
                                  <path
                                    d="M11.2063 17.7698C9.76459 17.7698 8.59265 18.9417 8.59265 20.3835C8.59265 21.8252 9.76459 22.9972 11.2063 22.9972C12.6481 22.9972 13.82 21.8252 13.82 20.3835C13.82 18.9417 12.6481 17.7698 11.2063 17.7698ZM11.2063 21.663C10.5012 21.663 9.92681 21.0886 9.92681 20.3851C9.92681 19.6816 10.5012 19.1056 11.2063 19.1056C11.9115 19.1056 12.4859 19.6816 12.4859 20.3851C12.4859 21.0886 11.9115 21.663 11.2063 21.663Z"
                                    fill="#030104"
                                  ></path>
                                  <path
                                    d="M18.2561 17.7698C16.8143 17.7698 15.6407 18.9417 15.6407 20.3835C15.6407 21.8252 16.8143 22.9972 18.2561 22.9972C19.6962 22.9972 20.8698 21.8252 20.8698 20.3835C20.8698 18.9417 19.6962 17.7698 18.2561 17.7698ZM18.2561 21.663C17.5493 21.663 16.9766 21.0886 16.9766 20.3851C16.9766 19.6816 17.5509 19.1056 18.2561 19.1056C18.9613 19.1056 19.534 19.6816 19.534 20.3851C19.534 21.0886 18.9613 21.663 18.2561 21.663Z"
                                    fill="#030104"
                                  ></path>
                                </svg>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  flex: "1 1 auto",
                                }}
                              >
                                <div
                                  className="mlk-row"
                                  style={{ rowGap: "0px" }}
                                >
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography text-gray-9">
                                      Infants
                                    </span>
                                  </div>
                                  <div className="mlk-col mlk-col-24">
                                    <span className="mlk-typography mlk-typography-secondary font-secondary font-normal text-sm text-gray-9">
                                      0 to 23 months
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="mlk-col"
                                style={{
                                  marginLeft: "-5px",
                                  marginRight: "-5px",
                                }}
                              >
                                <div
                                  className="mlk-row mlk-row-center mlk-row-middle st-quantity-selector"
                                  style={{ rowGap: "0px" }}
                                >
                                  <button
                                    type="submit"
                                    onClick={DecrementInfantsCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only minus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="minus-circle"
                                      className="anticon anticon-minus-circle"
                                      style={{ color: "rgb(191,191,191)" }}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="minus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <span
                                    className="mlk-typography quantity text-base font-normal"
                                    id="Infants"
                                    value={infantscount}
                                  >
                                    {infantscount}
                                  </span>
                                  <button
                                    type="submit"
                                    onClick={IncrementInfantsCount}
                                    className="mlk-btn mlk-btn-text mlk-btn-circle mlk-btn-lg mlk-btn-icon-only plus-icon"
                                  >
                                    <span
                                      role="img"
                                      aria-label="plus-circle"
                                      className="anticon anticon-plus-circle"
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="plus-circle"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                                          fill="#0a549c"
                                        ></path>
                                        <path
                                          d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                                          fill="#c5d4db"
                                        ></path>
                                        <path
                                          d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"
                                          fill="#0a549c"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mlk-col mlk-col-24 text-center">
                      <button
                        type="submit"
                        onClick={() => setToggle(!toggle)}
                        className="mlk-btn mlk-btn-primary done-btn"
                      >
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          tabIndex={"1"}
          className="mlk-drawer mlk-drawer-bottom  _23G7la1E16UsFbzfluUqE7 o-iP3JFardT4CsXxQbfK7"
          style={{ zIndex: "12" }}
          id="MobileDatePickerOverlay"
        >
          <div className="mlk-drawer-mask" id=""></div>
          <div className="mlk-drawer-content-wrapper" style={{ height: "80%" }}>
            <div className="mlk-drawer-content">
              <div className="mlk-drawer-wrapper-body">
                <div className="mlk-drawer-header">
                  <div className="mlk-drawer-title">Select Date</div>
                  <button
                    type="submit"
                    aria-label="Close"
                    className="mlk-drawer-close"
                    onClick={ClosePopUp}
                  >
                    <span
                      role="img"
                      aria-label="close"
                      className="anticon anticon-close"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="mlk-drawer-body">
                  <div className="">
                    <div
                      className=""
                      id="StartDateDivFlight"
                      style={{ display: "block" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SearchBar;

const Modal = ({ onClose }) => {
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  return ReactDOM.createPortal(
    <div style={modalStyle}>
      {/* Content of the modal */}
      <div className="modalContent">


        <button onClick={onClose}>Close</button>
      </div>
    </div>,
    document.body
  );
};