import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import "./FlightBooking.css";
import FlightTimeConverter from "./FlightTimeConverter";
import { Button, Tooltip } from 'antd';

export default function Multi_Booking() {
 
  //const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [countries, setCountries] = useState([]);

  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [Cities, setCities] = useState([]);

  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [data, setData] = useState("");
  const [TransactionID, setTransactionID] = useState("");
  var resp = "";

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const flightData = location.state.props;
 
  let adults = location.state.adults;
  let children = location.state.children;
  let infants = location.state.infants;
  console.log('flight data',adults, children)
  let totalPax = 0;
  
  let TotalPrice = 0;
  let TotalTaxes = 0;
  let GrandPrice = 0;
  let GrandTotal= 0;
  let AdultTotalPrice = 0;
  let AdultBasePrice = 0;
  let AdultTaxes = 0;
  let ChildTotalPrice = 0;
  let ChildBasePrice = 0;
  let ChildTaxes = 0;
  let InfantTotalPrice = 0;
  let InfantBasePrice = 0;
  let InfantTaxes = 0;
  let Origin = "";
  let Destination = "";
  let duration = 0;
  let hours = 0;
  let minutes = 0;
  let img = "";
  let BookingID = 0;
  let AirlineCode = "";
  let startarrTimeMoment=new Date();
  let endarrTimeMoment=new Date();
  let arrTimeDifferenceInMinutes = 0;
  const [formValue, setformValue] = useState({
    firstName_0: "",
    lastName_0: "",
    SegmentKey: flightData[0].SegmentKey,
    AvailabilitySource: flightData[0].AvailabilitySource,
    Equipment: flightData[0].Equipment,
    AvailabilityDisplayType: flightData[0].AvailabilityDisplayType,
    Group: flightData[0].Group,
    Carrier: flightData[0].Carrier,
    Distance: flightData[0].Distance,
    ProviderCode: flightData[0].ProviderCode,
    BookingCode: flightData[0].BookingCode,
    country_0: "",
    email_0: "",
    address_0: "",
    MobileNO_0: "",
    LandlineNo: "",
    bookingTitle: "",
    Gender_0: "",
    document_0: "",
    documenttype_0: "",
    expiry_0: "",
    nationality_0: "",
    dob_0: "",
    title_0: "",
    Supplier: "",
    Adults: adults,
    Children: children,
    Infants: infants,
    BookingID: "",
    type: "",
    count: "",
    from: flightData[0].Origin,
    to: flightData[0].FinalDestination,
    start: flightData[0].DepartureTime,
    end: flightData[0].ArrivalTime,
    FlightNumber: flightData[0].FlightNumber,
    serviceclass0: flightData[0].CabinClass,
    stops0: "",
    total0: "",
    amount0: "",
    taxes0: "",
    currency0: "",
    totaltime0: flightData[0].FlightTime,
    totaltime1: "",
    Weight: flightData[0].MaxWeightValue,
    stops1: "",
    AdultBasePrice: flightData[0].AdultBasePrice.replace("PKR ", ""),
    AdultTotalPrice: flightData[0].AdultTotalPrice.replace("PKR ", ""),
    AdultTaxes: flightData[0].AdultTaxes.replace("PKR ", ""),
    ChildBasePrice: 0,
    GrandTotal: parseInt(flightData[0].AdultBasePrice.replace("PKR ", "")) + 1000 + parseInt(flightData[0].AdultTaxes.replace("PKR ", "")),
    ChildTotalPrice: flightData[0].ChildTotalPrice.replace("PKR ", ""),
    ChildTaxes: flightData[0].ChildTaxes.replace("PKR ", ""),
    InfantTotalPrice: flightData[0].InfantTotalPrice.replace("PKR ", ""),
    InfantTaxes: flightData[0].InfantTaxes.replace("PKR ", ""),
    AirlineCode: flightData[0].Carrier,
    CancelPenalty: flightData[0].CancelPenalty,
    ChangePenalty: flightData[0].ChangePenalty,
    // userid: '-1',
  });
  
  
//console.log('formvalue',formValue)

const [isInitialized, setIsInitialized] = useState(false);

useEffect(() => {
  if (!isInitialized) {
    const Amounts = async () => {
      let AdultTotalPrice = parseInt(removeLetters(flightData[0].AdultTotalPrice)) || 0;
      let AdultBasePrice = parseInt(removeLetters(flightData[0].AdultBasePrice)) || 0;
      let AdultTaxes = parseInt(removeLetters(flightData[0].AdultTaxes)) || 0;

      let TotalPrice = AdultBasePrice + 1000;
      let TotalTaxes = AdultTaxes;
      let GrandPrice = TotalPrice + TotalTaxes;

      setformValue((prevFormValue) => ({
        ...prevFormValue,
        AdultTotalPrice: prevFormValue.AdultTotalPrice !== 0 ? prevFormValue.AdultTotalPrice : AdultTotalPrice,
        AdultBasePrice: prevFormValue.AdultBasePrice !== 0 ? prevFormValue.AdultBasePrice : AdultBasePrice,
        AdultTaxes: prevFormValue.AdultTaxes !== 0 ? prevFormValue.AdultTaxes : AdultTaxes,
        GrandTotal: prevFormValue.GrandTotal !== 0 ? prevFormValue.GrandTotal : GrandPrice,
        ChildTotalPrice: prevFormValue.ChildTotalPrice !== 0 ? prevFormValue.ChildTotalPrice : flightData[0].ChildTotalPrice || 0,
        ChildTaxes: prevFormValue.ChildTaxes !== 0 ? prevFormValue.ChildTaxes : flightData[0].ChildTaxes || 0,
        InfantTotalPrice: prevFormValue.InfantTotalPrice !== 0 ? prevFormValue.InfantTotalPrice : flightData[0].InfantTotalPrice || 0,
      }));

      setGrandPrice(GrandPrice);
      setPrice(GrandPrice);
      setTotalPrice(TotalPrice);
      setTotalTaxes(TotalTaxes);
    };

    if (flightData && flightData.length > 0) {
      Amounts();
      setIsInitialized(true);
    }
  }
}, [flightData]);

console.log('formvalue', formValue);






  // Function to handle when the mouse enters the Span
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  // Function to handle when the mouse leaves the Span
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };
 





  const apiUrl = process.env.REACT_APP_API_URL;


 

  const departureFlights = [flightData[0]]; // Initialize the departure array with the first flight
  const returnFlights = [];
  // Group flights by matching optionId
  for (let i = 1; i < flightData.length; i++) {
    const currentFlight = flightData[i];

    if (currentFlight.Option_Id  === departureFlights[0].Option_Id ) 
    {
      departureFlights.push(currentFlight);
    } else {
      returnFlights.push(currentFlight);
    }
  }
  
  const departure = departureFlights[0];
  const returnflight = returnFlights[0];
  // Calculate the total flight time for departure and return flights in minutes
  // const departureFlightTime = departureFlights.reduce((total, flight) => total + Number(flight.FlightTime), 0);
  // const returnFlightTime = returnFlights.reduce((total, flight) => total + Number(flight.FlightTime), 0);

  // Get the time of the last index in each array
  const lastDepartureFlightTime = departureFlights[departureFlights.length - 1]?.ArrivalTime || 0;
  const lastReturnFlightTime = returnFlights[returnFlights.length - 1]?.ArrivalTime || 0;

  const startdepTimeMoment = moment(departure.DepartureTime);
  const enddepTimeMoment = moment(lastDepartureFlightTime);
  if(returnflight !== undefined){
    startarrTimeMoment = moment(returnflight.DepartureTime);
    endarrTimeMoment = moment(lastReturnFlightTime);
  }
 

  const depTimeDifferenceInMinutes = enddepTimeMoment.diff(startdepTimeMoment, 'minutes');
  if(returnflight !== undefined)
  {
    arrTimeDifferenceInMinutes = endarrTimeMoment.diff(startarrTimeMoment, 'minutes');
  }

 

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [grandPrice, setGrandPrice] = useState(0);
  const [price, setPrice] = useState('')
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");

  const [returnorigin, setReturnOrigin] = useState("");
  const [returndestination, setReturnDestination] = useState("");

  const [jazzFormFields, setJazzFormFields] = useState({ key1: "", key2: "" });

  const [inputFields, setInputFields] = useState([{}]);

  const [loading, setLoading] = useState(false);

  const url = apiUrl + "/api/FlightBooking";


 
  async function fetchData() {
    const instance = axios.create({
      baseURL: url,
      timeout: 100000,
      headers: { "X-Custom-Header": "foobar" },
    });
    console.log('instance',instance);
    setDisableSubmitBtn(true);
    console.log('updateflight', newObject);
  
    instance
      .post("/Booking", newObject)
      .then((response) => {
        //debugger
        resp = response.data;
        setTransactionID(resp);
        setDisableSubmitBtn(false);
        console.log('transaction',resp);

        setformValue({
          ...formValue,
          TransactionId: resp,
        
        });
      })
      .catch((error) => {
        console.log(error);
        alert("Fares not available. Please choose another flight.")
        window.history.back();
      });
  }



  const newObject = flightData.map((object) => {
    return {
      ...object,
      Option_Id: String(flightData.Option_Id),
      Adults: adults,
      Children: children,
      Infants: infants,
    };
  });

 
  async function fetchOriginName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
          flightData[0].Origin
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchDestinationName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        departure.FinalDestination
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setDestination(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchReturnOriginName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        returnflight.Origin
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setReturnOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchReturnDestinationName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        returnflight.FinalDestination
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setReturnDestination(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchCountries() {
    await axios
      .get(apiUrl + "/api/FlightSearch/countries")
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        // console.log(response.data);
        setTimeout(() => {
          setCountries(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchCities(c) {
    await axios
      .get(apiUrl + "/api/FlightSearch/cities?term=" + c)
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //  console.log(response.data);
        setTimeout(() => {
          setCities(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
   
    //fetchData();
    totalPax = adults - 1 + children + infants;

    if (totalPax > 1) {
      addNewFields(totalPax);
    }
    fetchOriginName();
    fetchDestinationName();
    if(returnflight !== undefined){
    fetchReturnOriginName();
    fetchReturnDestinationName();
    }
    fetchCountries();
   
  }, []);
 

   //Calculate Layover Time For Flights Start 
   const calculateLayoverTime = (flight1, flight2) => {
    const arrivalTime = new Date(flight1.ArrivalTime);
    const departureTime = new Date(flight2.DepartureTime);

    const layoverTimeMilliseconds = departureTime - arrivalTime;
    const layoverTimeMinutes = layoverTimeMilliseconds / (1000 * 60);

    const hours = Math.floor(layoverTimeMinutes / 60);
    const remainingMinutes = layoverTimeMinutes % 60;
    let string = <p style={{color:"white"}}>Layover: {hours}h {remainingMinutes}m  ({flight2.Origin})</p>

    return string;
  };

  const layoverTimesdeparture = departureFlights.map((flight, index) => {
    if (index < departureFlights.length - 1) {
      const layoverTime = calculateLayoverTime(flight, departureFlights[index + 1]);
      return layoverTime;
    }
    return null;
  });

  const layoverTimesarrival = returnFlights.map((flight, index) => {
    if (index < returnFlights.length - 1) {
      const layoverTime = calculateLayoverTime(flight, returnFlights[index + 1]);
      return layoverTime;
    }
    return null;
  });
  const deptext = <span>{layoverTimesdeparture.length > 1 ? layoverTimesdeparture:"Direct"}</span>;
  const arrtext = <span>{layoverTimesarrival.length > 1 ? layoverTimesarrival :"Direct"}</span>;
//Calculate Layover Time for Flights End 



  const addNewFields = (num) => {
    let newFields = [...inputFields];

    for (let i = 0; i < num; i++) {
      newFields.push({
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        address: "",
        MobileNO: "",
        LandlineNo: "",
        bookingTitle: "",
        Gender: "",
        document: "",
        documenttype: "",
        expiry: "",
        nationality: "",
        dob: "",
        title: "",
      });
    }
    setInputFields(newFields);
  };

  const [contentRef, setContentRef] = useState(null)

  const [paymentData, setPaymentData] = useState(null);

  
  function navigateToPaymentGateway()
  {
    const jsonData = JSON.stringify(formValue);
    navigate('/Payment',{state:{props:  grandPrice}});

  }

  function postData() {
    //console.log(flightData[0].Option_Id);

   const instance = axios.create({
      baseURL: url,
      timeout: 10000,
      headers: { "X-Custom-Header": "foobar" },
    });

    if (formValue.firstName_0 != undefined) {
      if (formValue.firstName_0.length < 1) {
        alert("Please Provide First Name.");
        return;
      }
    } else {
      alert("Please Provide First Name.");
      return;
    }

    if (formValue.lastName_0 != undefined) {
      if (formValue.lastName_0.length < 1) {
        alert("Please Provide Last Name.");
        return;
      }
    } else {
      alert("Please Provide Last Name.");
      return;
    }

    if (formValue.dob_0 != undefined) {
      if (formValue.dob_0.length < 10) {
        alert("Please provide valid date of birth.");
        return;
      }
    } else {
      alert("Please provide valid date of birth.");
      return;
    }

    if (formValue.document_0 != undefined) {
      if (formValue.document_0.length < 1) {
        alert("Please provide document number.");
        return;
      }
    } else {
      alert("Please provide document number.");
      return;
    }

    if (formValue.expiry_0 != undefined) {
      if (formValue.expiry_0.length < 10) {
        alert("Please provide provide valid document expiry date.");
        return;
      }
    } else {
      alert("Please provide provide valid document expiry date.");
      return;
    }
   // debugger
    const jsonData = JSON.stringify(formValue);
   console.log('json is',jsonData)
    axios
      .post(url + "/SaveBooking", jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
       console.log('multi booking', response.data);
       navigate('/ThankYouGall', { state: { props: response.data } });       // const jazzData = response.data;
      //  navigateToPaymentGateway(jazzData);
      })
      .catch((error) => console.error(error));
  }

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValue((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));
  };

  const handleCountryChange = (event) => {
    //  console.log(event.target.value);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
    setSelectedOption(event.target.value);
    //if (event.target.name)
    fetchCities(event.target.value);
  };

  const handleSubmit = async () => {
    // store the states in the form data

    setformValue({
      ...formValue,
      transactionId: data,
    });

    const loginFormData = new FormData();

    // console.log(data);
    // console.log(formValue);

    try {
      // make axios post request
      const response = await axios({
        method: "post",
        url: url + "/SaveBooking",
        data: loginFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const res = response.data;

      navigateToPaymentGateway(res);

    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    postData();
  };


 

  //console.log(AdultTotalPrice);

  function removeLetters(str) {
    return str.replace(/[a-z]/gi, "");
  }

  return (
    <>
      <MainMenu></MainMenu>
      <br></br>

      <div className="container">
        <br></br>
        <form
          className="lg-booking-form"
          id="frm_booking"
          name="frm_booking"
          method="post"
        >
          <Grid container spacing={5}>
            <Grid xs={12} sm={6} md={8}>
              <div className="row">
                <div className="col-12">
                  <h3>Contact Details</h3>
                </div>
              </div>
              <div className="rcorners2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile No.</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        className="form-control"
                        id="MobileNO_0"
                        name="MobileNO_0"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        className="form-control"
                        id="email_0"
                        name="email_0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"style={{marginTop:"60px"}}>
                <div className="col-12">
                  <h3>Personal Information</h3>
                </div>
              </div>
              <div className="rcorners2">
                <div className="row">
                  <div className="lg-booking-form-heading col-12">
                    <span>1</span>
                  </div>

                  <div className="personal-info col-12 col-md-12">
                    <div className="row">

                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            onChange={handleChange}
                            id="firstName_0"
                            name="firstName_0"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            onChange={handleChange}
                            id="lastName_0"
                            name="lastName_0"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            onChange={handleChange}
                            required="required"
                            className="form-control dpd3"
                            id="dob_0"
                            name="dob_0"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Passport Issuing Country</label>
                          <select
                            className="form-control"
                            id="nationality_0"
                            name="nationality_0"
                          >
                            {countries.map((option) => (
                              <option key={option.Id} value={option.Id}>
                                {option.Value}
                              </option>
                            ))}
                          </select>
                          <span>
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            id="Gender_0"
                            name="Gender_0"
                          >
                            <option defaultValue="-1">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <span>
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Document Type</label>
                          <select
                            className="form-control"
                            id="documenttype_0"
                            name="documenttype_0"
                          >
                            <option defaultValue="-1">Select</option>
                            <option value="CNIC">CNIC</option>
                            <option value="Passport">Passport</option>
                          </select>
                          <span>
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </div>
                      </div>
                     

                    </div>

                    <div className="row" style={{ display: "none" }}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mobile No.</label>
                          <input
                            type="email"
                            onChange={handleChange}
                            className="form-control"
                            id="MobileN"
                            name="MobileN"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Landline Number</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            className="form-control"
                            id="LandlineNo_0"
                            name="LandlineNo_0"
                          />
                        </div>
                      </div>
                    </div>

                  
                   
                    <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                          <label>document</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            className="form-control"
                            rows="2"
                            id="document_0"
                            name="document_0"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Document Expiry</label>
                          <input
                            type="date"
                            onChange={handleChange}
                            className="form-control"
                            rows="2"
                            id="expiry_0"
                            name="expiry_0"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                  {inputFields.map((input, index) => {
                    if (index > 0) {
                      return (
                        <div id={index} className=" col-12">
                          <div className="lg-booking-form-heading">
                            <span>{index + 1}</span>
                          </div>

                          <div className="personal-info col-12 col-md-12">
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Title</label>
                                  <select
                                    className="form-control"
                                    id={"title_" + index}
                                    name={"title_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                    <option value="Mr">Mr.</option>
                                    <option value="Mrs">Mrs.</option>
                                    <option value="Ms">Ms.</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mistress">Mistress</option>
                                    <option value="Master">Master</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>

                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange}
                                    id={"lastName_" + index}
                                    name={"lastName_" + index}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange}
                                    id={"firstName_" + index}
                                    name={"firstName_" + index}
                                  />
                                </div>
                              </div>

                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Date of Birth</label>
                                  <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control dpd3"
                                    id={"dob_" + index}
                                    name={"dob_" + index}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Passport Issuing Country</label>
                                  <select
                                    className="form-control"
                                    id={"nationality_" + index}
                                    name={"nationality_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>

                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Email Address</label>
                                  <input
                                    type="email"
                                    onChange={handleChange}
                                    className="form-control"
                                    id={"email_" + index}
                                    name={"email_" + index}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Mobile No.</label>
                                  <input
                                    type="email"
                                    onChange={handleChange}
                                    className="form-control"
                                    id={"MobileNO_" + index}
                                    name={"MobileNO_" + index}
                                  />
                                </div>
                              </div>

                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Gender</label>
                                  <select
                                    className="form-control"
                                    onChange={handleChange}
                                    id={"Gender_" + index}
                                    name={"Gender_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>City</label>
                                  <select
                                    className="form-control"
                                    id={"city_" + index}
                                    name={"city_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Country</label>
                                  <select
                                    className="form-control"
                                    id={"country_" + index}
                                    name={"country_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Permanent Address</label>
                                  <input
                                    type="text"
                                    onChange={handleChange}
                                    className="form-control"
                                    rows="2"
                                    id={"address_" + index}
                                    name={"address_" + index}
                                  ></input>
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>document</label>
                                  <input
                                    type="text"
                                    onChange={handleChange}
                                    className="form-control"
                                    rows="2"
                                    id={"document_" + index}
                                    name={"document_" + index}
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Document Type</label>
                                  <select
                                    className="form-control"
                                    id={"documenttype_" + index}
                                    name={"documenttype_" + index}
                                  >
                                    <option defaultValue="-1">Select</option>
                                    <option value="CNIC">CNIC</option>
                                    <option value="Passport">Passport</option>
                                  </select>
                                  <span>
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-group">
                                  <label>Document Expiry</label>
                                  <input
                                    type="date"
                                    onChange={handleChange}
                                    className="form-control"
                                    rows="2"
                                    id={"expiry_" + index}
                                    name={"expiry_" + index}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}

                  <div className="checkbox">
                    <label>
                      <input type="checkbox" id="chk_agree" name="chk_agree" style={{width:'auto'}}/>{" "}
                      By continuing, you are agree to the{" "}
                      <Link href="#">Terms and Conditions.</Link>
                    </label>
                  </div>
                  <div className="col-md-12 text-center" id="result_msg"></div>
                  {disableSubmitBtn ? (
                    <>Loading..</>
                  ) : (
                    <>
                      <a onClick={handleClick} className="btn btn-orange ml-5">
                        Confirm Booking
                      </a>
                    </>
                  )}
                  
                </div>
                <div>
                  <img
                            src={"images/NIFTePay-logo-tagline-01.png"}
                            width={200} height={140}
                          />
                          </div>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <div className="row">
                <div className="col-12">
                  <h3>Price Summary</h3>
                </div>
              </div>
              <div className="rcornersflight">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-12">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Price</td>
                            <td>PKR {totalPrice}</td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td>PKR {totalTaxes}</td>
                          </tr>
                          <tr>
                            <td>Price you pay</td>
                            <td>PKR {price}</td>
                          </tr>
                          {/* <tr>
                            <td>Price you pay</td>
                            <td>PKR {grandPrice}</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3>Flight Details</h3>
                </div>
              </div>
              <div className="rcornersflight"style={{overflow:"hidden"}}>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-12">
                    <div style={{ display: "none" }}>
                      {(duration = flightData[0].FlightTime)}
                      {(hours = Math.floor(duration / 60))}
                      {(minutes = duration % 60)}
                      {(img = flightData[0].Carrier + ".png")}
                    </div>
                    {/* Departure Data Div */}
                    {
                      departure ?  <div className="shadow"style={{boxShadow:"#00000033"}}>
                      <div className="mt-5">
                        <div className="" style={{ height: "30px", backgroundColor: "#f0f0f0", overflow: "hidden" }}>
                          <div className="row w-100 h-100 d-flex">
                            <div className="col-6">
                              Departure
                            </div>
                            <div className=" col-6">
                              {moment(departure.DepartureTime).format(
                                "DD MMMM YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="">
                          <img
                            src={"images/airlines/" + img}
                            className="img-fluid" width={20} height={20}
                          />
                        </div>
                        <div className="ml-3">
                          {departure.CarrierName }
                        </div>
                        <div className="ml-3">
                        {departure.Carrier }-{departure.FlightNumber }
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                          {moment(departure.DepartureTime).utcOffset("+05:00").format("hh:mm A")}
                        </div>
                        <div className="ml-3 pr-5 pl-5" style={{ backgroundColor: "#f0f0f0", borderRadius: "20px" }} visible={tooltipVisible}>
                        <FlightTimeConverter minutes={depTimeDifferenceInMinutes}  />
                          
                         
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                          {moment(lastDepartureFlightTime).utcOffset("+05:00").format("hh:mm A")}
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        {origin ? origin.Name : ""} {`(${departure.Origin})`}
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                            <Tooltip placement="top" title={deptext}style={{cursor:"pointer"}}>
                              {<span  style={{ color: "#faa61a" }}>{departure.Stops} </span>}<span style={{ color: "#faa61a" }}> Stops</span>
                              {/* <Button>Top</Button> */}
                              <span onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style={{ cursor: 'pointer' }}>
                              </span>
                            </Tooltip>
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        {destination ? destination.Name : ""} {`(${departure.FinalDestination})`}
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                          Beggage:
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                          <span>Total</span><span style={{ fontWeight: "400" }}> { departure.MaxWeightValue} {departure.MaxWeightUnit}</span>
                        </div>

                      </div>
                     
                      <hr></hr>
                    
                     
                    </div> : ""
                    }
                   
                   {/* Arrival Data Div */}
                   { returnflight ?  
                    <div className="shadow" style={{ boxShadow: "#00000033" }}>
                      <div className="mt-5">
                        <div className="" style={{ height: "30px", backgroundColor: "#f0f0f0", overflow: "hidden" }}>
                          <div className="row w-100 h-100 d-flex">
                            <div className="col-6">
                              Departure
                            </div>
                            <div className=" col-6">
                              {moment(returnflight.DepartureTime).format(
                                "DD MMMM YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="">
                          <img
                            src={"images/airlines/" + img}
                            className="img-fluid" width={20} height={20}
                          />
                        </div>
                        <div className="ml-3">
                        {returnflight.CarrierName }
                        </div>
                        <div className="ml-3">
                        {returnflight.Carrier }-{returnflight.FlightNumber }

                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                        {moment(returnflight.DepartureTime).utcOffset("+05:00").format("hh:mm A")}
                        </div>
                        <div className="ml-3 pr-5 pl-5" style={{ backgroundColor: "#f0f0f0", borderRadius: "20px" }}>
                        <FlightTimeConverter minutes={arrTimeDifferenceInMinutes}  />
                     
                      
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                        {moment(lastReturnFlightTime).utcOffset("+05:00").format("hh:mm A")}
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        {returnorigin ? returnorigin.Name : ""} {`(${returnflight.Origin})`}
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        <Tooltip placement="top" title={arrtext}>
                        {<span style={{ color: "#faa61a" }}>{returnflight.Stops} </span>}<span style={{ color: "#faa61a" }}> Stops</span>

                        {/* {returnflight.Stops }<span> Stops</span> */}

                              {/* <Button>Top</Button> */}
                              <span onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style={{ cursor: 'pointer' }}>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        {returndestination ? returndestination.Name : ""} {`(${returnflight.FinalDestination})`}
                        </div>
                      </div>

                      <div className="row mt-5 ml-5">
                        <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                          Beggage:
                        </div>
                        <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                        <span>Total</span><span style={{ fontWeight: "400" }}> { returnflight.MaxWeightValue} {returnflight.MaxWeightUnit}</span>

                        </div>

                      </div>

                      <hr></hr>
                    </div> : "" }
                   

                    {/* <div className="side-bar-block detail-block style1 text-center">
                     
                      <div className="detail-img text-center">
                        <Link href="#">
                          <img
                            src={"images/airlines/" + img}
                            className="img-fluid" width={120} height={100}
                          />
                        </Link>
                      </div>

                      <div className="detail-title">
                        <h4>
                          {" "}
                          <b>{origin ? origin.Name : ""}</b> to{" "}
                          <b>{destination ? destination.Name : ""}</b>{" "}
                        </h4>
                        <p>Oneway Flight</p>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>
                            <tr>
                              <td>Departure</td>
                              <td>
                                {moment(flightData.DepartureTime).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                -{" "}
                                {moment(flightData.DepartureTime).format(
                                  "HH:MM"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Arrival</td>
                              <td>
                                {moment(flightData.ArrivalTime).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                -{" "}
                                {moment(flightData.ArrivalTime).format("HH:MM")}
                              </td>
                            </tr>
                            <tr>
                              <td>Class</td>
                              <td>
                                {flightData[0].CabinClass} (
                                {flightData[0].BookingCode})
                              </td>
                            </tr>
                            <tr>
                              <td>Stops</td>
                              <td>{flightData[0].Stops}</td>
                            </tr>
                            <tr>
                              <td>Flight Duration</td>
                              <td>
                                {" "}
                                {hours} hours - {minutes} minutes
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 col-md-6 col-lg-12">
                    <div className="side-bar-block support-block">
                      <h3>Need Help</h3>
                     
                      <div className="support-contact">
                        <span>
                          <i className="fa fa-phone"></i>
                        </span>
                        <p>+92 51 111 77 88 00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Footer></Footer>
    </>
  );
}
