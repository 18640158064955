import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope } from 'react-icons/fa'; // Import the icons from the react-icons library
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { da } from "date-fns/locale";
import { Email } from "@mui/icons-material";


function TravelSearch() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [countries, setCountries] = useState([]);
    const [timeout, setTimeout] = useState('')
    const [isFlightDetailsVisible, setFlightDetailsVisible] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [icon, setIcon] = useState(<FaPlus />); // Initialize with the "plus" icon
    const [enquireVisible, setEnquireVisible] = useState(false);
    const [benefit, setBenefit] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const { passengertype, data, departureDate, durations, country, country1, dob, area } = location.state || {};
    const [cardVisibility, setCardVisibility] = useState(Array(data.length).fill(false));
    const [cardVisibilitys, setCardVisibilitys] = useState(Array(data.length).fill(false));
    const [selectedDetailIndex, setSelectedDetailIndex] = useState(null);
    const [formvalue, setFormvalue] = useState({
        Full_Name: '',
        Mobile_no: '',
        Email: '',
        City: '',
        InsuranceId: '',
        Passengertype: passengertype,
    })
    console.log('enquire now', data)
    console.log('responceee', area)
    const url = apiUrl + "/api/Insurance";
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormvalue((prevFormValue) => ({
            ...prevFormValue,
            [name]: value,
        }));
    };
    useEffect(() => {
        fetchCountries();
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded

        }, 5000); // Adjust the durations as needed

    }, []);
    async function fetchCountries() {
        try {
            const response = await axios.get(apiUrl + "/api/flightsearch/pakcities");
            setCountries(response.data);
            setIsLoading(false); // Set isLoading to false once the content is loaded
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Handle errors and set isLoading to false
        }
    }
    const toggleFlightDetails = (itemId, insuranceType, index) => {
        // Toggle the visibility state only if it's a different item
        if (selectedItemId !== itemId) {
            setCardVisibility(Array(data.length).fill(false)); // Close all other cards
            setCardVisibility((prevVisibility) => {
                const newVisibility = [...prevVisibility];
                newVisibility[index] = true;
                return newVisibility;
            });

            setSelectedItemId(itemId);

            const instance = axios.create({
                baseURL: url,
                timeout: 10000,
                headers: { "X-Custom-Header": "foobar" },
            });

            const jsonData = JSON.stringify({
                CountryCode: itemId,
                InsuranceType: insuranceType,
            });

            axios
                .post(url + "/insurancebenefits", jsonData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const data1 = response.data;
                        setBenefit(data1);
                        console.log('benefits are', data1);
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                })
                .catch((error) => console.error(error));
        } else {
            // If the same item is clicked again, hide details
            setCardVisibility((prevVisibility) => {
                const newVisibility = [...prevVisibility];
                newVisibility[index] = !newVisibility[index];
                return newVisibility;
            });

            setSelectedItemId(null);
        }

        // Change the icon based on visibility
        setIcon(cardVisibility[index] ? <FaPlus /> : <FaMinus />);
        setEnquireVisible(false);
    };

    const toggleEnquireDetails = (index, ids) => {
        setCardVisibilitys((prevVisibility) => {
            const newVisibility = [...prevVisibility];
            newVisibility[index] = !newVisibility[index]; // Toggle visibility
            return newVisibility;

        });
        setIcon(<FaPlus />);
        setSelectedDetailIndex(index);
        setFormvalue({ ...formvalue, InsuranceId: ids.toString() });
    };
    const handleCloseClick = () => {
        setCardVisibilitys((prevVisibility) => {
            const newVisibility = [...prevVisibility];
            newVisibility[selectedDetailIndex] = false;
            return newVisibility;
        });

        // Reset selectedDetailIndex if needed
        setSelectedDetailIndex(null);

    };
    const onEdit = () => {
        navigate('/online-travel-insurance-pakistan');
    }
    const handleBuyClick = (itemId, singleFee, familyFee, InsuranceType, Vendor) => {

        navigate('/TravelInsurance_Booking', { state: { passengertype, InsuranceType, itemId, data, departureDate, durations, country, country1, singleFee, familyFee, dob, Vendor } });
    }
    const handleDownload = (vendor) => {
        if (vendor === 'CSI') {
            handleDownloadCSI();
        } else if (vendor === 'UIC') {
            handleDownloadUIC();
        }
    };

    const handleDownloadCSI = () => {
        const pdfUrl = '../../images/Brochure.pdf';
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'CSI.pdf';
        anchor.click();
    };

    const handleDownloadUIC = () => {
        const pdfUrl = '../../images/Travel  Broucher 2022.pdf';
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'UIC.pdf';
        anchor.click();
    };

    function postData() {
        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: { "X-Custom-Header": "foobar" },
        });

        // ... (validation checks)

        const jsonData = JSON.stringify(formvalue);
        console.log('json is', jsonData);

        axios
            .post(url + "/insuranceenquiry", jsonData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    console.log('api response is', data);
                    // Display the API response in an alert
                    alert(JSON.stringify(data));
                } else {
                    // Handle other status codes or errors if needed
                    console.error("Unexpected response status:", response.status);
                }
            })
            .catch((error) => console.error(error));
    }

    const handleClick = (e) => {
        postData();
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    function cleanMaxStay(maxStay) {
        return maxStay.replace(/^c-/, '');
    }

    return (

        <>
            <Helmet>
                <title >{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>

                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>



            <section className="innerpage-wrapper">
                <div id="travel-insurance" className="innerpage-section-padding">
                    <div className="container">

                        <div className="row">
                            {!isLoading && (
                                <div className="col-12 col-md-12 col-lg-9 mt-2">


                                    {data.map((item, index) => {
                                        return (
                                            <form key={index}>
                                                <div id={item.Id} className="list-block main-block f-list-block" style={{ display: 'block' }}>
                                                    <div style={{ display: 'none' }}>3210330EK.png</div>
                                                    <div className="card mt-0" style={{ borderRadius: '20px' }}>
                                                        <div className="row">

                                                            <div className="col-md-3 col-lg-3 borderrightt">
                                                                <div className="clearfix box4 borderright">
                                                                    <div className="plan-info">
                                                                        <div className="tumb">
                                                                            <img
                                                                                className="lozad"
                                                                                src={item.Vendor === 'CSI' ? "../images/CSI-Logo.png" : item.Vendor === 'UIC' ? "../images/UIC.png" : ""}
                                                                                alt={item.Vendor === 'CSI' ? "CSI Logo" : item.Vendor === 'UIC' ? "UIC Logo" : "Vendor Logo"}
                                                                            />
                                                                        </div>
                                                                        <div className="plan-name">
                                                                            <h4 className="nomargin bold darkergray">
                                                                                <div className="pckg-name">{item.InsuranceType}</div>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid-plan-detail grid-col col-md-6 ">
                                                                <div className="t-row borderbottom" style={{ backgroundColor: 'white' }}>
                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">Medical cover:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>{`$${item.MedicalExpense}`}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">Duration:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>{`${durations}`}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">Max Stay:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>{`${cleanMaxStay(item.MaxStay)} days`}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">Deductible on Medical & Dental:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>$100</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">Covid cover T & C Apply:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>US$ 2000</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-6 col-6">
                                                                        <div className="boxx">
                                                                            <div className="f14 darkergray coverage">24/7 World Wide Assistance Services:</div>
                                                                            <div className="semibold value darkergray2">
                                                                                <b>Included</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid-col col-md-3 mb-4">
                                                                <div className="price-row">
                                                                    <del className="discount-price">{`Rs.${passengertype === 'family' ? item.FamilyFee : item.SingleFee}`}</del>
                                                                    <label className="percentage ml-2">10% off</label>
                                                                    <p style={{ color: '#e5b622', fontSize: '20px' }}>
                                                                        <b>{`Rs.${((passengertype === 'family' ? item.FamilyFee : item.SingleFee) * 0.90).toFixed(0)}`}</b>
                                                                    </p>
                                                                    <div className="f14 darkergray coverage"><b>Premium</b></div>
                                                                </div>
                                                                <div className="boxx3 mx-auto">

                                                                    <a
                                                                        onClick={() => handleBuyClick(item.Id, item.SingleFee, item.FamilyFee, item.InsuranceType, item.Vendor)}
                                                                        className="btn btn-orange mb-2" style={{ height: '35px' }}>
                                                                        Buy Now
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={index} className="card-footer text-left">
                                                            <div className="ant-collapse ant-collapse-icon-position-start ant-collapse-ghost css-26rdvq">
                                                                <div className="ant-collapse-item">
                                                                    <div
                                                                        className="ant-collapse-header"
                                                                        aria-expanded={cardVisibility[index]}
                                                                        aria-disabled="false"
                                                                        role="button"
                                                                        tabIndex="0"
                                                                        onClick={() => toggleFlightDetails(item.CountryCode, item.InsuranceType, index)}
                                                                    >
                                                                        <a className="ant-collapse-header-text" style={{ color: '#e5b622', textAlign: 'center', display: 'block' }}>
                                                                            {icon} {cardVisibility[index] ? 'Hide Details' : 'More Details'}
                                                                        </a>
                                                                    </div>
                                                                    {cardVisibility[index] && selectedItemId && (
                                                                        <div className="ant-collapse-content ant-collapse-content-active" style={{ padding: '10px 20px' }}>
                                                                            <div className="ant-collapse-content-box">
                                                                                {benefit && benefit.length > 0 ? (
                                                                                    <>
                                                                                        <div className="card-header" style={{ background: '#fff', borderTop: '1px solid  rgba(0,0,0,.125)' }}>
                                                                                            <div className="row">

                                                                                                <div className="col-sm-3">
                                                                                                    <p style={{ color: '#e5b622', fontSize: '20px', fontFamily: 'Panton Bold', }}>Medical Benefits:</p>
                                                                                                </div>

                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[0].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[0].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[1].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[1].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[2].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[2].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row mt-4">
                                                                                                <div className="col-sm-3">

                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[3].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[3].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[4].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[4].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[5].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[5].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="row mt-4">
                                                                                                <div className="col-sm-3">

                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[6].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[6].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[7].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[7].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[8].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[8].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>
                                                                                            <div className="row mt-4">
                                                                                                <div className="col-sm-3">

                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[9].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[9].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="card-header" style={{ background: '#fff' }}>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-3">
                                                                                                    <p style={{ color: '#e5b622', fontSize: '20px', fontFamily: 'Panton Bold' }}>Travel Benefits:</p>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[10].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[10].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[11].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[11].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[12].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[12].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row mt-4">
                                                                                                <div className="col-sm-3">

                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[13].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`${benefit[13].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <p className="f14 darkergray coverage">{`${benefit[14].Benefit}:`}</p>
                                                                                                    <div className="semibold value darkergray2">
                                                                                                        <b>{`$${benefit[14].Coverage}`}</b>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>

                                                                                    </>
                                                                                ) : (
                                                                                    <div className="col-sm-12">
                                                                                        <p>No benefits available</p>
                                                                                    </div>
                                                                                )}
                                                                                <div className="card-header" style={{ background: '#fff' }}>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-3">
                                                                                            <p style={{ color: '#e5b622', fontSize: '20px', fontFamily: 'Panton Bold' }}>Policy Wording:</p>
                                                                                        </div>
                                                                                        <div className="col-sm-3 mt-1">
                                                                                            <div className="valuee">
                                                                                                <a className="linkStyle" onClick={() => handleDownload(item.Vendor)}>
                                                                                                    Download Document
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>


                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    )}



                                                                    {cardVisibilitys[index] && selectedDetailIndex !== null && (
                                                                        <div className="ant-collapse-content ant-collapse-content-active">
                                                                            <div className="ant-collapse-content-box">
                                                                                <div className="card-header" style={{ background: '#fff', borderTop: '1px solid rgba(0,0,0,.125)', position: 'relative' }}>
                                                                                    <FaTimes className="linkStyle" style={{ position: 'absolute', top: '15px', right: '10px', cursor: 'pointer', fontSize: '20px' }} onClick={handleCloseClick} />
                                                                                    <h2 style={{ color: '#e5b622', fontFamily: 'Panton Bold', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>Setup a call with an Expert</h2>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label className="mb-2">Full Name</label>
                                                                                                <input type="text"
                                                                                                    required
                                                                                                    className="form-control"
                                                                                                    placeholder="Full name"
                                                                                                    name="Full_Name"
                                                                                                    value={formvalue.Full_Name}
                                                                                                    onChange={handleInputChange}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label className="mb-2">Phone Number</label>
                                                                                                <input type="tel"
                                                                                                    required
                                                                                                    className="form-control"
                                                                                                    placeholder="phone number"
                                                                                                    name="Mobile_no"
                                                                                                    value={formvalue.Mobile_no}
                                                                                                    onChange={handleInputChange}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label className="mb-2">Email Address</label>
                                                                                                <input type="email"
                                                                                                    required
                                                                                                    className="form-control"
                                                                                                    placeholder="Email Address"
                                                                                                    name="Email"
                                                                                                    value={formvalue.Email}
                                                                                                    onChange={handleInputChange}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label className="mb-2">Select City</label>
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    id="nationality_0"
                                                                                                    name="City"
                                                                                                    required
                                                                                                    value={formvalue.City}
                                                                                                    onChange={handleInputChange}
                                                                                                >
                                                                                                    <option value="" selected>
                                                                                                        Select City
                                                                                                    </option>
                                                                                                    {countries.map((option) => (
                                                                                                        <option key={option.Id} value={option.Id}>
                                                                                                            {option.Value}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="center2 pt-5 pb-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={handleClick}
                                                                                            className="btn btn-lg btn-orange fn semibold btn-enquire"
                                                                                        >
                                                                                            Enquire Now
                                                                                        </button>
                                                                                    </div>
                                                                                </div>







                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        );
                                    })}



                                </div>
                            )}
                            <div class="col-12 col-md-12 col-lg-3 mb-2">
                                <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                    <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                        <div className="h-pro">
                                            <div className="row1 clearfix">
                                                <div className="bold">
                                                    <h3 style={{ color: '#e5b622', fontFamily: 'Panton Bold', textAlign: 'center', marginTop: '20px' }}>Your Travel Details</h3></div>
                                            </div>
                                            <div className="clearfix f14 user-detail-wrap clearfix p-3">
                                                <div className="row">
                                                    <div className="top">Traveling To:</div>
                                                    <div className="bold bottom">

                                                        <b>{data[0].CountryDescrtiption}</b>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="top">Passenger Traveling:</div>
                                                    <div className="bold bottom">

                                                        <b>{passengertype}</b>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="top">Departing On:</div>
                                                    <div className="bold bottom"><b>{departureDate}</b></div>
                                                </div>
                                                <div className="row">
                                                    <div className="top">Returning On:</div>
                                                    <div className="bold bottom"><b>{durations}</b></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="clearfix">
                                                    <button onClick={onEdit} className="pull-right btn-edit btn btn-secondary btn-sm btn-border">
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>

                        </div>

                    </div>
                </div>
            </section>




            <Footer></Footer>
        </>
    );

}

export default TravelSearch;

